import React from "react";

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

const Table = ({ data, columns, conditionalRowStyles, onRowClicked }) => {
    const tableData = {
        columns,
        data,
    };

    return (
        <DataTableExtensions {...tableData} print={false} export={false}>
            <DataTable
                columns={columns}
                data={data}
                // noHeader
                defaultSortField="id"
                defaultSortAsc={false}
                pagination
                highlightOnHover
                responsive
                conditionalRowStyles={conditionalRowStyles}
                noDataComponent="This attribute was not found" //or your component
            />
        </DataTableExtensions>
    );
};

export default Table;
