import React, { useState, useEffect } from "react";
import {
    CUPID_MODEL,
    STATUS_PENDING,
    STATUS_PROCESSING,
} from "../utilis/const";
import { toast } from "react-toastify";
import { Modal, Row, Col, Card, Spinner } from "react-bootstrap";
import PartnerCatalogService from "../services/PartnerCatalogService";
import CupidLogo from "../lotties/cupid-logo.svg";
import { getCupidModels } from "../redux/actions/ModelsActions";
import Stepper from "react-stepper-horizontal";
import { useSelector, useDispatch } from "react-redux";
import { getPartnerCatalogsLastUpdatePeriodique } from "../redux/actions/PartnerActions";
import { getPartnerCatalogsByStatus } from "../redux/actions/PartnerCataogActions";

const RunMappingModal = ({ open, handleClose, currentElement }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedModel, setSelectedModel] = useState(CUPID_MODEL);
    const [selectedCupidModel, setSelectedCupidModel] = useState();
    const [useManualMappings, setUseManualMappings] = useState(false);
    const [name, setName] = useState("");

    const sections = React.useMemo(() => {
        const required = [
            {
                title: "Catalog Name",
            },
            {
                title: "Select Model",
            },
        ];

        return required;
    }, []);
    const next = async () => {
        if (currentPage === 1 && name === "") {
            toast.error("Catalog name is required to pass", {
                toastId: "name-catalog-csv-step1",
            });
        } else {
            setCurrentPage((prev) => prev + 1);
        }
    };
    const prev = () => setCurrentPage((prev) => prev - 1);

    const renderElement = () => {
        switch (currentPage) {
            case 1:
                return (
                    <TypeCatalogName
                        next={next}
                        name={name}
                        setName={setName}
                        handleClose={handleClose}
                    />
                );

            case 2:
                return (
                    <SelectModel
                        selectedModel={selectedModel}
                        setSelectedModel={setSelectedModel}
                        prev={prev}
                        next={next}
                        selectedCupidModel={selectedCupidModel}
                        setSelectedCupidModel={setSelectedCupidModel}
                        handleClose={handleClose}
                        name={name}
                        currentElement={currentElement}
                        useManualMappings={useManualMappings}
                        setUseManualMappings={setUseManualMappings}
                    />
                );

            default:
                return <></>;
        }
    };

    return (
        <Modal
            show={open}
            onHide={handleClose}
            dialogClassName="modal-90w modal-dialog modal-dialog-centered"
        >
            <Modal.Body style={{ paddingBottom: "0px" }}>
                <div className="card-header p-0 pb-2">
                    <Stepper
                        steps={sections}
                        activeStep={currentPage}
                        activeColor="#D3D7DB"
                        defaultBarColor="#D3D7DB"
                        completeColor="#6076E8"
                        completeBarColor="#6076E8"
                        fontFamily="Roboto"
                        textColor="#fff"
                        defaultBorderStyle="solid 6px"
                        defaultBorderWidth={10}
                    />
                </div>
                <div className="card-body">{renderElement()}</div>
            </Modal.Body>
        </Modal>
    );
};

const SelectModel = (props) => {
    const {
        selectedCupidModel,
        setSelectedCupidModel,
        prev,
        handleClose,
        currentElement,
        name,
        useManualMappings,
        setUseManualMappings,
    } = props;

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const { cupidModelsList } = useSelector((state) => ({
        cupidModelsList: state.modelsReducer.cupidModelsList?.models ?? [],
    }));

    useEffect(() => {
        dispatch(getCupidModels());
    }, []);
    const handleRunMapping = async () => {
        setLoading(true);

        try {
            const res = await PartnerCatalogService.runOutdatedMapping({
                model: selectedCupidModel?.name,
                use_manual_mappings: useManualMappings,
                name: name,
                partner_id: currentElement.partner_id,
                company_id: currentElement.company_id,
            });
            if (res.status === 200) {
                setLoading(false);
                toast.success("Mapping started ");
                dispatch(
                    getPartnerCatalogsByStatus(undefined, [
                        STATUS_PENDING,
                        STATUS_PROCESSING,
                    ])
                );
                dispatch(getPartnerCatalogsLastUpdatePeriodique());
                handleClose();
            } else {
                setLoading(false);
                toast.error(res.message);
            }
        } catch (err) {
            setLoading(false);
            toast.error(err ?? "Error while runing mapping");
        }
    };
    return (
        <>
            <Modal.Body style={{ textAlign: "center" }}>
                <div className="row">
                    <div className="col-12">
                        <fieldset
                            className="scheduler-border"
                            style={{
                                height: "100%",
                                marginBottom: "0px",
                                display: "flex",
                                justifyContent: "center",
                                padding: "0 0.75em 0.75em 0.75em",
                            }}
                        >
                            <legend className="scheduler-border">
                                <h3 className="card-title">Select model</h3>
                            </legend>
                            <div className="col-3">
                                <div style={{ paddingRight: "20px" }}>
                                    <div
                                        style={{
                                            padding: "15px",
                                            border: "1px solid rgb(96, 118, 232)",
                                            borderRadius: "1rem",
                                            // cursor: "pointer",
                                        }}
                                        disabled={true}
                                    >
                                        <div
                                            className="card-body"
                                            style={{
                                                padding: "17px 13px 17px 13px",
                                            }}
                                        >
                                            <img
                                                id={CUPID_MODEL}
                                                alt={CUPID_MODEL}
                                                src={CupidLogo}
                                                width="150"
                                                height="70"
                                                style={{
                                                    padding: "10px",
                                                }}
                                            />
                                        </div>
                                        <div
                                            className="card-footer"
                                            style={{
                                                borderRadius: "1rem",
                                                backgroundColor:
                                                    "rgb(96, 118, 232)",
                                                fontWeight: "bold",
                                                color: "white",
                                            }}
                                        >
                                            Cupid
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-9">
                                <Row
                                    style={{
                                        overflow: "auto",
                                        maxHeight: "200px",
                                    }}
                                >
                                    {cupidModelsList?.map((e, index) => {
                                        return (
                                            <Col md={12} lg={12}>
                                                <Card
                                                    className="card-without-hover"
                                                    for={e?.id}
                                                    style={{
                                                        border:
                                                            e.id ===
                                                            selectedCupidModel?.id
                                                                ? "2px solid rgb(96, 118, 232)"
                                                                : "",
                                                        marginBottom: "10px",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={(event) => {
                                                        setSelectedCupidModel(
                                                            e
                                                        );
                                                    }}
                                                >
                                                    <Row className="no-gutters">
                                                        <Col>
                                                            <Card.Body
                                                                style={{
                                                                    padding:
                                                                        "10px",
                                                                }}
                                                            >
                                                                <Card.Title
                                                                    style={{
                                                                        display:
                                                                            "flex",

                                                                        justifyContent:
                                                                            "space-between",
                                                                    }}
                                                                >
                                                                    <label
                                                                        style={{
                                                                            fontWeight:
                                                                                "800",
                                                                            fontSize:
                                                                                "18px",
                                                                            marginBottom:
                                                                                "0px",
                                                                        }}
                                                                        for={
                                                                            e?.id
                                                                        }
                                                                    >
                                                                        {
                                                                            e?.name
                                                                        }
                                                                    </label>
                                                                </Card.Title>
                                                                {/* <Card.Text
                                                                    className="card-text-mapping-details"
                                                                    style={{
                                                                        textAlign:
                                                                            "left",
                                                                        marginTop:
                                                                            "0px",
                                                                    }}
                                                                >
                                                                    {e?.description ??
                                                                        ""}
                                                                </Card.Text> */}
                                                            </Card.Body>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                }}
            >
                <div
                    className="custom-control custom-checkbox"
                    style={{
                        marginRight: "35px",
                    }}
                >
                    <input
                        id={"usePreviousMapping"}
                        checked={useManualMappings}
                        onClick={() => {
                            setUseManualMappings(!useManualMappings);
                        }}
                        type="checkbox"
                        className="custom-control-input"
                        style={{
                            cursor: "pointer",
                        }}
                        // id="customCheck1"
                    />
                    <label
                        className="custom-control-label"
                        for="usePreviousMapping"
                    >
                        Use previous manual mapping
                    </label>
                </div>
                <div>
                    <button
                        type="button"
                        className="btn btn-warning"
                        onClick={prev}
                    >
                        <i className="fas fa-caret-left"></i> &nbsp;&nbsp; Back
                    </button>
                    <button
                        type="button"
                        className="btn btn-light"
                        onClick={handleClose}
                        style={{
                            marginLeft: "10px",
                        }}
                    >
                        Close
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleRunMapping}
                        disabled={loading}
                        style={{
                            marginLeft: "10px",
                        }}
                    >
                        Start Mapping
                        {loading && (
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        )}
                    </button>
                </div>
            </Modal.Footer>
        </>
    );
};

function TypeCatalogName(props) {
    const { next, name, setName, handleClose } = props;

    return (
        <>
            <Modal.Body>
                <div className="row">
                    <div className="col-lg-6">
                        <fieldset
                            className="scheduler-border"
                            style={{ minHeight: "220px" }}
                        >
                            <legend className="scheduler-border">
                                <h3 className="card-title">
                                    Type your catalog name
                                </h3>
                            </legend>
                            <div className="form-group form-group-header">
                                <label>Catalog name </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Type your catalog name "
                                    name="name"
                                    value={name ?? ""}
                                    onChange={(e) => {
                                        setName(e.target.value);
                                    }}
                                />
                            </div>
                        </fieldset>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="btn btn-light"
                    onClick={handleClose}
                >
                    Close
                </button>
                <button className="btn btn-primary" onClick={next}>
                    Next &nbsp;&nbsp;
                    <i className="fas fa-caret-right"></i>
                </button>
            </Modal.Footer>{" "}
        </>
    );
}
export default RunMappingModal;
