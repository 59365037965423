import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useLocation, Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import moment from "moment";
import CatalogPartnersTableDetails from "./CatalogPartnersTableDetails";
import { PieChart } from "react-minimal-pie-chart";
import AddAttributes from "../Partners/AddAttributes";
import { getPartnerAttributes } from "../../redux/actions/PartnerActions";
import { getOutdatedCatalogs } from "../../redux/actions/PartnerCataogActions";
import { useDispatch, useSelector } from "react-redux";
import PartnerCatalogService from "../../services/PartnerCatalogService";
import { toast } from "react-toastify";
import { separator } from "../../utilis/functions";

function PartnerCatalogDetails() {
    const history = useHistory();
    const dispatch = useDispatch();
    const params = useLocation();
    const [showAddAttributes, setShowAddAttributes] = useState(false);
    const [loadingRunMapping, setLoadingRunMapping] = useState(false);
    const partner = params?.state?.params?.partner;

    const { activeCatalog, outadatedCatalog } = useSelector((state) => ({
        activeCatalog: state?.partnerReducer.partnerGroupedCatalogs?.header,
        outadatedCatalog:
            state?.partnerReducer.partnerGroupedCatalogs?.header?.outdated ??
            false,
    }));

    useEffect(() => {
        dispatch(getOutdatedCatalogs());
    }, []);

    return (
        <>
            <Helmet>
                <title>Partners content</title>
            </Helmet>
            <div className="page-wrapper" style={{ display: "block" }}>
                <div className="page-breadcrumb">
                    <div className="row">
                        <div className="col-12 align-self-center">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                                    <button
                                        className="btn"
                                        onClick={() => {
                                            history.push("/provider-content");
                                        }}
                                        style={{ paddingLeft: "0px" }}
                                    >
                                        <i className="fas fa-arrow-left"></i>
                                    </button>
                                    {`${partner?.name ?? ""} details`}
                                </h3>
                            </div>
                        </div>
                        {activeCatalog?.has_mapping_error && (
                            <div className="col-lg-12 col-md-12">
                                <div
                                    className="alert alert-warning"
                                    role="alert"
                                    style={{
                                        borderRadius: "12px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div style={{ marginTop: "9px" }}>
                                        <i
                                            className="fas fa-exclamation-triangle"
                                            style={{
                                                marginRight: "6px",
                                            }}
                                        ></i>
                                        A mapping error was removed from this
                                        partner's active catalog mapping. Please
                                        make sure you download or export the
                                        updated version.
                                    </div>
                                </div>
                            </div>
                        )}
                        {outadatedCatalog && (
                            <div className="col-lg-12 col-md-12">
                                <div
                                    className="alert alert-warning"
                                    role="alert"
                                    style={{
                                        borderRadius: "12px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div style={{ marginTop: "9px" }}>
                                        <i
                                            className="fas fa-exclamation-triangle"
                                            style={{
                                                marginRight: "6px",
                                            }}
                                        ></i>
                                        Your partner's catalog has been updated,
                                        your mapping is no longer up to date.
                                        Re-run mapping ?
                                    </div>

                                    <button
                                        className="btn-info btn"
                                        style={{
                                            marginLeft: "5px",
                                            borderRadius: "10px",
                                            marginBottom: "2px",
                                        }}
                                        onClick={async (e) => {
                                            e.stopPropagation();
                                            setLoadingRunMapping(true);
                                            try {
                                                const res =
                                                    await PartnerCatalogService.runOutdatedMapping(
                                                        [partner?.company_id]
                                                    );
                                                setLoadingRunMapping(false);
                                                if (res.status === 200) {
                                                    toast.success(
                                                        res?.data?.message
                                                    );
                                                } else {
                                                    toast.error(res.message);
                                                }
                                            } catch {
                                                setLoadingRunMapping(false);
                                            }
                                        }}
                                        disabled={loadingRunMapping}
                                    >
                                        {loadingRunMapping && (
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        )}
                                        Run Mapping
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div
                            className="col-3 col-md-12 col-lg-3"
                            style={{ paddingRight: "0px" }}
                        >
                            <div
                                className={`card boxShadow card-info card-partner-details`}
                                style={{
                                    borderRadius: "0.75rem",
                                }}
                            >
                                <div
                                    className="card-body"
                                    style={{
                                        padding: "28px",
                                    }}
                                >
                                    <div className="d-flex d-lg-flex d-md-block align-items-center">
                                        <div>
                                            <h2
                                                className="text-dark mb-1 w-100 text-truncate font-weight-medium"
                                                style={{
                                                    fontSize: "20px",
                                                    marginTop: "8px",
                                                }}
                                            >
                                                Mapping Rate
                                            </h2>
                                            <h6 className="text-muted font-weight-bold mb-0 w-100 text-truncate">
                                                (
                                                {`Last update :  ${
                                                    activeCatalog?.last_mapping_date
                                                        ? moment(
                                                              activeCatalog?.last_mapping_date,
                                                              "YYYYMMDD HH:mm:ss Z"
                                                          ).fromNow()
                                                        : "--"
                                                }`}
                                                )
                                            </h6>{" "}
                                            <h6
                                                style={{
                                                    marginBottom: "9px",
                                                }}
                                            >
                                                {" "}
                                            </h6>
                                        </div>
                                        <div className="ml-auto mt-md-3 mt-lg-0">
                                            <span className="opacity-7 text-muted">
                                                <PieChart
                                                    style={{
                                                        height: "58px",
                                                    }}
                                                    data={[
                                                        {
                                                            value:
                                                                (
                                                                    activeCatalog?.affiliate_mapped *
                                                                    100
                                                                ).toFixed(0) ??
                                                                0,

                                                            title: `${
                                                                (
                                                                    activeCatalog?.affiliate_mapped *
                                                                    100
                                                                )?.toFixed(0) ??
                                                                0
                                                            }%`,
                                                            color: "rgb(21, 191, 174)",
                                                        },
                                                    ]}
                                                    totalValue={100}
                                                    lineWidth={20}
                                                    label={({ dataEntry }) =>
                                                        dataEntry.title
                                                    }
                                                    labelStyle={{
                                                        fontSize: "25px",
                                                        fontFamily:
                                                            "sans-serif",
                                                        fill: "rgb(21, 191, 174)",
                                                    }}
                                                    labelPosition={0}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-lg-3 home"
                            style={{ paddingRight: "0px" }}
                        >
                            <div
                                className={`card boxShadow card-info card-partner-details`}
                                style={{
                                    borderRadius: "0.75rem",
                                }}
                            >
                                <div
                                    className="card-body"
                                    style={{
                                        padding: "28px",
                                    }}
                                >
                                    <div className="d-flex d-md-block align-items-center">
                                        <div>
                                            <h2
                                                className="text-dark mb-1 w-100 text-truncate font-weight-medium"
                                                style={{
                                                    fontSize: "18px",
                                                }}
                                            >
                                                Inventory
                                            </h2>

                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent:
                                                        "space-between",
                                                    marginBottom: "4px",
                                                }}
                                            >
                                                <div>
                                                    <h6 className="text-muted font-weight-bold mb-0 w-100 text-truncate">
                                                        Your Hotels
                                                    </h6>
                                                </div>
                                                <div>
                                                    <h6 className="text-muted font-weight-bold mb-0 w-100 text-truncate">
                                                        {activeCatalog?.affiliate_total
                                                            ? separator(
                                                                  activeCatalog?.affiliate_total
                                                              )
                                                            : 0}
                                                    </h6>
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent:
                                                        "space-between",
                                                }}
                                            >
                                                <div>
                                                    <h6 className="text-muted font-weight-bold mb-0 w-100 text-truncate">
                                                        Partner Hotels
                                                    </h6>{" "}
                                                </div>
                                                <div>
                                                    <h6 className="text-muted font-weight-bold mb-0 w-100 text-truncate">
                                                        {activeCatalog?.company_total
                                                            ? separator(
                                                                  activeCatalog?.company_total
                                                              )
                                                            : 0}
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-lg-3 home"
                            style={{ paddingRight: "0px" }}
                        >
                            <div
                                className={`card boxShadow card-info card-partner-details`}
                                style={{
                                    borderRadius: "0.75rem",
                                }}
                            >
                                <div
                                    className="card-body"
                                    style={{
                                        padding: "28px",
                                    }}
                                >
                                    <div className="d-flex d-md-block align-items-center">
                                        <div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent:
                                                        "space-between",
                                                }}
                                            >
                                                <div>
                                                    <h2
                                                        className="text-dark mb-1 w-100 text-truncate font-weight-medium"
                                                        style={{
                                                            fontSize: "18px",
                                                        }}
                                                    >
                                                        Mapped
                                                    </h2>
                                                </div>
                                                <div>
                                                    <h2
                                                        className="text-dark mb-1 w-100 text-truncate font-weight-medium"
                                                        style={{
                                                            fontSize: "18px",
                                                        }}
                                                    >
                                                        {activeCatalog?.total_mapped
                                                            ? separator(
                                                                  activeCatalog?.total_mapped
                                                              )
                                                            : 0}
                                                    </h2>
                                                </div>
                                            </div>

                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent:
                                                        "space-between",
                                                    marginBottom: "4px",
                                                }}
                                            >
                                                <div>
                                                    <h6 className="text-muted font-weight-bold mb-0 w-100 text-truncate">
                                                        Your inventory
                                                    </h6>
                                                </div>
                                                <div>
                                                    <span
                                                        class="badge bg-primary font-12 text-white font-weight-medium badge-pill ml-2 d-md-none d-lg-block"
                                                        style={{
                                                            padding: "2px 10px",
                                                        }}
                                                    >
                                                        {`${(
                                                            activeCatalog?.affiliate_mapped *
                                                            100
                                                        )?.toFixed(0)} %`}
                                                    </span>
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent:
                                                        "space-between",
                                                }}
                                            >
                                                <div>
                                                    <h6 className="text-muted font-weight-bold mb-0 w-100 text-truncate">
                                                        Partner inventory
                                                    </h6>
                                                </div>
                                                <div>
                                                    <span
                                                        class="badge bg-primary font-12 text-white font-weight-medium badge-pill ml-2 d-md-none d-lg-block"
                                                        style={{
                                                            padding: "2px 10px",
                                                        }}
                                                    >
                                                        {`${(
                                                            activeCatalog?.company_mapped *
                                                            100
                                                        )?.toFixed(0)} %`}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-lg-3 home"
                            style={{ paddingRight: "0px" }}
                        >
                            <div
                                className={`card boxShadow card-info card-partner-details`}
                                style={{
                                    borderRadius: "0.75rem",
                                }}
                            >
                                <div
                                    className="card-body"
                                    style={{
                                        padding: "28px",
                                    }}
                                >
                                    <div className="d-flex d-md-block align-items-center">
                                        <div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent:
                                                        "space-between",
                                                }}
                                            >
                                                <div>
                                                    <h2
                                                        className="text-dark mb-1 w-100 text-truncate font-weight-medium"
                                                        style={{
                                                            fontSize: "18px",
                                                        }}
                                                    >
                                                        Opportunities
                                                    </h2>
                                                </div>
                                                <div>
                                                    <h2
                                                        className="text-dark mb-1 w-100 text-truncate font-weight-medium"
                                                        style={{
                                                            fontSize: "18px",
                                                        }}
                                                    >
                                                        {activeCatalog?.total_opportunities
                                                            ? separator(
                                                                  activeCatalog?.total_opportunities
                                                              )
                                                            : 0}
                                                    </h2>
                                                </div>
                                            </div>

                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent:
                                                        "space-between",
                                                    marginBottom: "4px",
                                                }}
                                            >
                                                <div>
                                                    <h6 className="text-muted font-weight-bold mb-0 w-100 text-truncate">
                                                        Your inventory
                                                    </h6>
                                                </div>
                                                <div>
                                                    <span
                                                        class="badge bg-danger font-12 text-white font-weight-medium badge-pill ml-2 d-md-none d-lg-block"
                                                        style={{
                                                            padding: "2px 10px",
                                                        }}
                                                    >
                                                        {`${(
                                                            activeCatalog?.affiliate_opportunities *
                                                            100
                                                        )?.toFixed(0)} %`}
                                                    </span>
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent:
                                                        "space-between",
                                                }}
                                            >
                                                <div>
                                                    <h6 className="text-muted font-weight-bold mb-0 w-100 text-truncate">
                                                        Partner inventory
                                                    </h6>{" "}
                                                </div>
                                                <div>
                                                    <span
                                                        class="badge bg-danger font-12 text-white font-weight-medium badge-pill ml-2 d-md-none d-lg-block"
                                                        style={{
                                                            padding: "2px 10px",
                                                        }}
                                                    >
                                                        {`${(
                                                            activeCatalog?.company_opportunities *
                                                            100
                                                        )?.toFixed(0)} %`}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <CatalogPartnersTableDetails partner={partner} />
                        </div>
                    </div>
                </div>
            </div>
            {showAddAttributes && (
                <AddAttributes
                    show={showAddAttributes}
                    handleClose={() => setShowAddAttributes(false)}
                    currentElement={partner}
                />
            )}
        </>
    );
}
export default PartnerCatalogDetails;
function ButtonAddAttribute(props) {
    const dispatch = useDispatch();
    const [pending, setPending] = React.useState(false);
    const { item, setShowAddAttributes } = props;

    return (
        <Link
            disabled={pending}
            onClick={async () => {
                setPending(true);
                await dispatch(getPartnerAttributes(item?.id));
                //setCurrentElement(item);
                setPending(false);
                setShowAddAttributes(true);
            }}
        >
            <i className="fas fa-cogs" style={{ marginRight: "10px" }}></i>
            Manage partner attributes
            {pending && (
                <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
            )}
        </Link>
    );
}
