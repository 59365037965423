/* eslint-disable import/no-anonymous-default-export */
import {
    GET_PARTNERS_LIST_SUCCESS,
    GET_PARTNERS_LIST_FAIL,
    GET_PARTNERS_LIST_LISTING,
    GET_PARTNER_ATTRIBUTES_LISTING,
    GET_PARTNER_ATTRIBUTES_FAIL,
    GET_PARTNER_ATTRIBUTES_SUCCESS,
    GET_PARTNER_CATALOGS_LAST_UPDATE_FAIL,
    GET_PARTNER_CATALOGS_LAST_UPDATE_LISTING,
    GET_PARTNER_CATALOGS_LAST_UPDATE_SUCCESS,
    CHECK_PARTNER_CATALOGS_LISTING,
    CHECK_PARTNER_CATALOGS_SUCCESS,
    CHECK_PARTNER_CATALOGS_FAIL,
    GET_PARTNER_GROUPED_LIST_FAILD,
    GET_PARTNER_GROUPED_LIST_SUCCESS,
    GET_PARTNER_GROUPED_LIST_LISTING,
    CLEAR_PARTNER_HAS_CATALOG,
    GET_PARTNERS_INVENTORIES_LISTING,
    GET_PARTNERS_INVENTORIES_SUCCESS,
    GET_PARTNERS_INVENTORIES_FAIL,
    GET_PARTNER_GROUPED_LIST_LISTING_PERIODIQUE,
} from "../types";

const initialState = {
    partners: [],
    loading: false,
    loadingAttributes: false,
    partnerAttributes: [],
    loadingLastUpdateCatalogs: false,
    lastUpdateCatalogs: [],
    partnerHasCatalog: {},
    loadingPartnerHasCatalog: false,
    partnerGroupedCatalogs: {},
    loadingPartnerGroupedCatalog: false,
    partnerInventories: [],
    loadingPartnerInventories: false,
    loadingPartnerGroupedCatalogPeriodique: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PARTNERS_LIST_LISTING:
            return {
                ...state,
                loading: true,
            };
        case GET_PARTNERS_LIST_SUCCESS:
            return {
                ...state,
                partners: action.payload,
                loading: false,
            };
        case GET_PARTNERS_LIST_FAIL:
            return {
                ...state,
                loading: false,
            };
        case GET_PARTNER_ATTRIBUTES_LISTING:
            return {
                ...state,
                loadingAttributes: true,
            };
        case GET_PARTNER_ATTRIBUTES_SUCCESS:
            return {
                ...state,
                partnerAttributes: action.payload,
                loadingAttributes: false,
            };
        case GET_PARTNER_ATTRIBUTES_FAIL:
            return {
                ...state,
                loadingAttributes: false,
            };

        case GET_PARTNER_CATALOGS_LAST_UPDATE_LISTING:
            return {
                ...state,
                loadingLastUpdateCatalogs: true,
            };
        case GET_PARTNER_CATALOGS_LAST_UPDATE_SUCCESS:
            return {
                ...state,
                lastUpdateCatalogs: action.payload,
                loadingLastUpdateCatalogs: false,
                loadingPartnerGroupedCatalogPeriodique: false,
            };
        case GET_PARTNER_CATALOGS_LAST_UPDATE_FAIL:
            return {
                ...state,
                loadingLastUpdateCatalogs: false,
                loadingPartnerGroupedCatalogPeriodique: false,
            };
        case CHECK_PARTNER_CATALOGS_LISTING:
            return {
                ...state,
                loadingPartnerHasCatalog: true,
            };
        case CHECK_PARTNER_CATALOGS_SUCCESS:
            return {
                ...state,
                partnerHasCatalog: action.payload,
                loadingPartnerHasCatalog: false,
            };
        case CHECK_PARTNER_CATALOGS_FAIL:
            return {
                ...state,
                loadingPartnerHasCatalog: false,
            };
        case GET_PARTNER_GROUPED_LIST_LISTING:
            return {
                ...state,
                loadingPartnerGroupedCatalog: true,
            };
        case GET_PARTNER_GROUPED_LIST_LISTING_PERIODIQUE:
            return {
                ...state,
                loadingPartnerGroupedCatalogPeriodique: true,
            };
        case GET_PARTNER_GROUPED_LIST_SUCCESS:
            return {
                ...state,
                partnerGroupedCatalogs: action.payload,
                loadingPartnerGroupedCatalog: false,
            };
        case GET_PARTNER_GROUPED_LIST_FAILD:
            return {
                ...state,
                loadingPartnerGroupedCatalog: false,
                partnerGroupedCatalogs: {},
            };
        case CLEAR_PARTNER_HAS_CATALOG:
            return {
                ...state,
                partnerHasCatalog: {},
                loadingPartnerHasCatalog: false,
            };
        case GET_PARTNERS_INVENTORIES_LISTING:
            return {
                ...state,
                loadingPartnerInventories: true,
            };
        case GET_PARTNERS_INVENTORIES_SUCCESS:
            return {
                ...state,
                loadingPartnerInventories: false,
                partnerInventories: action.payload.data,
            };
        case GET_PARTNERS_INVENTORIES_FAIL:
            return {
                ...state,
                loadingPartnerInventories: false,
            };
        default:
            return state;
    }
}
