import React from "react";
import { Link } from "react-router-dom";
import Avatar from "react-avatar";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { USER_LOGOUT } from "../redux/types";
import { useCookies } from "react-cookie";
import InvitationsBar from "./InvitationsBar";

function Header() {
    const [cookies, setCookie, removeCookie] = useCookies();

    const dispatch = useDispatch();
    const history = useHistory();
    return (
        <header className="topbar" data-navbarbg="skin6">
            <nav className="navbar top-navbar navbar-expand-md">
                <div className="navbar-header" data-logobg="skin6">
                    <Link
                        className="nav-toggler waves-effect waves-light d-block d-md-none"
                        to=""
                    >
                        <i className="ti-menu ti-close"></i>
                    </Link>
                    <div className="navbar-brand">
                        <Link to="/catalogs-manager">
                            <span className="logo-text">
                                Cup<span className="purple-text">id</span>&nbsp;
                                <span
                                    className="purple-text"
                                    style={{
                                        fontSize: "17px",
                                        margineft: "8px",
                                        fontweight: "bold",
                                    }}
                                >
                                    Beta
                                </span>
                            </span>
                        </Link>
                    </div>
                </div>
                <div
                    className="navbar-collapse collapse"
                    id="navbarSupportedContent"
                >
                    <InvitationsBar />
                    <ul className="navbar-nav float-right">
                        <li className="nav-item dropdown">
                            <Link
                                className="nav-link dropdown-toggle"
                                to="#"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <Avatar
                                    name={localStorage.getItem("userConnected")}
                                    size="45"
                                    round={true}
                                    textSizeRatio={1.75}
                                    color="#6275E7"
                                />
                            </Link>
                            <div
                                className="dropdown-menu dropdown-menu-right mailbox animated bounceInDown"
                                style={{ borderRadius: "15px" }}
                            >
                                <Link
                                    className="dropdown-item"
                                    onClick={async (evt) => {
                                        evt.preventDefault();
                                        localStorage.clear();
                                        removeCookie(
                                            "intercom-session-nhu68eka"
                                        );

                                        window.location =
                                            window.location.protocol +
                                            "//" +
                                            window.location.host +
                                            "/sign-in";
                                        // history.push("/sign-in");
                                        dispatch({ type: USER_LOGOUT });
                                    }}
                                >
                                    <i className="fas fa-sign-out-alt"></i>
                                    Logout
                                </Link>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    );
}
export default Header;
