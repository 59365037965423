import React from "react";
import signUp from "../lotties/sign-up.gif";
import CLOSE_BUTTON from "../lotties/close-button.png";

const styleBox = {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "flex-start",
    borderRadius: "13px",
    backgroundColor: "#5f76e821",
    width: "100%",
    padding: "10px",
};

const CloseButtonStyle = {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
};

const AnimationStyle = {
    marginTop: "8px",
    textAlign: "center",
    width: "100%",
};

const divContainerStyle = {
    textAlign: "center",
    width: "100%",
    paddingTop: "10px",
};

const signUpButtonStyle = {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: "13px",
    marginBottom: "15px",
    fontSize: "15px",
};

const TextStyle = { fontSize: "13px", fontWeight: "500" };

const IconContainerStyle = {
    borderRadius: "50%",
    backgroundColor: "rgba(95, 118, 232, 0.13)",
    width: "90px",
    alignItems: "center",
    justifyContent: "center",
    padding: "10x",
    height: "90px",
    textAlign: "center",
};
const SignUpCompanyModal = () => {
    const [showBar, setShowBar] = React.useState(true);

    return (
        <>
            {showBar ? (
                <div
                    style={{
                        padding: "10px",
                        // position: "absolute",
                        bottom: "10px",
                    }}
                >
                    <div style={{ ...styleBox }}>
                        <div
                            style={{
                                ...CloseButtonStyle,
                            }}
                        >
                            <button
                                className="btn"
                                style={{ padding: "0px" }}
                                onClick={() => {
                                    setShowBar(false);
                                }}
                            >
                                <img
                                    src={CLOSE_BUTTON}
                                    height="20"
                                    width="20"
                                    alt="Close"
                                />
                                {/* <i class="fas fa-times"></i> */}
                            </button>
                        </div>
                        <div style={{ ...AnimationStyle }}>
                            <img
                                src={signUp}
                                height="100"
                                width="100"
                                alt="Hello"
                            />
                        </div>
                        <div
                            style={{
                                ...divContainerStyle,
                            }}
                        >
                            <div
                                style={{
                                    ...TextStyle,
                                }}
                            >
                                You're currently logged in with an affiliate
                                account, which gives you the opportunity to see
                                your hotel mapping performance with the partner
                                who invited you.
                            </div>
                            <div
                                style={{
                                    ...TextStyle,
                                }}
                            >
                                But why stop there?
                            </div>
                            <div
                                style={{
                                    ...TextStyle,
                                }}
                            >
                                View your mapping performance with{" "}
                                <span style={{ fontWeight: "bold" }}>
                                    All partners!
                                </span>
                                <br /> Sign up for free and upgrade your hotel
                                mapping experience
                            </div>
                        </div>
                        <div
                            style={{
                                ...signUpButtonStyle,
                            }}
                        >
                            <button
                                className="btn btn-primary"
                                style={{ fontSize: "15px" }}
                                onClick={() => {
                                    window.open(
                                        process.env.REACT_APP_COMPANY_SIGN_UP,
                                        "_blank"
                                    );
                                }}
                            >
                                Sign up for free
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div
                    style={{
                        position: "absolute",
                        bottom: "10px",
                        left: "10px",
                    }}
                >
                    <div
                        style={{
                            ...IconContainerStyle,
                        }}
                    >
                        <button
                            className="btn"
                            onClick={() => {
                                setShowBar(true);
                            }}
                            style={{ padding: "0px" }}
                        >
                            <img
                                src={signUp}
                                height="70"
                                width="70"
                                alt="Hello"
                                style={{ borderRadius: "50%" }}
                            />
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default SignUpCompanyModal;
