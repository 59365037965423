/* eslint-disable import/no-anonymous-default-export */
import {
    GET_CATALOGS_SUCCESS,
    GET_CATALOGS_FAIL,
    GET_CATALOGS_LISTING,
    GET_PROGRESS_CATALOG_SUCCESS,
    GET_PROGRESS_CATALOG_FAIL,
    GET_PROGRESS_CATALOG_LISTING,
    GET_CATALOG_SUCCESS,
    GET_CATALOG_FAIL ,
    GET_CATALOG_LISTING,
    SHOW_WELCOME_DIALOG,
    HIDE_WELCOME_DIALOG,
    OPEN_DIALOG_ADD_NEW_CATALOG,
    HIDE_DIALOG_ADD_NEW_CATALOG
} from '../types';

const initialState = {
    catalogs:[],
    loading:false,   
    loadingCatalog : false,
    catalog : {},
    progress : 0,
    loadingProgress:false,
    showWelcomeDialog:false,
    openDialogCatalog : false


}

export default function(state = initialState, action){
    switch(action.type){

        case GET_CATALOGS_LISTING:
            return {
                ...state,
                loading:true
            }   
        case GET_CATALOGS_SUCCESS:
            return {
                ...state,
                catalogs : action.payload,
                loading:false
            }   
        case GET_CATALOGS_FAIL:
            return {
                ...state,
                loading:false
            }      
        case GET_PROGRESS_CATALOG_LISTING:
            return {
                ...state,
                loadingProgress:true
            }   
        case GET_PROGRESS_CATALOG_SUCCESS:
            return {
                ...state,
                progress : action.payload,
                loadingProgress:false
            }   
        case GET_PROGRESS_CATALOG_FAIL:
            return {
                ...state,
                loadingProgress:false
            }        
        case GET_CATALOG_LISTING:
            return {
                ...state,
                loadingCatalog:true
            }   
        case GET_CATALOG_SUCCESS:
            return {
                ...state,
                catalog : action.payload,
                loadingCatalog:false
            }      
        case SHOW_WELCOME_DIALOG:
            return {
                ...state,
                showWelcomeDialog : true
            }        
        case HIDE_WELCOME_DIALOG:
            return {
                ...state,
                showWelcomeDialog : false
            }
        case OPEN_DIALOG_ADD_NEW_CATALOG:
            return {
                ...state,
                openDialogCatalog:true
            }    
        case HIDE_DIALOG_ADD_NEW_CATALOG:
            return {
                ...state,
                openDialogCatalog:false
            }         
        case GET_CATALOG_FAIL:
            return {
                ...state,
                loadingCatalog:false
            }
        default: return state
    }

}