import { combineReducers } from "redux";
import DashBoardReducer from "./DashboardReducer";
import CatalogReducer from "./CatalogReducer";
import PartnerCatalogReducer from "./PartnerCatalogReducer";
import PartnerReducer from "./PartnerReducer";
import LoginReducer from "./LoginReducer";
import CompanyReducer from "./CompanyReducer";
import ManageTeamReducer from "./ManageTeamReducer";
import AutoPilotReducer from "./AutoPilotReducer";
import { USER_LOGOUT } from "../types";
import ModelsReducer from "./ModelsReducer";

const appReducer = combineReducers({
    dashboardReducer: DashBoardReducer,
    catalogReducer: CatalogReducer,
    partnerCatalogReducer: PartnerCatalogReducer,
    partnerReducer: PartnerReducer,
    loginReducer: LoginReducer,
    companyReducer: CompanyReducer,
    manageTeamReducer: ManageTeamReducer,
    autoPilotReducer: AutoPilotReducer,
    modelsReducer: ModelsReducer,
});

const rootReducer = (state, action) => {
    if (action.type === USER_LOGOUT) {
        return appReducer(undefined, action);
    }

    return appReducer(state, action);
};
export default rootReducer;
