import { Modal } from "react-bootstrap";
import React from "react";
import { useHistory } from "react-router-dom";
import Lottie from "react-lottie";
import animationData from "../../lotties/success.json";

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};
function FinishProcessingDialog(props) {
    const { show, handleClose } = props;
    const history = useHistory();
    const onClose = () => {
        handleClose();
        history.push("/provider-content");
    };
    return (
        <Modal
            show={show}
            onHide={onClose}
            dialogClassName="modal-40w modal-dialog modal-dialog-centered"
        >
            <Modal.Body style={{ textAlign: "center" }}>
                <Lottie options={defaultOptions} width={188} />
                <h3
                    className="modalTitle"
                    style={{
                        fontSize: "30px",
                        marginTop: "30px",
                        marginBottom: "13px",
                    }}
                >
                    Inventory Uploaded Successfully
                </h3>
                <p className="modalP" style={{ marginBottom: "15px" }}>
                    You can now run mapping with your Partner's
                </p>

                <button
                    className="modalBtn btn waves-effect waves-light btn-rounded btn-primary"
                    onClick={onClose}
                >
                    Go to Mapppings
                </button>
            </Modal.Body>
        </Modal>
    );
}

export default FinishProcessingDialog;
