import axios from "axios";
const url = process.env.REACT_APP_BASE_URL;

const PartnerService = {
    uploadPartnerCatalog: async (req, opt) => {
        const result = await axios
            .post(url + "/partner-catalogs/upload", req, opt)
            .then((res) => {
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },

    dowloadPartnerCatalog: async (id) => {
        const result = await axios
            .get(url + `/mappings/download/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                responseType: "blob",
            })
            .then((res) => {
                const re = new RegExp('filename=\\"([a-zA-Z0-9.\\-_]+)');
                return {
                    data: res.data,
                    fileName:
                        res.headers?.["content-disposition"].match(re)?.[1],
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },
    exportPartnerCatalog: async (destination_id, catalog_id) => {
        const formData = new FormData();
        formData.append("destination_id", destination_id);
        formData.append("catalog_id", catalog_id);
        const result = await axios
            .post(url + "/destinations/execute", formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });

        return result;
    },

    addNewPartner: async (partnerName, opt) => {
        const formData = new FormData();
        formData.append("name", partnerName);
        window.analytics.track("Sign in", {
            userID: localStorage.getItem("userId"),
            companyID: localStorage.getItem("companyId"),
            companyName: localStorage.getItem("companyName"),
            firstName: localStorage.getItem("firstName"),
            lastName: localStorage.getItem("lastName"),
            partnerName: partnerName,
        });

        const result = await axios
            .post(url + "/partner/create", formData, opt)
            .then((res) => {
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },

    reviewHotel: async (id, req) => {
        const opt = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        const result = await axios
            .put(url + `/partner-catalogs/candidates/${id}`, req, opt)
            .then((res) => {
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },
    addPartnerAttributes: async (req) => {
        const opt = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        const result = await axios
            .post(url + `/partners/attributes/`, req, opt)
            .then((res) => {
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },
};

export default PartnerService;
