import React from "react";
import Avatar from "react-avatar";
import { Link, useHistory } from "react-router-dom";

const AcceptInvitation = () => {
    const history = useHistory();
    return (
        <div
            data-aos-easing="ease-out-quad"
            data-aos-duration="700"
            data-aos-delay="0"
        >
            <section className="bg-gray-200">
                <div className="container d-flex flex-column">
                    <div className="row align-items-center justify-content-center gx-0 min-vh-100">
                        <div className="col-12-sign-in col-md-6 col-lg-6 py-8 py-md-11">
                            <div
                                style={{
                                    marginBottom: "10px",
                                    diplay: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <img
                                    src="../assets/images/favicon-32x32.png"
                                    alt="cupid"
                                    style={{
                                        marginRight: "6px",
                                        marginBottom: "17px",
                                    }}
                                />
                                <span
                                    className="logo-text"
                                    style={{ fontSize: "32px" }}
                                >
                                    Cup
                                    <span className="purple-text">id</span>
                                    &nbsp;
                                </span>
                            </div>
                            <div
                                className="card"
                                style={{ borderRadius: "1rem" }}
                            >
                                <div className="card-body">
                                    <h1
                                        className="mb-0 fw-bold text-center"
                                        style={{ fontWeight: "700" }}
                                    >
                                        Join the{" "}
                                        <span className="logo-text">
                                            Cup
                                            <span className="purple-text">
                                                id
                                            </span>
                                            &nbsp;
                                        </span>
                                        team
                                    </h1>
                                    <div
                                        style={{
                                            border: "2px solid #6173e766",
                                            borderRadius: "10px",
                                            marginBottom: "35px",
                                            marginTop: "35px",
                                            padding: "17px",
                                        }}
                                    >
                                        <Avatar
                                            name={"Ilyas oudghiri"}
                                            round={true}
                                            size={50}
                                            color="#6275e7"
                                            style={{
                                                marginLeft: "208px",
                                                marginTop: "-60px",
                                                fontSize: "17px",
                                            }}
                                        />
                                        <h4
                                            className="mb-0 fw-bold text-center"
                                            style={{ fontWeight: "700" }}
                                        >
                                            Ilyas Oudghiri invited you
                                        </h4>
                                        <p style={{ marginTop: "10px" }}>
                                            Lorem ipsum dolor sit amet,
                                            consectetur adipiscing elit. Nulla
                                            tempus bibendum ullamcorper. Sed
                                            quis metus pharetra risus suscipit
                                            cursus eget a turpis. Integer dictum
                                            mauris vitae bibendum venenatis.
                                            Phasellus porta, mauris quis semper
                                            convallis, ipsum arcu vehicula nunc,
                                            eu viverra felis nunc in nulla.
                                            Nulla sed tempus orci, eu tincidunt
                                            lectus. Mauris id nisl tristique,
                                            aliquam lacus ut, porta mi. Mauris
                                            euismod magna nec volutpat
                                            condimentum.
                                        </p>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <button
                                            className="btn2 w-100 btn-primary2"
                                            type="submit"
                                            // style={{
                                            //     marginRight: "15px",
                                            //     justifyContent: "space-between",
                                            //     display: "flex",
                                            // }}
                                            onClick={() =>
                                                history.push("/sign-up")
                                            }
                                            //disabled={loading}
                                        >
                                            Accept
                                        </button>

                                        <button
                                            className="btn2 w-100 btn-warning2"
                                            type="submit"
                                            style={{ marginLeft: "10px" }}

                                            //disabled={loading}
                                        >
                                            Decline
                                        </button>
                                    </div>
                                    {/* <p
                                        className="mb-0 fs-sm text-center text-muted"
                                        style={{ marginLeft: "-21px" }}
                                    >
                                        Or&nbsp;
                                        <Link
                                            style={{
                                                textDecoration: "underline",
                                            }}
                                        >
                                            Decline
                                        </Link>
                                    </p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default AcceptInvitation;
