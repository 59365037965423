import {
    GET_MAPPING_CATALOGS_SUCCESS,
    GET_MAPPING_CATALOGS_FAIL,
    GET_MAPPING_CATALOGS_LISTING,
    GET_MAPPINGS_LISTING,
    GET_MAPPINGS_SUCCESS,
    GET_MAPPINGS_FAIL,
    CLEAR_CATALOG_HOTEL_TO_REVIEW,
    GET_CATALOGS_FIRST_FAIL,
    GET_CATALOGS_FIRST_LISTING,
    GET_CATALOGS_FIRST_SUCCESS,
    GET_PARTNER_CATALOGS_BY_ID_FAIL,
    GET_PARTNER_CATALOGS_BY_ID_LISTING,
    GET_PARTNER_CATALOGS_BY_ID_SUCCESS,
    GET_OUTDATED_CATALOGS_FAIL,
    GET_OUTDATED_CATALOGS_SUCCESS,
    GET_OUTDATED_CATALOGS_LISTING,
} from "../types";
import axios from "axios";
import { getPartnerCatalogsLastUpdate } from "../actions/PartnerActions";

const PATH_URL = process.env.REACT_APP_BASE_URL;

export const getPartnerCatalogs = (id) => async (dispatch) => {
    dispatch({
        type: GET_PARTNER_CATALOGS_BY_ID_LISTING,
    });
    dispatch({
        type: CLEAR_CATALOG_HOTEL_TO_REVIEW,
    });
    try {
        const res = await axios.get(PATH_URL + "/catalogs", {
            params: { companyId: id },
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        dispatch({
            type: GET_PARTNER_CATALOGS_BY_ID_SUCCESS,
            payload: { catalogs: res.data.data, average: res.data.average },
        });
    } catch (e) {
        dispatch({
            type: GET_PARTNER_CATALOGS_BY_ID_FAIL,
        });
    }
};
export const getPartnerCatalogsFirst = (id) => async (dispatch) => {
    dispatch({
        type: GET_PARTNER_CATALOGS_BY_ID_LISTING,
    });
    dispatch({
        type: CLEAR_CATALOG_HOTEL_TO_REVIEW,
    });
    try {
        const res = await axios.get(PATH_URL + "/catalogs", {
            params: { companyId: id },
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        dispatch({
            type: GET_PARTNER_CATALOGS_BY_ID_SUCCESS,
            payload: { catalogs: res.data.data, average: res.data.average },
        });
    } catch (e) {
        dispatch({
            type: GET_PARTNER_CATALOGS_BY_ID_FAIL,
        });
    }
};
export const getPartnerCatalogsByStatus =
    (id, statusList) => async (dispatch) => {
        dispatch({
            type: GET_MAPPING_CATALOGS_LISTING,
        });
        dispatch({
            type: CLEAR_CATALOG_HOTEL_TO_REVIEW,
        });

        try {
            const res = await axios.get(PATH_URL + "/catalogs", {
                params: { companyId: id, status: statusList.join(",") },
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            if (res?.data?.data?.length === 0) {
                dispatch(getPartnerCatalogsLastUpdate());
            }
            dispatch({
                type: GET_MAPPING_CATALOGS_SUCCESS,
                payload: { catalogs: res.data.data, average: res.data.average },
            });
        } catch (e) {
            dispatch({
                type: GET_MAPPING_CATALOGS_FAIL,
            });
        }
    };

export const getPartnerListFirst = () => async (dispatch) => {
    dispatch({
        type: GET_CATALOGS_FIRST_LISTING,
    });
    try {
        const res = await axios.get(PATH_URL + "api/partner-catalogs", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        dispatch({
            type: GET_CATALOGS_FIRST_SUCCESS,
            payload: res.data.data,
        });
    } catch (e) {
        dispatch({
            type: GET_CATALOGS_FIRST_FAIL,
        });
    }
};
export const getMappings = () => async (dispatch) => {
    dispatch({
        type: GET_MAPPINGS_LISTING,
    });
    try {
        const res = await axios.get(PATH_URL + "/mappings", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        dispatch({
            type: GET_MAPPINGS_SUCCESS,
            payload: res.data.data,
        });
    } catch (e) {
        dispatch({
            type: GET_MAPPINGS_FAIL,
        });
    }
};

export const getOutdatedCatalogs = () => async (dispatch) => {
    dispatch({
        type: GET_OUTDATED_CATALOGS_LISTING,
    });
    try {
        const res = await axios.get(PATH_URL + `/outdated-catalogs`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        dispatch({
            type: GET_OUTDATED_CATALOGS_SUCCESS,
            payload: res.data,
        });
    } catch (e) {
        dispatch({
            type: GET_OUTDATED_CATALOGS_FAIL,
        });
    }
};
