import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import AddPartnerAttribute from "../../components/AddPartnerAttribute";
import { toast } from "react-toastify";
import PartnerService from "../../services/PartnerService";
import { useDispatch, useSelector } from "react-redux";

function AddAttributes(props) {
    const dispatch = useDispatch();
    const { show, handleClose, currentElement } = props;
    const [loadingSaveAttributes, setLoadingSaveAttributes] = useState(false);
    const [listAttributes, setListAttributes] = useState([]);
    const { partnerAttributes, loadingAttributes } = useSelector((state) => ({
        loadingAttributes: state.partnerReducer.loadingAttributes,
        partnerAttributes: state.partnerReducer.partnerAttributes ?? [],
    }));

    React.useEffect(() => {
        setListAttributes(partnerAttributes);
    }, [loadingAttributes]);

    const handleSave = async () => {
        setLoadingSaveAttributes(true);
        const res = await PartnerService.addPartnerAttributes({
            partner_id: currentElement?.id,
            attributes: listAttributes,
        });
        if (res.status === 200) {
            toast.success("Created Successfully");
            handleClose();
        } else {
            toast.error(res.message, {
                toastId: "partner-attributes",
            });
        }
        setLoadingSaveAttributes(false);
    };
    return (
        <Modal
            show={show}
            onHide={handleClose}
            dialogClassName={"modal-50w modal-dialog modal-dialog-centered"}
        >
            <Modal.Header
                style={{
                    textAlign: "center",
                    display: "block",
                    color: "#6a76e9",
                    fontWeight: "500",
                    fontSize: "27px",
                }}
            >
                Add custom informations about your partner
            </Modal.Header>
            <Modal.Body style={{ paddingBottom: "0px" }}>
                <AddPartnerAttribute
                    listAttributes={listAttributes}
                    setListAttributes={setListAttributes}
                    hideButton={true}
                />
                <Modal.Footer>
                    <button
                        type="button"
                        className="btn btn-warning"
                        onClick={handleClose}
                    >
                        close
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleSave}
                        disabled={loadingAttributes}
                    >
                        Save
                        {loadingSaveAttributes && (
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        )}
                    </button>
                </Modal.Footer>
            </Modal.Body>
        </Modal>
    );
}
export default AddAttributes;
