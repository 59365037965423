import CatalogsManagerScreen from "./pages/Catalog/CatalogsManagerScreen";
import PartnersDetails from "./pages/Mapping/PartnersDetails";
import PartnerCatalogDetails from "./pages/Mapping/PartnersCatalogDetails";
import BasicInfoScreen from "./pages/Company/BasicInfoScreen";

const routes = [
    {
        path: "/catalogs-manager",
        component: CatalogsManagerScreen,
    },
    {
        path: "/provider-content/details/:id",
        component: PartnerCatalogDetails,
    },
    {
        path: "/provider-content",
        component: PartnersDetails,
    },
    {
        path: "/basic-info",
        component: BasicInfoScreen,
    },
];
export default routes;
