import React, { useEffect, useState } from "react";
import { getPartnerInventories } from "../../redux/actions/PartnerActions";
import { useDispatch, useSelector } from "react-redux";
import { Row, Card, Col } from "react-bootstrap";
import Avatar from "react-avatar";
import moment from "moment";
import ReactPaginate from "react-paginate";

const CompaniesInventories = () => {
    const dispatch = useDispatch();
    const { partnerInventories, loadingPartnerInventories } = useSelector(
        (state) => ({
            partnerInventories: state.partnerReducer?.partnerInventories,
            loadingPartnerInventories:
                state.partnerReducer?.loadingPartnerInventories,
        })
    );
    const [filter, setFilter] = useState("");
    const [currentItems, setCurrentItems] = useState();

    const [displayedData, setDisplayedData] =
        React.useState(partnerInventories);
    const [itemsPerPage, setItemsPerPage] = useState(9);

    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
        dispatch(getPartnerInventories());
    }, []);

    useEffect(() => {
        if (filter !== "") {
            const newData = partnerInventories.filter((element) => {
                let match = false;

                let stringValue = element?.name?.toString() ?? "";
                match =
                    match ||
                    stringValue
                        ?.toLowerCase()
                        ?.search(filter?.toLowerCase()) !== -1;
                return match;
            });

            setDisplayedData(newData);
        } else {
            setDisplayedData(partnerInventories);
        }
    }, [filter, loadingPartnerInventories]);

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(displayedData.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(displayedData.length / itemsPerPage));
    }, [
        itemOffset,
        itemsPerPage,
        filter,
        loadingPartnerInventories,
        displayedData,
    ]);

    const handlePageClick = (event) => {
        const newOffset =
            (event.selected * itemsPerPage) % displayedData.length;
        setItemOffset(newOffset);
    };

    return (
        <div className="container-fluid">
            {loadingPartnerInventories ? (
                <div className="text-center">
                    <div className="lds-ripple">
                        <div className="lds-pos"></div>
                        <div className="lds-pos"></div>
                    </div>
                </div>
            ) : (
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="card-body" style={{ padding: "0px" }}>
                            <div className="search-input">
                                <span className="hevo-web-icon search-icon hevo-search">
                                    <i className="fas fa-search"></i>
                                </span>
                                <input
                                    style={{ width: "90%" }}
                                    type="text"
                                    name="filter"
                                    value={filter}
                                    onChange={(e) =>
                                        setFilter(e?.target?.value)
                                    }
                                    placeholder="Search Partner"
                                    autoComplete="off"
                                    id="inte_input"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                        <div className="">
                            <div
                                className="card-body"
                                style={{ padding: "0px" }}
                            >
                                <div className="row">
                                    {currentItems?.map((e, index) => {
                                        return (
                                            <div className="col-6 col-md-5 col-lg-4">
                                                <Card
                                                    className="card-destination"
                                                    style={{ cursor: "auto" }}
                                                >
                                                    <Row className="no-gutters">
                                                        <Col md={1} lg={1}>
                                                            <Avatar
                                                                name={e?.company_name?.charAt(
                                                                    0
                                                                )}
                                                                round={true}
                                                                size={40}
                                                                color="#222"
                                                                style={{
                                                                    marginLeft:
                                                                        "11px",
                                                                    marginTop:
                                                                        "25px",
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <Card.Body>
                                                                <Card.Title
                                                                    style={{
                                                                        display:
                                                                            "flex",

                                                                        justifyContent:
                                                                            "space-between",
                                                                        marginBottom:
                                                                            "25px",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            color: "#444",
                                                                        }}
                                                                    >
                                                                        {
                                                                            e?.company_name
                                                                        }
                                                                        <div
                                                                            style={{
                                                                                color: "rgb(128, 134, 152)",
                                                                                font: '500 1rem / 16px Roboto, "Helvetica Neue", sans-serif',
                                                                                letterSpacing:
                                                                                    "0.3px",
                                                                                fontWeight:
                                                                                    "500",
                                                                                fontSize:
                                                                                    "1rem",
                                                                                paddingTop:
                                                                                    "5px",
                                                                            }}
                                                                        >
                                                                            Last
                                                                            update
                                                                            :&nbsp;
                                                                            {moment(
                                                                                new Date(
                                                                                    e?.inventory?.created_at
                                                                                )
                                                                            ).format(
                                                                                "DD/MM/YYYY"
                                                                            )}
                                                                        </div>
                                                                        <div
                                                                            style={{
                                                                                color: "rgb(128, 134, 152)",
                                                                                font: '500 1rem / 16px Roboto, "Helvetica Neue", sans-serif',
                                                                                letterSpacing:
                                                                                    "0.3px",
                                                                                fontWeight:
                                                                                    "500",
                                                                                fontSize:
                                                                                    "1rem",
                                                                                paddingTop:
                                                                                    "5px",
                                                                            }}
                                                                        >
                                                                            Total
                                                                            hotels
                                                                            :&nbsp;
                                                                            {
                                                                                e
                                                                                    ?.inventory
                                                                                    ?.total_hotels
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </Card.Title>

                                                                <Card.Text className="card-text-mapping-details">
                                                                    <span
                                                                        text="white"
                                                                        className={`badge rounded-pill text-white 
                                                                                        `}
                                                                        style={{
                                                                            width: "100%",
                                                                            fontWeight: 800,
                                                                            fontSize:
                                                                                "13px",
                                                                            height: "20px",
                                                                            backgroundColor:
                                                                                "#8971eabd",
                                                                        }}
                                                                    >
                                                                        Upload
                                                                        your
                                                                        inventory
                                                                    </span>
                                                                </Card.Text>
                                                            </Card.Body>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </div>
                                        );
                                    })}
                                    <div className="col-lg-12 col-md-12">
                                        {currentItems?.length > 0 ? (
                                            <div
                                                className="row"
                                                style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                }}
                                            >
                                                <ReactPaginate
                                                    className="react-pagination-div"
                                                    breakClassName={"page-item"}
                                                    breakLinkClassName={
                                                        "page-link"
                                                    }
                                                    containerClassName={
                                                        "pagination"
                                                    }
                                                    pageClassName={"page-item"}
                                                    pageLinkClassName={
                                                        "page-link"
                                                    }
                                                    previousClassName={
                                                        "page-item"
                                                    }
                                                    previousLinkClassName={
                                                        "page-link"
                                                    }
                                                    nextClassName={"page-item"}
                                                    nextLinkClassName={
                                                        "page-link"
                                                    }
                                                    activeClassName={"active"}
                                                    breakLabel="..."
                                                    nextLabel=" >"
                                                    onPageChange={
                                                        handlePageClick
                                                    }
                                                    pageRangeDisplayed={5}
                                                    pageCount={pageCount}
                                                    // initialPage={
                                                    //     currentPage
                                                    // // }
                                                    // forcePage={
                                                    //     currentPage
                                                    // }
                                                    previousLabel="<"
                                                    renderOnZeroPageCount={null}
                                                />
                                            </div>
                                        ) : currentItems?.length === 0 &&
                                          filter !== "" ? (
                                            <div
                                                className="card"
                                                style={{
                                                    padding: "2rem",
                                                    width: "100%",
                                                }}
                                            >
                                                <div className="empty text-center">
                                                    <p>
                                                        Sorry, we couldn&apos;t
                                                        find any partner for
                                                        your search criteria.
                                                        Please check your
                                                        information and try
                                                        again.
                                                    </p>
                                                </div>
                                            </div>
                                        ) : (
                                            <div
                                                className="card"
                                                style={{
                                                    padding: "2rem",
                                                    width: "100%",
                                                }}
                                            >
                                                <div className="empty text-center">
                                                    <p>No data to display.</p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* <CatalogNoDataModal
                title="Your dedicated workspace is almost ready to go !"
                text="First, you’ll need to upload your hotel inventory."
                buttonText="Upload inventory"
                handleClick={async () => {
                    history.push("/catalogs-manager");
                }}
            /> */}
        </div>
    );
};

export default CompaniesInventories;
