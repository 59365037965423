import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import LoginService from "../../services/LoginService";
import { useParams, useHistory } from "react-router-dom";
import { STYLE_INPUT } from "../../utilis/const";

const schema = yup.object().shape({
    password: yup.string().required("Password is required"),
    password2: yup.string().required("Please confirm your password"),
});

function ConfirmPassword(props) {
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState("password");
    const [showPassword2, setShowPassword2] = useState("password");
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });
    let { token } = useParams();
    const history = useHistory();
    const handleChangePassword = async (values) => {
        if (values?.password2 !== values?.password) {
            toast.error("Passwords must match");
        } else {
            setLoading(true);
            const res = await LoginService.resetPasswordConfirm(token, values);
            try {
                if (res.status === 200) {
                    setLoading(false);
                    setTimeout(() => {
                        history.push("/sign-in");
                    }, 3000);
                    return toast.success(
                        " Your password was reset successfully",
                        {
                            toastId: "reset-password",
                        }
                    );
                } else {
                    setLoading(false);
                    toast.error(res?.message);
                }
            } catch (err) {
                setLoading(false);
                toast.error("Server error");
            }
        }
    };
    return (
        <>
            <Helmet>
                <title> Sign in</title>
            </Helmet>
            <div
                data-aos-easing="ease-out-quad"
                data-aos-duration="700"
                data-aos-delay="0"
            >
                <section className="bg-gray-200">
                    <div className="container d-flex flex-column">
                        <div className="row align-items-center justify-content-center gx-0 min-vh-100">
                            <div className="col-12-sign-in col-md-5 col-lg-5 py-8 py-md-11">
                                <h1 className="mb-0 fw-bold text-center">
                                    Reset your password
                                </h1>
                                <p
                                    className="mb-6 text-center text-muted"
                                    style={{ marginTop: "1rem" }}
                                >
                                    You have confirmed your email, and may now
                                    update your password below.
                                </p>
                                <form
                                    className="mb-6"
                                    onSubmit={handleSubmit(
                                        handleChangePassword
                                    )}
                                >
                                    <div className="form-group">
                                        <div className="input-group">
                                            <input
                                                className="form-control"
                                                type={showPassword}
                                                name="password"
                                                id="newPassword"
                                                autoComplete="off"
                                                placeholder="Enter your new password"
                                                {...register("password")}
                                                style={{
                                                    ...STYLE_INPUT,
                                                }}
                                            />
                                            <span
                                                class="input-group-text"
                                                style={{
                                                    border: "none",
                                                }}
                                            >
                                                {showPassword === "password" ? (
                                                    <i
                                                        className="fa fa-eye"
                                                        id="togglePassword"
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                            setShowPassword(
                                                                "text"
                                                            )
                                                        }
                                                    ></i>
                                                ) : (
                                                    <i
                                                        className="fas fa-eye-slash"
                                                        id="togglehidePassword"
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                            setShowPassword(
                                                                "password"
                                                            )
                                                        }
                                                    ></i>
                                                )}
                                            </span>
                                        </div>
                                        {errors.password && (
                                            <small
                                                id="emailHelp"
                                                className="text-danger form-text"
                                            >
                                                {errors.password.message}
                                            </small>
                                        )}
                                    </div>
                                    <div className="form-group mb-5">
                                        <div className="input-group">
                                            <input
                                                className="form-control"
                                                type={showPassword2}
                                                name="password2"
                                                id="password2"
                                                placeholder="Confirm your password"
                                                autoComplete="off"
                                                {...register("password2")}
                                                style={{
                                                    ...STYLE_INPUT,
                                                }}
                                            />
                                            <span
                                                class="input-group-text"
                                                style={{
                                                    border: "none",
                                                }}
                                            >
                                                {showPassword2 ===
                                                "password" ? (
                                                    <i
                                                        className="fa fa-eye"
                                                        id="togglePassword"
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                            setShowPassword2(
                                                                "text"
                                                            )
                                                        }
                                                    ></i>
                                                ) : (
                                                    <i
                                                        className="fas fa-eye-slash"
                                                        id="togglehidePassword"
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                            setShowPassword2(
                                                                "password"
                                                            )
                                                        }
                                                    ></i>
                                                )}
                                            </span>
                                        </div>
                                        {errors.password2 && (
                                            <small
                                                id="emailHelp"
                                                className="text-danger form-text"
                                            >
                                                {errors.password2.message}
                                            </small>
                                        )}
                                    </div>
                                    <button
                                        className="btn2 w-100 btn-primary2"
                                        type="submit"
                                        disabled={loading}
                                    >
                                        Change password
                                        {loading && (
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        )}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}
export default ConfirmPassword;
