import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import LoginService from "../../services/LoginService";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { getUserConnected } from "../../redux/actions/DashboardActions";
import { Helmet } from "react-helmet";
import { isMobile } from "react-device-detect";
import useQuery from "../../hooks/useQuery";
import { STYLE_INPUT } from "../../utilis/const";

function SignUp(props) {
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState("password");
    const [showMobileContent, setShowMobileContent] = useState(false);
    const [loadingLogin, setLoadingLogin] = useState(false);
    const history = useHistory();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    let query = useQuery();

    const dispatch = useDispatch();
    const handleSignUp = async (data) => {
        setLoading(true);
        let response = await LoginService.signUp({
            ...data,
            verification_code: query.get("invitation_code"),
            // affiliate_id: query.get("affiliate_id")
            //     ? parseInt(query.get("affiliate_id"), 10)
            //     : 0,
        });
        try {
            if (response.status === 200) {
                toast.success("Created !");
                setLoadingLogin(true);
                let res = await LoginService.signIn({
                    login: response?.data?.data?.email,
                    password: data?.password,
                });
                try {
                    if (res.status === 200) {
                        localStorage.setItem("isAuthenticated", "true");
                        localStorage.setItem("userConnected", data.name);
                        localStorage.setItem(
                            `welcome_${response?.data?.data?.email}`,
                            true
                        );
                        localStorage.setItem(
                            `email`,
                            response?.data?.data?.email
                        );

                        await dispatch(getUserConnected());
                        history.push("/catalogs-manager");
                    } else {
                        setLoadingLogin(false);
                        toast.error(res.message);
                    }
                } catch (err) {
                    setLoadingLogin(false);
                    toast.error("Server error");
                }
            } else {
                setLoading(false);
                toast.error(response.message);
            }
        } catch (err) {
            setLoading(false);
            toast.error("Server error");
        }
        setLoading(false);
    };
    const renderContent = () => {
        if (showMobileContent) {
            return (
                <div
                    data-aos-easing="ease-out-quad"
                    data-aos-duration="700"
                    data-aos-delay="0"
                >
                    <section className="bg-gray-200">
                        <div className="container d-flex flex-column">
                            <div className="row align-items-center justify-content-center gx-0 min-vh-100">
                                <div className="col-12-sign-in col-md-5 col-lg-5 py-8 py-md-11">
                                    <h1 className="mb-0 fw-bold text-center">
                                        This content is available only on
                                        browser{" "}
                                    </h1>

                                    <p className="mb-6 text-center text-muted"></p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            );
        } else {
            return (
                <>
                    <Helmet>
                        <title> Sign up</title>
                    </Helmet>
                    <div
                        data-aos-easing="ease-out-quad"
                        data-aos-duration="700"
                        data-aos-delay="0"
                    >
                        <section className="bg-gray-200">
                            <div className="container d-flex flex-column">
                                <div className="row align-items-center justify-content-center gx-0 min-vh-100">
                                    <div className="col-12-sign-in col-md-5 col-lg-5 py-8 py-md-11">
                                        <div
                                            class="text-center"
                                            style={{
                                                marginBottom: "60px",
                                            }}
                                        >
                                            <img
                                                src="../assets/images/cupid_affiliate_logo.png"
                                                alt="wrapkit"
                                                height="150px"
                                                width="300px"
                                            />
                                        </div>
                                        <h1 className="mb-5 fw-bold text-center">
                                            Sign up
                                        </h1>
                                        <form
                                            className="mb-6"
                                            onSubmit={handleSubmit(
                                                handleSignUp
                                            )}
                                        >
                                            <div className="form-group">
                                                <label
                                                    className="form-label"
                                                    htmlFor="name"
                                                >
                                                    Name
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control2"
                                                    name="name"
                                                    id="name"
                                                    placeholder="Enter your name"
                                                    autoComplete="off"
                                                    {...register("name")}
                                                />
                                                {errors?.name && (
                                                    <small
                                                        id="emailHelp"
                                                        className="text-danger form-text"
                                                    >
                                                        {errors?.name?.message}
                                                    </small>
                                                )}
                                            </div>

                                            {/* <div className="form-group mb-5">
                                                <label
                                                    className="form-label"
                                                    htmlFor="password"
                                                >
                                                    Password
                                                </label>
                                                <input
                                                    type="password"
                                                    className="form-control2"
                                                    name="password"
                                                    id="password"
                                                    placeholder="Enter your password"
                                                    autoComplete="off"
                                                    {...register("password")}
                                                />
                                                {errors.password && (
                                                    <small
                                                        id="emailHelp"
                                                        className="text-danger form-text"
                                                    >
                                                        {
                                                            errors.password
                                                                .message
                                                        }
                                                    </small>
                                                )}
                                            </div> */}

                                            <div className="form-group mb-5">
                                                <label
                                                    className="form-label"
                                                    htmlFor="password"
                                                >
                                                    Password
                                                </label>
                                                <div className="input-group">
                                                    <input
                                                        className="form-control"
                                                        type={showPassword}
                                                        name="password"
                                                        id="password"
                                                        placeholder="Enter your password"
                                                        autoComplete="off"
                                                        {...register(
                                                            "password"
                                                        )}
                                                        style={{
                                                            ...STYLE_INPUT,
                                                        }}
                                                    />
                                                    <span
                                                        class="input-group-text"
                                                        style={{
                                                            border: "none",
                                                        }}
                                                    >
                                                        {showPassword ===
                                                        "password" ? (
                                                            <i
                                                                className="fa fa-eye"
                                                                id="togglePassword"
                                                                style={{
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={() =>
                                                                    setShowPassword(
                                                                        "text"
                                                                    )
                                                                }
                                                            ></i>
                                                        ) : (
                                                            <i
                                                                className="fas fa-eye-slash"
                                                                id="togglehidePassword"
                                                                style={{
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={() =>
                                                                    setShowPassword(
                                                                        "password"
                                                                    )
                                                                }
                                                            ></i>
                                                        )}
                                                    </span>
                                                </div>
                                                {errors.password && (
                                                    <small
                                                        id="emailHelp"
                                                        className="text-danger form-text"
                                                    >
                                                        {
                                                            errors.password
                                                                .message
                                                        }
                                                    </small>
                                                )}
                                            </div>

                                            <button
                                                className="btn2 w-100 btn-primary2"
                                                type="submit"
                                                disabled={loading}
                                            >
                                                Sign up
                                                {loading && (
                                                    <Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                )}
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </>
            );
        }
    };

    return <>{renderContent()}</>;
}
export default SignUp;

const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    password: yup
        .string()
        .min(8)
        .required("Password is required and must be 8 characters length"),
});
