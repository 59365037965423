import axios from "axios";
const url = process.env.REACT_APP_BASE_URL;

const PartnerCatalogService = {
    runOutdatedMapping: async (data) => {
        const result = await axios
            .post(url + `/run-mapping`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.message,
                    status: error.response.data.code,
                };
            });
        return result;
    },
    ChangeStatus: async (data) => {
        const result = await axios
            .post(url + `/change-catalog-status`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                return {
                    data: res?.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error?.response?.data?.msg,
                    status: error?.response?.data?.code,
                };
            });
        return result;
    },
};

export default PartnerCatalogService;
