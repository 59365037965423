import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
function AddPartnerAttribute(props) {
    const {
        prev,
        next,
        setListAttributes,
        listAttributes,
        loadingSaveAttributes,
        hideButton,
    } = props;

    const [value, setValue] = useState();
    const [name, setName] = useState();

    return (
        <>
            <Modal.Body>
                <p>
                    {!hideButton && (
                        <h4 style={{ fontWeight: "500", color: "#6076e8" }}>
                            Add custom informations about your partner :
                        </h4>
                    )}
                    <span className="sub-title-info ">
                        Partner attributes can be used for customizing your
                        partners informations and adapting them to your specific
                        use cases. Your Partner Attributes will be attached to
                        all of the partner's mapping outputs for you to access
                        outside Cupid. This can be useful when handling mapping
                        outputs in your own systems. An example of attribute can
                        be a partner ID.
                    </span>
                </p>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <div className="row" style={{ width: "90%" }}>
                        <div className="col-5">
                            <input
                                type="text"
                                className="form-control"
                                name="name"
                                id="text"
                                placeholder="Attribute name"
                                value={name}
                                onChange={(e) => {
                                    setName(e?.target?.value);
                                }}
                            />
                        </div>
                        <div className="col-5" style={{ paddingLeft: "0px" }}>
                            <input
                                type="text"
                                className="form-control"
                                name="value"
                                id="value"
                                placeholder="Attribute value "
                                value={value}
                                onChange={(e) => {
                                    setValue(e?.target?.value);
                                }}
                            />
                        </div>
                        <div className="col-2" style={{ paddingLeft: "0px" }}>
                            <button
                                className="btn w-100  btn-primary"
                                style={{
                                    padding: "0.325rem .75rem",
                                }}
                                onClick={() => {
                                    const list = [...listAttributes];
                                    setListAttributes([
                                        ...list,
                                        { name: name, value: value },
                                    ]);
                                    setValue("");
                                    setName("");
                                }}
                            >
                                Add
                            </button>
                        </div>
                        {listAttributes?.length > 0 && (
                            <div
                                class="table-responsive attribute"
                                style={{
                                    marginTop: "20px",
                                    paddingLeft: "15px",
                                    paddingRight: "15px",
                                }}
                            >
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Attribute Name</th>
                                            <th scope="col">Attribute Value</th>
                                            <th scope="col">Remove</th>
                                        </tr>
                                    </thead>{" "}
                                    {listAttributes?.map((e, index) => {
                                        return (
                                            <>
                                                <tbody>
                                                    <tr>
                                                        {/* <th scope="row">1</th> */}
                                                        <td>{e?.name}</td>
                                                        <td>{e?.value}</td>
                                                        <td>
                                                            <button
                                                                className="btn "
                                                                onClick={() => {
                                                                    var array =
                                                                        [
                                                                            ...listAttributes,
                                                                        ];
                                                                    array.splice(
                                                                        index,
                                                                        1
                                                                    );
                                                                    setListAttributes(
                                                                        array
                                                                    );
                                                                }}
                                                            >
                                                                <i
                                                                    class="fas fa-times"
                                                                    style={{
                                                                        color: "red",
                                                                    }}
                                                                ></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </>
                                        );
                                    })}
                                </table>
                            </div>
                        )}
                    </div>
                </div>
            </Modal.Body>
            {hideButton ? (
                <></>
            ) : (
                <Modal.Footer>
                    <button
                        type="button"
                        className="btn btn-warning"
                        onClick={prev}
                    >
                        <i className="fas fa-caret-left"></i> &nbsp;&nbsp; Back
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary"
                        disabled={loadingSaveAttributes}
                        onClick={next}
                    >
                        Next &nbsp;&nbsp;
                        {loadingSaveAttributes && (
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        )}
                        <i className="fas fa-caret-right"></i>
                    </button>
                </Modal.Footer>
            )}
        </>
    );
}
export default AddPartnerAttribute;
