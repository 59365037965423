import {
    GET_PARTNERS_LIST_SUCCESS,
    GET_PARTNERS_LIST_FAIL,
    GET_PARTNERS_LIST_LISTING,
    GET_PARTNER_ATTRIBUTES_LISTING,
    GET_PARTNER_ATTRIBUTES_FAIL,
    GET_PARTNER_ATTRIBUTES_SUCCESS,
    GET_PARTNER_CATALOGS_LAST_UPDATE_FAIL,
    GET_PARTNER_CATALOGS_LAST_UPDATE_LISTING,
    GET_PARTNER_CATALOGS_LAST_UPDATE_SUCCESS,
    GET_PARTNER_GROUPED_LIST_FAILD,
    GET_PARTNER_GROUPED_LIST_SUCCESS,
    GET_PARTNER_GROUPED_LIST_LISTING,
    GET_PARTNERS_INVENTORIES_LISTING,
    GET_PARTNERS_INVENTORIES_SUCCESS,
    GET_PARTNERS_INVENTORIES_FAIL,
    GET_PARTNER_GROUPED_LIST_LISTING_PERIODIQUE,
} from "../types";
import axios from "axios";

const PATH_URL = process.env.REACT_APP_BASE_URL;

export const getPartnerList = () => async (dispatch) => {
    dispatch({
        type: GET_PARTNERS_LIST_LISTING,
    });
    try {
        const res = await axios.get(PATH_URL + "/partner/list", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        dispatch({
            type: GET_PARTNERS_LIST_SUCCESS,
            payload: res.data.data,
        });
    } catch (e) {
        dispatch({
            type: GET_PARTNERS_LIST_FAIL,
        });
    }
};
export const getPartnerAttributes = (partner_id) => async (dispatch) => {
    dispatch({
        type: GET_PARTNER_ATTRIBUTES_LISTING,
    });
    try {
        const res = await axios.get(
            PATH_URL + `/partners/attributes/${partner_id}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        );
        dispatch({
            type: GET_PARTNER_ATTRIBUTES_SUCCESS,
            payload: res.data.data,
        });
    } catch (e) {
        dispatch({
            type: GET_PARTNER_ATTRIBUTES_FAIL,
        });
    }
};

export const getPartnerCatalogsLastUpdate = () => async (dispatch) => {
    dispatch({
        type: GET_PARTNER_CATALOGS_LAST_UPDATE_LISTING,
    });
    try {
        const res = await axios.get(PATH_URL + `/companies`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        dispatch({
            type: GET_PARTNER_CATALOGS_LAST_UPDATE_SUCCESS,
            payload: res.data.data,
        });
    } catch (e) {
        dispatch({
            type: GET_PARTNER_CATALOGS_LAST_UPDATE_FAIL,
        });
    }
};
export const getPartnerCatalogsLastUpdatePeriodique =
    () => async (dispatch) => {
        dispatch({
            type: GET_PARTNER_GROUPED_LIST_LISTING_PERIODIQUE,
        });
        try {
            const res = await axios.get(PATH_URL + `/companies`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            dispatch({
                type: GET_PARTNER_CATALOGS_LAST_UPDATE_SUCCESS,
                payload: res.data.data,
            });
        } catch (e) {
            dispatch({
                type: GET_PARTNER_CATALOGS_LAST_UPDATE_FAIL,
            });
        }
    };

export const partnerGrouppdListByAction = (company_id) => async (dispatch) => {
    dispatch({
        type: GET_PARTNER_GROUPED_LIST_LISTING,
    });
    try {
        const res = await axios.get(PATH_URL + `/companies/${company_id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        dispatch({
            type: GET_PARTNER_GROUPED_LIST_SUCCESS,
            payload: res.data?.data,
        });
    } catch (e) {
        dispatch({
            type: GET_PARTNER_GROUPED_LIST_FAILD,
        });
    }
};
export const partnerGrouppdListByActionPeriodique =
    (company_id) => async (dispatch) => {
        try {
            const res = await axios.get(PATH_URL + `/companies/${company_id}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            dispatch({
                type: GET_PARTNER_GROUPED_LIST_SUCCESS,
                payload: res.data?.data,
            });
        } catch (e) {
            dispatch({
                type: GET_PARTNER_GROUPED_LIST_FAILD,
            });
        }
    };

export const getPartnerInventories = () => async (dispatch) => {
    dispatch({
        type: GET_PARTNERS_INVENTORIES_LISTING,
    });
    try {
        const res = await axios.get(PATH_URL + `/companies`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        dispatch({
            type: GET_PARTNERS_INVENTORIES_SUCCESS,
            payload: res.data,
        });
    } catch (e) {
        dispatch({
            type: GET_PARTNERS_INVENTORIES_FAIL,
        });
    }
};
