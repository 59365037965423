import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import {
    getCompanyData,
    updateCompanyData,
} from "../../redux/actions/CompanyActions";

const schema = yup.object().shape({
    brand_name: yup.string().required("Brand name is required").min(2),
    legal_name: yup.string().required("Legal name is required").min(2),
});

function BasicInfoScreen() {
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm({
        resolver: yupResolver(schema),
    });
    const data = useSelector((state) => ({
        company: state.companyReducer?.company ?? [],
        loading: state.companyReducer?.loading,
        updating: state.companyReducer?.updating,
    }));

    setValue("brand_name", data?.company?.brand_name ?? "");
    setValue("legal_name", data?.company?.legal_name ?? "");
    setValue("vat", data?.company?.vat ?? "");
    setValue("vat_not_available", data?.company?.vat === "");

    useEffect(() => {
        dispatch(getCompanyData());
    }, []);

    const saveCompany = async (data) => {
        dispatch(updateCompanyData(data));
    };
    return (
        <>
            <Helmet>
                <title> Your company</title>
            </Helmet>
            <div className="page-wrapper" style={{ display: "block" }}>
                <div className="page-breadcrumb">
                    <div className="row">
                        <div className="col-7 align-self-center">
                            <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                                Your company
                            </h3>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-6">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex align-items-center mb-4">
                                        <h4 className="card-title">
                                            General information
                                        </h4>
                                    </div>

                                    <form
                                        className="mt-3"
                                        onSubmit={handleSubmit(saveCompany)}
                                    >
                                        <div className="form-group">
                                            <label for="brand_name">
                                                Brand name
                                            </label>
                                            <input
                                                {...register("brand_name")}
                                                type="text"
                                                className="form-control"
                                                aria-describedby="emailHelp"
                                                placeholder="Enter your brand name"
                                            />
                                            {errors.brand_name && (
                                                <small className="text-danger form-text">
                                                    {errors.brand_name.message}
                                                </small>
                                            )}
                                        </div>
                                        <div className="form-group">
                                            <label for="legal_name">
                                                Legal name
                                            </label>
                                            <input
                                                {...register("legal_name")}
                                                type="text"
                                                className="form-control"
                                                aria-describedby="emailHelp"
                                                placeholder="Enter your legal name"
                                            />
                                            {errors.legal_name && (
                                                <small className="text-danger form-text">
                                                    {errors.legal_name.message}
                                                </small>
                                            )}
                                        </div>
                                        <div className="form-group">
                                            <label for="vat">VAT Number</label>
                                            <input
                                                {...register("vat")}
                                                type="text"
                                                className="form-control"
                                                aria-describedby="emailHelp"
                                                placeholder="VAT"
                                            />
                                            {errors.vat && (
                                                <small className="text-danger form-text">
                                                    {errors.vat.message}
                                                </small>
                                            )}
                                        </div>
                                        {/* <div className="custom-control custom-checkbox">
                    <input
                      {...register("vat_not_available")}
                      type="checkbox"
                      className="custom-control-input"
                    />
                    <label className="custom-control-label" for="customCheck2">
                      I don't have a VAT number
                    </label>
                  </div> */}
                                        <div
                                            className="card-footer"
                                            style={{ marginTop: 10 }}
                                        >
                                            {!data.updating ? (
                                                <button
                                                    type="submit"
                                                    className="btn waves-effect waves-light btn-rounded btn-primary"
                                                >
                                                    Save changes
                                                </button>
                                            ) : (
                                                <button
                                                    type="submit"
                                                    className="btn waves-effect waves-light btn-rounded btn-primary"
                                                    disabled
                                                >
                                                    Updating...
                                                </button>
                                            )}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default BasicInfoScreen;
