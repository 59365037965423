import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
    Card,
    Row,
    Col,
    Tooltip,
    OverlayTrigger,
    Spinner,
} from "react-bootstrap";
import Avatar from "react-avatar";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import DownloadCatalog from "./DownloadCatalog";
import {
    getPartnerCatalogsLastUpdate,
    getPartnerCatalogsLastUpdatePeriodique,
    getPartnerInventories,
} from "../../redux/actions/PartnerActions";

import CompaniesInventories from "./CompaniesInventories";
import { getPartnerCatalogsByStatus } from "../../redux/actions/PartnerCataogActions";
import useCollapse from "react-collapsed";
import { ListGroup } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { getCatalogs } from "../../redux/actions/CatalogActions";
import {
    STATUS_PROCESSING,
    STATUS_PENDING,
    STATUS_SUCCESS_CATALOG,
    STATUS_FAILED,
    STATUS_IN_QUEUE,
} from "../../utilis/const";
import { separator } from "../../utilis/functions";
import PartnerOutdatedCatalog from "./PartnerOutdatedCatalog";
import PartnerCatalogService from "../../services/PartnerCatalogService";
import { toast } from "react-toastify";
import RunMappingModal from "../../components/RunMappingModal";

const renderTooltip = (name) => (props) =>
    (
        <Tooltip id="button-tooltip" {...props}>
            {name}
        </Tooltip>
    );

function PartnersDetails() {
    const config = {
        defaultExpanded: true,
    };
    const [filter, setFilter] = useState("");
    const dispatch = useDispatch();
    const history = useHistory();
    const [currentElement, setCurrentElement] = useState();
    const [showRunMapping, setShowRunMapping] = useState(false);
    const { getCollapseProps, getToggleProps, isExpanded } =
        useCollapse(config);

    const {
        dataList,
        loading,
        partnerCatalogs,
        inventories,
        loadingInventories,
        loadingMappingNewCompanies,
        loadingLastUpdateCatalogs,
        loadingPartnerGroupedCatalogPeriodique,
    } = useSelector((state) => ({
        dataList:
            state?.partnerReducer.lastUpdateCatalogs?.map((e) => {
                const mapped_hotels = e?.mapping?.mapped_hotels ?? 0;
                const invalid_hotels = e?.mapping?.invalid_hotels ?? 0;
                const unmapped_hotels =
                    e?.mapping?.valid_hotels - e?.mapping?.mapped_hotels ?? 0;
                const total_hotels = e?.mapping?.valid_hotels ?? 0;
                const pourcentageMappedHotels =
                    total_hotels != 0
                        ? (mapped_hotels / total_hotels) * 100
                        : 0;
                const pourcentageInvalidHotels =
                    total_hotels != 0
                        ? (invalid_hotels / e?.mapping?.total_hotels) * 100
                        : 0;
                const pourcentageUnmappedHotels =
                    100 - pourcentageMappedHotels - pourcentageInvalidHotels;

                return {
                    id: e?.mapping?.id,
                    name: e?.company_name,
                    company_id: e?.id,
                    created_at: e?.mapping?.created_at,
                    pourcentageMappedHotels: pourcentageMappedHotels,
                    pourcentageUnmappedHotels: pourcentageUnmappedHotels,
                    pourcentageInvalidHotels: pourcentageInvalidHotels,
                    unmapped_hotels: unmapped_hotels,
                    mapped_hotels: mapped_hotels,
                    invalid_hotels: invalid_hotels,
                    mapping_process_status_id:
                        e?.mapping?.mapping_process_status_id,
                    outdated: e?.outdated ?? false,
                    hasMapping: e?.mapping ? true : false,
                };
            }) ?? [],
        loading: state?.partnerReducer.loadingLastUpdateCatalogs,
        loadingPartnerGroupedCatalogPeriodique:
            state?.partnerReducer.loadingPartnerGroupedCatalogPeriodique,
        partnerCatalogs:
            state?.partnerCatalogReducer.partnerCatalogs?.map((e, index) => {
                var rate = (
                    ((e?.total_hotels - e?.mapped_hotels - e?.unmapped_hotels) *
                        1.6) /
                    1000 /
                    60
                ).toFixed(0, 10);
                return { ...e, rate: rate };
            }) ?? [],
        loadingMappingNewCompanies:
            state?.partnerCatalogReducer.loadingMappingNewCompanies,
        loadingLastUpdateCatalogs:
            state?.partnerReducer.loadingLastUpdateCatalogs,
        inventories: state.catalogReducer?.catalogs ?? [],
        loadingInventories: state.catalogReducer?.loading ?? [],
    }));
    const [displayedData, setDisplayedData] = React.useState(dataList);
    const [itemsPerPage, setItemsPerPage] = useState(9);
    const [currentItems, setCurrentItems] = useState();
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
        dispatch(getCatalogs());
        dispatch(getPartnerCatalogsLastUpdate());
        dispatch(getPartnerInventories());
        dispatch(
            getPartnerCatalogsByStatus(undefined, [
                STATUS_PENDING,
                STATUS_PROCESSING,
            ])
        );
        //  dispatch(getMappingNewCompanies());
    }, []);
    useEffect(() => {
        if (filter !== "") {
            const newData = dataList.filter((element) => {
                let match = false;

                let stringValue = element?.name?.toString() ?? "";
                match =
                    match ||
                    stringValue
                        ?.toLowerCase()
                        ?.search(filter?.toLowerCase()) !== -1;
                return match;
            });

            setDisplayedData(newData);
        } else {
            setDisplayedData(dataList);
        }
    }, [filter, loading, loadingPartnerGroupedCatalogPeriodique]);

    useEffect(() => {
        setInterval(() => {
            if (
                window.location?.pathname !== "/sign-in" &&
                window.location.pathname === "/provider-content"
            ) {
                dispatch(
                    getPartnerCatalogsByStatus(undefined, [
                        STATUS_PENDING,
                        STATUS_PROCESSING,
                    ])
                );
                dispatch(getPartnerCatalogsLastUpdatePeriodique());
            }
        }, 30000);
    }, []);

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(displayedData.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(displayedData.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, filter, loading, displayedData]);

    const handlePageClick = (event) => {
        const newOffset =
            (event.selected * itemsPerPage) % displayedData.length;
        setItemOffset(newOffset);
    };
    return (
        <>
            <Helmet>
                <title>Partners content</title>
            </Helmet>
            <div className="page-wrapper" style={{ display: "block" }}>
                <div className="page-breadcrumb">
                    <div className="row">
                        <div className="col-12 align-self-center">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                                    Partners content
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                {loadingInventories ||
                loadingLastUpdateCatalogs ||
                loadingMappingNewCompanies ? (
                    <div className="text-center">
                        <div className="lds-ripple">
                            <div className="lds-pos"></div>
                            <div className="lds-pos"></div>
                        </div>
                    </div>
                ) : inventories?.length === 0 ? (
                    <CompaniesInventories />
                ) : (
                    <div className="container-fluid">
                        <div className="row">
                            {false && (
                                <PartnerOutdatedCatalog
                                    outadatedCatalogs={dataList
                                        ?.filter(
                                            (e) =>
                                                e?.outdated === true &&
                                                e?.hasMapping === true
                                        )
                                        ?.map((e) => ({
                                            ...e,
                                            id: e?.company_id,
                                        }))}
                                />
                            )}

                            {partnerCatalogs?.length > 0 && (
                                <>
                                    <div className="col-lg-12 col-md-12">
                                        <div
                                            className="collapsible "
                                            style={{
                                                marginBottom: "20px",
                                                border: "2px solid rgb(99 117 231 / 77%)",
                                                borderRadius: "8px",
                                            }}
                                        >
                                            <div
                                                className="header"
                                                {...getToggleProps()}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "space-between",
                                                    }}
                                                >
                                                    <div>
                                                        <i
                                                            className="fas fa-info-circle"
                                                            style={{
                                                                color: "rgb(254 192 106)",
                                                                fontSize:
                                                                    "20px",
                                                            }}
                                                        ></i>
                                                        &nbsp;
                                                        {`You have ${
                                                            partnerCatalogs?.filter(
                                                                (e) =>
                                                                    e?.mapping_process_status_id ===
                                                                        STATUS_PROCESSING ||
                                                                    e?.mapping_process_status_id ===
                                                                        STATUS_PENDING
                                                            )?.length
                                                        } mapping currently processing. `}
                                                    </div>
                                                    <div>
                                                        <>
                                                            {isExpanded ? (
                                                                <div
                                                                    className="hideOldBlocs"
                                                                    style={{
                                                                        margin: "0px",
                                                                    }}
                                                                >
                                                                    <h4>
                                                                        <Link
                                                                            style={{
                                                                                fontSize:
                                                                                    "17px",
                                                                            }}
                                                                        >
                                                                            Hide
                                                                            details
                                                                        </Link>
                                                                    </h4>
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    className="showOldBlocs"
                                                                    style={{
                                                                        margin: "0px",
                                                                    }}
                                                                >
                                                                    <h4>
                                                                        <Link
                                                                            style={{
                                                                                fontSize:
                                                                                    "17px",
                                                                            }}
                                                                        >
                                                                            More
                                                                            details
                                                                        </Link>
                                                                    </h4>
                                                                </div>
                                                            )}
                                                        </>
                                                    </div>
                                                </div>
                                            </div>
                                            <div {...getCollapseProps()}>
                                                <div className="content">
                                                    <ListGroup variant="flush">
                                                        {partnerCatalogs?.map(
                                                            (e, index) => {
                                                                return (
                                                                    <ListGroup.Item
                                                                        key={
                                                                            index
                                                                        }
                                                                        style={{
                                                                            backgroundColor:
                                                                                "#eeeffd",
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() => {
                                                                            history.push(
                                                                                `/provider-content/details/${e?.company_id}`,
                                                                                {
                                                                                    params: {
                                                                                        partner:
                                                                                            {
                                                                                                name: e.partner_name,
                                                                                                id: e.company_id,
                                                                                                created_at:
                                                                                                    e?.created_at,
                                                                                            },
                                                                                    },
                                                                                }
                                                                            );
                                                                        }}
                                                                    >
                                                                        <div className="row">
                                                                            <div className="col-lg-3">
                                                                                {e?.mapping_process_status_id ===
                                                                                    STATUS_PROCESSING ||
                                                                                e?.mapping_process_status_id ===
                                                                                    STATUS_PENDING ? (
                                                                                    <Spinner
                                                                                        animation="border"
                                                                                        variant="primary"
                                                                                        size="sm"
                                                                                    />
                                                                                ) : (
                                                                                    <i
                                                                                        className="fas fa-check"
                                                                                        style={{
                                                                                            color: "green",
                                                                                        }}
                                                                                    ></i>
                                                                                )}
                                                                                &nbsp;&nbsp;
                                                                                {
                                                                                    e?.name
                                                                                }
                                                                                {e?.has_mapping_error && (
                                                                                    <OverlayTrigger
                                                                                        placement="top"
                                                                                        delay={{
                                                                                            show: 250,
                                                                                            hide: 400,
                                                                                        }}
                                                                                        overlay={renderTooltip(
                                                                                            " Warning ! A mapping error was removed from this catalog's mapping. Take necessary action"
                                                                                        )}
                                                                                    >
                                                                                        <i
                                                                                            className="fas fa-exclamation-triangle"
                                                                                            style={{
                                                                                                color: "rgb(254, 192, 106)",
                                                                                                marginLeft:
                                                                                                    "5px",
                                                                                            }}
                                                                                        ></i>
                                                                                    </OverlayTrigger>
                                                                                )}
                                                                                {e?.mapping_process_status_id ===
                                                                                    STATUS_PROCESSING && (
                                                                                    <small
                                                                                        style={{
                                                                                            fontSize:
                                                                                                "13px",
                                                                                            color: "#21CA7F",
                                                                                            fontWeight:
                                                                                                "900",
                                                                                            margin: "0px",
                                                                                        }}
                                                                                    >
                                                                                        &nbsp;Estimated
                                                                                        time
                                                                                        ~
                                                                                        {e?.rate ??
                                                                                            0}
                                                                                        min
                                                                                    </small>
                                                                                )}
                                                                            </div>
                                                                            <div className="col-lg-2 expanded-div">
                                                                                {`${e?.partner_name} `}
                                                                            </div>
                                                                            <div className="col-lg-3 expanded-div">
                                                                                {`${
                                                                                    e?.total_hotels
                                                                                        ? separator(
                                                                                              e?.total_hotels
                                                                                          )
                                                                                        : 0
                                                                                }
                                                                                Total`}
                                                                                {e?.hotels_rate !==
                                                                                    0 &&
                                                                                    e?.hotels_rate !==
                                                                                        null &&
                                                                                    e?.mapping_process_status_id ===
                                                                                        STATUS_PENDING && (
                                                                                        <>
                                                                                            &nbsp;
                                                                                            <OverlayTrigger
                                                                                                placement="top"
                                                                                                delay={{
                                                                                                    show: 250,
                                                                                                    hide: 400,
                                                                                                }}
                                                                                                overlay={renderTooltip(
                                                                                                    "Rate/Sec"
                                                                                                )}
                                                                                            >
                                                                                                <small
                                                                                                    style={{
                                                                                                        fontSize:
                                                                                                            "13px",
                                                                                                        color: "#21CA7F",
                                                                                                        fontWeight:
                                                                                                            "900",
                                                                                                        margin: "0px",
                                                                                                    }}
                                                                                                >
                                                                                                    {
                                                                                                        e?.hotels_rate
                                                                                                    }
                                                                                                    /Sec
                                                                                                </small>
                                                                                            </OverlayTrigger>
                                                                                        </>
                                                                                    )}
                                                                            </div>

                                                                            <div className="col-lg-2 expanded-div">
                                                                                {`${
                                                                                    e?.mapped_hotels
                                                                                        ? separator(
                                                                                              e?.mapped_hotels
                                                                                          )
                                                                                        : 0
                                                                                }
                                                                                Mapped`}
                                                                            </div>
                                                                            <div className="col-lg-2 expanded-div">
                                                                                {`${
                                                                                    e?.unmapped_hotels
                                                                                        ? separator(
                                                                                              e?.unmapped_hotels
                                                                                          )
                                                                                        : 0
                                                                                }
                                                                                Opportunities`}
                                                                            </div>
                                                                        </div>
                                                                    </ListGroup.Item>
                                                                );
                                                            }
                                                        )}
                                                    </ListGroup>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className="col-lg-12 col-md-12">
                                <div
                                    className="card-body"
                                    style={{ padding: "0px" }}
                                >
                                    <div className="search-input">
                                        <span className="hevo-web-icon search-icon hevo-search">
                                            <i className="fas fa-search"></i>
                                        </span>
                                        <input
                                            style={{ width: "90%" }}
                                            type="text"
                                            name="filter"
                                            value={filter}
                                            onChange={(e) =>
                                                setFilter(e?.target?.value)
                                            }
                                            placeholder="Search Partner"
                                            autoComplete="off"
                                            id="inte_input"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-lg-12 col-md-12"
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <div
                                    className="col-lg-6 col-md-6"
                                    style={{
                                        paddingLeft: "0px",
                                        paddingRight: "0px",
                                    }}
                                >
                                    <Card
                                        className="card-partner-details"
                                        style={{
                                            marginBottom: "17px",
                                            padding: "8px",
                                        }}
                                    >
                                        <Col md={12} lg={12}>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent:
                                                        "space-between",
                                                }}
                                            >
                                                <div>
                                                    <span
                                                        className="dot"
                                                        style={{
                                                            backgroundColor:
                                                                "rgb(21, 191, 174)",
                                                        }}
                                                    ></span>
                                                    <span
                                                        style={{
                                                            fontSize: "small",
                                                        }}
                                                    >
                                                        &nbsp; Mapped
                                                        hotels&nbsp;
                                                    </span>
                                                </div>
                                                <div>
                                                    <span
                                                        className="dot"
                                                        style={{
                                                            backgroundColor:
                                                                "rgb(242, 95, 41)",
                                                        }}
                                                    ></span>
                                                    <span
                                                        style={{
                                                            fontSize: "small",
                                                        }}
                                                    >
                                                        &nbsp; Not Mapped hotels
                                                        &nbsp;
                                                    </span>
                                                </div>
                                                <div>
                                                    <span
                                                        className="dot"
                                                        style={{
                                                            backgroundColor:
                                                                "rgba(18, 46, 64, 0.14)",
                                                        }}
                                                    ></span>
                                                    <span
                                                        style={{
                                                            fontSize: "small",
                                                        }}
                                                    >
                                                        &nbsp; Invalid hotels
                                                    </span>
                                                </div>
                                            </div>
                                        </Col>
                                    </Card>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <div className="">
                                    <div
                                        className="card-body"
                                        style={{ padding: "0px" }}
                                    >
                                        <div className="row">
                                            {currentItems?.map((e, index) => {
                                                return (
                                                    <div className="col-6 col-md-5 col-lg-4">
                                                        <Card
                                                            className="card-destination"
                                                            onClick={() => {
                                                                if (
                                                                    e?.hasMapping
                                                                ) {
                                                                    history.push(
                                                                        `/provider-content/details/${e?.company_id}`,
                                                                        {
                                                                            params: {
                                                                                partner:
                                                                                    {
                                                                                        ...e,
                                                                                        id: e.company_id,
                                                                                    },
                                                                            },
                                                                        }
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <Row className="no-gutters">
                                                                <Col
                                                                    md={1}
                                                                    lg={1}
                                                                >
                                                                    <Avatar
                                                                        name={e?.name?.charAt(
                                                                            0
                                                                        )}
                                                                        round={
                                                                            true
                                                                        }
                                                                        size={
                                                                            40
                                                                        }
                                                                        color="#222"
                                                                        style={{
                                                                            marginLeft:
                                                                                "11px",
                                                                            marginTop:
                                                                                "25px",
                                                                        }}
                                                                    />
                                                                </Col>
                                                                <Col>
                                                                    <Card.Body>
                                                                        <Card.Title
                                                                            style={{
                                                                                display:
                                                                                    "flex",

                                                                                justifyContent:
                                                                                    "space-between",
                                                                                marginBottom:
                                                                                    e?.outdated
                                                                                        ? "22px"
                                                                                        : "25px",
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    color: "#444",
                                                                                }}
                                                                            >
                                                                                {
                                                                                    e?.name
                                                                                }

                                                                                {(e?.mapping_process_status_id ===
                                                                                    STATUS_PROCESSING ||
                                                                                    e?.mapping_process_status_id ===
                                                                                        STATUS_PROCESSING) && (
                                                                                    <Spinner
                                                                                        animation="border"
                                                                                        variant="primary"
                                                                                        size="sm"
                                                                                        style={{
                                                                                            marginLeft:
                                                                                                "4px",
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                                <div
                                                                                    style={{
                                                                                        color: "rgb(128, 134, 152)",
                                                                                        font: '500 1rem / 16px Roboto, "Helvetica Neue", sans-serif',
                                                                                        letterSpacing:
                                                                                            "0.3px",
                                                                                        fontWeight:
                                                                                            "500",
                                                                                        fontSize:
                                                                                            "1rem",
                                                                                        paddingTop:
                                                                                            "5px",
                                                                                    }}
                                                                                >
                                                                                    Last
                                                                                    update
                                                                                    :&nbsp;
                                                                                    {e?.created_at
                                                                                        ? moment(
                                                                                              new Date(
                                                                                                  e?.created_at
                                                                                              )
                                                                                          ).format(
                                                                                              "DD/MM/YYYY"
                                                                                          )
                                                                                        : "--"}
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                onClick={(
                                                                                    e
                                                                                ) =>
                                                                                    e.stopPropagation()
                                                                                }
                                                                            >
                                                                                {e?.mapping_process_status_id ===
                                                                                    STATUS_SUCCESS_CATALOG &&
                                                                                    !e?.outdated && (
                                                                                        <DownloadCatalog
                                                                                            dataCell={
                                                                                                e
                                                                                            }
                                                                                            setCurrentElement={
                                                                                                setCurrentElement
                                                                                            }
                                                                                        />
                                                                                    )}
                                                                                {(e?.outdated ||
                                                                                    e?.mapping_process_status_id ===
                                                                                        STATUS_FAILED) && (
                                                                                    <RunOutdatedMapping
                                                                                        setShowRunMapping={
                                                                                            setShowRunMapping
                                                                                        }
                                                                                        setCurrentElement={
                                                                                            setCurrentElement
                                                                                        }
                                                                                        currentElement={
                                                                                            e
                                                                                        }
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        </Card.Title>
                                                                        {e?.outdated ? (
                                                                            <Card.Text className="card-text-mapping-details">
                                                                                <span
                                                                                    text="white"
                                                                                    className={`badge rounded-pill text-white bg-dark
                                                                                        `}
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        fontWeight: 800,
                                                                                        fontSize:
                                                                                            "13px",
                                                                                        height: "20px",
                                                                                    }}
                                                                                >
                                                                                    {e?.hasMapping
                                                                                        ? "Outdated"
                                                                                        : "Run mapping"}
                                                                                </span>
                                                                            </Card.Text>
                                                                        ) : e?.mapping_process_status_id ===
                                                                              STATUS_PROCESSING ||
                                                                          e?.mapping_process_status_id ===
                                                                              STATUS_PENDING ? (
                                                                            <Card.Text className="card-text-mapping-details">
                                                                                <span
                                                                                    text="white"
                                                                                    className={`badge rounded-pill text-white bg-warning
                                                                                        `}
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        fontWeight: 800,
                                                                                        fontSize:
                                                                                            "13px",
                                                                                        height: "20px",
                                                                                    }}
                                                                                >
                                                                                    Processing
                                                                                </span>
                                                                            </Card.Text>
                                                                        ) : e?.mapping_process_status_id ===
                                                                          STATUS_FAILED ? (
                                                                            <Card.Text className="card-text-mapping-details">
                                                                                <span
                                                                                    text="white"
                                                                                    className={`badge rounded-pill text-white bg-danger
                                                                                `}
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        fontWeight: 800,
                                                                                        fontSize:
                                                                                            "13px",
                                                                                        height: "20px",
                                                                                    }}
                                                                                >
                                                                                    Failed
                                                                                </span>
                                                                            </Card.Text>
                                                                        ) : e?.mapping_process_status_id ===
                                                                          STATUS_IN_QUEUE ? (
                                                                            <Card.Text className="card-text-mapping-details">
                                                                                <span
                                                                                    text="white"
                                                                                    className={`badge rounded-pill text-white bg-cyan
                                                                                `}
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        fontWeight: 800,
                                                                                        fontSize:
                                                                                            "13px",
                                                                                        height: "20px",
                                                                                    }}
                                                                                >
                                                                                    In
                                                                                    queue
                                                                                </span>
                                                                            </Card.Text>
                                                                        ) : (
                                                                            <Card.Text className="card-text-mapping-details">
                                                                                <Row className="no-gutters">
                                                                                    <Col
                                                                                        md={
                                                                                            12
                                                                                        }
                                                                                        lg={
                                                                                            12
                                                                                        }
                                                                                    >
                                                                                        <div
                                                                                            className="graph-details"
                                                                                            style={{
                                                                                                height: "20px",
                                                                                            }}
                                                                                        >
                                                                                            <OverlayTrigger
                                                                                                placement="top"
                                                                                                delay={{
                                                                                                    show: 100,
                                                                                                    hide: 100,
                                                                                                }}
                                                                                                overlay={renderTooltip(
                                                                                                    `Mapped hotels : ${
                                                                                                        e?.mapped_hotels
                                                                                                            ? separator(
                                                                                                                  e?.mapped_hotels
                                                                                                              )
                                                                                                            : 0
                                                                                                    } (${
                                                                                                        e?.pourcentageMappedHotels?.toFixed(
                                                                                                            2,
                                                                                                            10
                                                                                                        ) ??
                                                                                                        0
                                                                                                    }%)`
                                                                                                )}
                                                                                            >
                                                                                                <div
                                                                                                    className="graph-item-details"
                                                                                                    style={{
                                                                                                        backgroundColor:
                                                                                                            "#15BFAE",
                                                                                                        width: `${e?.pourcentageMappedHotels}%`,
                                                                                                        height: "20px",
                                                                                                    }}
                                                                                                ></div>
                                                                                            </OverlayTrigger>
                                                                                            <OverlayTrigger
                                                                                                placement="top"
                                                                                                delay={{
                                                                                                    show: 100,
                                                                                                    hide: 100,
                                                                                                }}
                                                                                                overlay={renderTooltip(
                                                                                                    `Not Mapped hotels : ${
                                                                                                        e?.unmapped_hotels
                                                                                                            ? separator(
                                                                                                                  e?.unmapped_hotels
                                                                                                              )
                                                                                                            : 0
                                                                                                    } (${
                                                                                                        e?.pourcentageUnmappedHotels?.toFixed(
                                                                                                            2,
                                                                                                            10
                                                                                                        ) ??
                                                                                                        0
                                                                                                    }%)`
                                                                                                )}
                                                                                            >
                                                                                                <div
                                                                                                    className="graph-item-details"
                                                                                                    style={{
                                                                                                        backgroundColor:
                                                                                                            "#F25F29",
                                                                                                        width: `${
                                                                                                            e?.pourcentageUnmappedHotels ??
                                                                                                            0
                                                                                                        }%`,
                                                                                                        height: "20px",
                                                                                                    }}
                                                                                                ></div>
                                                                                            </OverlayTrigger>
                                                                                            <OverlayTrigger
                                                                                                placement="top"
                                                                                                delay={{
                                                                                                    show: 100,
                                                                                                    hide: 100,
                                                                                                }}
                                                                                                overlay={renderTooltip(
                                                                                                    `Possibles : ${
                                                                                                        e?.possibles_hotels
                                                                                                    } Hotel(s) (${
                                                                                                        e?.pourcentagePossiblesHotels?.toFixed(
                                                                                                            2,
                                                                                                            10
                                                                                                        ) ??
                                                                                                        0
                                                                                                    }%)`
                                                                                                )}
                                                                                            >
                                                                                                <div
                                                                                                    className="graph-item-details"
                                                                                                    style={{
                                                                                                        backgroundColor:
                                                                                                            "rgb(104 118 232)",
                                                                                                        width: `${
                                                                                                            e?.pourcentagePossiblesHotels ??
                                                                                                            0
                                                                                                        }%`,
                                                                                                        height: "20px",
                                                                                                    }}
                                                                                                ></div>
                                                                                            </OverlayTrigger>
                                                                                            <OverlayTrigger
                                                                                                placement="top"
                                                                                                delay={{
                                                                                                    show: 100,
                                                                                                    hide: 100,
                                                                                                }}
                                                                                                overlay={renderTooltip(
                                                                                                    `Invalid hotels : ${
                                                                                                        e?.invalid_hotels
                                                                                                            ? separator(
                                                                                                                  e?.invalid_hotels
                                                                                                              )
                                                                                                            : 0
                                                                                                    } (${
                                                                                                        e?.pourcentageInvalidHotels?.toFixed(
                                                                                                            2,
                                                                                                            10
                                                                                                        ) ??
                                                                                                        0
                                                                                                    }%)`
                                                                                                )}
                                                                                            >
                                                                                                <div
                                                                                                    className="graph-item-details"
                                                                                                    style={{
                                                                                                        backgroundColor:
                                                                                                            "#122e4024",
                                                                                                        width: `${
                                                                                                            e?.pourcentageInvalidHotels ??
                                                                                                            0
                                                                                                        }%`,
                                                                                                        height: "20px",
                                                                                                    }}
                                                                                                ></div>
                                                                                            </OverlayTrigger>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Card.Text>
                                                                        )}
                                                                    </Card.Body>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </div>
                                                );
                                            })}
                                            <div className="col-lg-12 col-md-12">
                                                {currentItems?.length > 0 ? (
                                                    <div
                                                        className="row"
                                                        style={{
                                                            width: "100%",
                                                            display: "flex",
                                                            justifyContent:
                                                                "center",
                                                        }}
                                                    >
                                                        <ReactPaginate
                                                            className="react-pagination-div"
                                                            breakClassName={
                                                                "page-item"
                                                            }
                                                            breakLinkClassName={
                                                                "page-link"
                                                            }
                                                            containerClassName={
                                                                "pagination"
                                                            }
                                                            pageClassName={
                                                                "page-item"
                                                            }
                                                            pageLinkClassName={
                                                                "page-link"
                                                            }
                                                            previousClassName={
                                                                "page-item"
                                                            }
                                                            previousLinkClassName={
                                                                "page-link"
                                                            }
                                                            nextClassName={
                                                                "page-item"
                                                            }
                                                            nextLinkClassName={
                                                                "page-link"
                                                            }
                                                            activeClassName={
                                                                "active"
                                                            }
                                                            breakLabel="..."
                                                            nextLabel=" >"
                                                            onPageChange={
                                                                handlePageClick
                                                            }
                                                            pageRangeDisplayed={
                                                                5
                                                            }
                                                            pageCount={
                                                                pageCount
                                                            }
                                                            previousLabel="<"
                                                            renderOnZeroPageCount={
                                                                null
                                                            }
                                                        />
                                                    </div>
                                                ) : currentItems?.length ===
                                                      0 && filter !== "" ? (
                                                    <div
                                                        className="card"
                                                        style={{
                                                            padding: "2rem",
                                                            width: "100%",
                                                        }}
                                                    >
                                                        <div className="empty text-center">
                                                            <p>
                                                                Sorry, we
                                                                couldn&apos;t
                                                                find any partner
                                                                for your search
                                                                criteria. Please
                                                                check your
                                                                information and
                                                                try again.
                                                            </p>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div
                                                        className="card"
                                                        style={{
                                                            padding: "2rem",
                                                            width: "100%",
                                                        }}
                                                    >
                                                        <div className="empty text-center">
                                                            <p>
                                                                No data to
                                                                display.
                                                            </p>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {showRunMapping && (
                <RunMappingModal
                    open={showRunMapping}
                    handleClose={() => setShowRunMapping(false)}
                    currentElement={currentElement}
                />
            )}
        </>
    );
}
export default PartnersDetails;
const RunOutdatedMapping = ({
    setShowRunMapping,
    setCurrentElement,
    currentElement,
}) => {
    const [loading, setLoading] = useState(false);
    return (
        <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip("Run mapping")}
        >
            <button
                type="button"
                className="btn btn-sm waves-effect waves-light btn-rounded btn-outline-secondary"
                onClick={async (e) => {
                    e.stopPropagation();
                    setCurrentElement(currentElement);
                    setShowRunMapping(true);
                }}
                disabled={loading}
            >
                {loading ? (
                    <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                ) : (
                    <i className="fas fa-play"></i>
                )}
            </button>
        </OverlayTrigger>
    );
};
