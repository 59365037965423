import React from "react";
import DropzonePreview from "../../../components/DropzonePreview";
import { Modal } from "react-bootstrap";

function Step2(props) {
    const { files, setFiles, setData, handleClose, next, setFirstLine } = props;
    return (
        <>
            <Modal.Body>
                {files?.length == 0 && (
                    <h3 className="card-title">Upload inventory</h3>
                )}
                <span
                    className="sub-title-info "
                    style={{
                        display: "flex",
                        textAlign: "center",
                        marginBottom: "10px",
                    }}
                >
                    Upload a CSV file containing your properties. Make sure your
                    file contains the following columns :
                </span>
                <div
                    style={{
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        marginBottom: "30px",
                        textAlign: "center",
                    }}
                >
                    <div className="requiredFileProperties">
                        <div className="statsRow">
                            <div className="col-1 d-flex flex-column">
                                <span className="title">
                                    Hotel ID
                                    <span className="text-danger"> * </span>
                                </span>
                            </div>
                            <div className="col-1 d-flex flex-column bordered">
                                <span className="title">
                                    Hotel Name
                                    <span className="text-danger"> * </span>
                                </span>
                            </div>
                            <div className="col-2 d-flex flex-column bordered">
                                <span className="title">
                                    Hotel Address
                                    <span className="text-danger"> * </span>
                                </span>
                            </div>
                            <div className="col-4 d-flex flex-column bordered">
                                <span className="title">
                                    Country ISO Code
                                    <span className="text-danger"> * </span>
                                </span>
                            </div>
                            <div className="col-2 d-flex flex-column bordered">
                                <span className="title">
                                    Latitude (Recommended)
                                </span>
                            </div>
                            <div className="col-2 d-flex flex-column bordered">
                                <span className="title">
                                    Longitude (Recommended)
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="form-group">
                            <DropzonePreview
                                files={files}
                                setFiles={setFiles}
                                setData={setData}
                                isCatalog={true}
                                setFirstLine={setFirstLine}
                            />
                        </div>
                        <div style={{ textAlign: "left" }}>
                            <span style={{ color: "#ff4f70" }}>
                                * Required fields
                            </span>
                        </div>
                    </div>
                </div>
                <div className="home">
                    <button
                        className="btn text-link-button"
                        onClick={() => {
                            // Create new tag for download file
                            const anchor = document.createElement("a");
                            //anchor.download = name;
                            anchor.href =
                                "https://public-cupid.s3.us-east-2.amazonaws.com/samples/csv_template.csv";
                            anchor.dataset.downloadurl = [
                                "text/csv",
                                anchor.download,
                                anchor.href,
                            ].join(":");
                            anchor.click();

                            // Remove URL.createObjectURL. The browser should not save the reference to the file.
                            setTimeout(() => {
                                // For Firefox it is necessary to delay revoking the ObjectURL
                                URL.revokeObjectURL(
                                    "https://public-cupid.s3.us-east-2.amazonaws.com/samples/csv_template.csv"
                                );
                            }, 100);
                        }}
                    >
                        Download CSV template
                    </button>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="btn btn-warning"
                    onClick={handleClose}
                >
                    Close
                </button>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={next}
                >
                    Next &nbsp;&nbsp;
                    <i className="fas fa-caret-right"></i>
                </button>
            </Modal.Footer>
        </>
    );
}

export default Step2;
