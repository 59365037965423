import {
    GET_DASHBOARD_DATA_FAIL,
    GET_DASHBOARD_DATA_SUCCESS,
    GET_DASHBOARD_DATA_LISTING,
    GET_USER_CONNECTED_FAIL,
    GET_USER_CONNECTED_SUCCESS,
    GET_USER_CONNECTED_LISTING,
} from "../types";
import axios from "axios";

const PATH_URL = process.env.REACT_APP_BASE_URL;

export const getDashboardData = () => async (dispatch) => {
    dispatch({
        type: GET_DASHBOARD_DATA_LISTING,
    });
    try {
        const res = await axios.get(PATH_URL + "/catalogs/stats", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        dispatch({
            type: GET_DASHBOARD_DATA_SUCCESS,
            payload: res.data,
        });
    } catch (e) {
        dispatch({
            type: GET_DASHBOARD_DATA_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};

export const getUserConnected = () => async (dispatch) => {
    dispatch({
        type: GET_USER_CONNECTED_LISTING,
    });
    try {
        const res = await axios.get(PATH_URL + "/me", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        localStorage.setItem("userConnected", res.data.name);
        localStorage.setItem("id", res.data.id);
        localStorage.setItem("name", res.data.name);
        dispatch({
            type: GET_USER_CONNECTED_SUCCESS,
            payload: res.data,
        });
    } catch (e) {
        dispatch({
            type: GET_USER_CONNECTED_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};
export const getAffiliateCompanies = () => async (dispatch) => {
    dispatch({
        type: GET_USER_CONNECTED_LISTING,
    });
    try {
        const res = await axios.get(PATH_URL + "/mappings", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        localStorage.setItem("userConnected", res.data.name);
        localStorage.setItem("id", res.data.id);
        localStorage.setItem("name", res.data.name);
        dispatch({
            type: GET_USER_CONNECTED_SUCCESS,
            payload: res.data,
        });
    } catch (e) {
        dispatch({
            type: GET_USER_CONNECTED_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};
