import React from "react";
import Lottie from "react-lottie";
import processingAnimationData from "../../lotties/validating.json";
import { STATUS_PROCESSING } from "../../utilis/const";
import { ProgressBar } from "react-bootstrap";

const processingOptions = {
    loop: true,
    autoplay: true,
    animationData: processingAnimationData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};
const ProccessingCatalog = (props) => {
    const { statusBadgeVariant, activeCatalog, progressPourcentage, time } =
        props;
    return (
        <>
            <div className="col-6">
                <span
                    text="white"
                    className={`badge rounded-pill text-white bg-${
                        statusBadgeVariant(activeCatalog?.status)?.color
                    }`}
                    style={{
                        fontWeight: 800,
                        fontSize: "13px",
                    }}
                >
                    {statusBadgeVariant(activeCatalog?.status)?.status}
                </span>
            </div>
            {/* <div
                className="col-6"
                style={{
                    textAlign: "right",
                    paddingTop: "5px",
                }}
            >
                {activeCatalog && activeCatalog?.total_hotels !== 0 && (
                    <>
                        {activeCatalog?.status === STATUS_PROCESSING && (
                            <>
                                <ProgressBar
                                    now={progressPourcentage}
                                    label={`${progressPourcentage?.toFixed(
                                        0
                                    )}%`}
                                />
                                <span
                                    style={{
                                        fontWeight: 600,
                                        color: "#8C9BEF",
                                    }}
                                >
                                    Estimated time ~ {time ?? 0} min
                                </span>
                            </>
                        )}
                    </>
                )}
            </div> */}

            <div className="col-12 align-items-center">
                <Lottie options={processingOptions} height={150} width={180} />
            </div>
        </>
    );
};
export default ProccessingCatalog;
