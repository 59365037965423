import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import LoginService from "../../services/LoginService";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { getUserConnected } from "../../redux/actions/DashboardActions";
import "./style.css";
import { Helmet } from "react-helmet";
import { useCookies } from "react-cookie";
import { isMobile } from "react-device-detect";
import ResetPasswordForm from "./ResetPasswordForm";
import { STYLE_INPUT } from "../../utilis/const";

function SignIn(props) {
    const [loading, setLoading] = useState(false);
    const [showMobileContent, setShowMobileContent] = useState(false);
    const [showResetContent, setShowResetContent] = useState(false);
    const [showPassword, setShowPassword] = useState("password");
    const history = useHistory();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });
    const [cookies, setCookie, removeCookie] = useCookies();

    const dispatch = useDispatch();

    React.useEffect(() => {
        removeCookie("intercom-session-nhu68eka");
    }, []);

    const handleLogin = async (data) => {
        setLoading(true);
        try {
            var res = await LoginService.signIn(data);
            if (res?.status === 200) {
                localStorage.setItem("isAuthenticated", "true");
                await dispatch(getUserConnected());
                if (isMobile) {
                    setShowMobileContent(true);
                } else {
                    setShowMobileContent(false);
                    history.push("/catalogs-manager");
                }

                setLoading(false);
            } else {
                setLoading(false);
                toast.error(res?.message);
            }
        } catch (err) {
            setLoading(false);
            toast.error("Server error");
        }
    };

    const renderContent = () => {
        if (showMobileContent) {
            return (
                <div
                    data-aos-easing="ease-out-quad"
                    data-aos-duration="700"
                    data-aos-delay="0"
                >
                    <section className="bg-gray-200">
                        <div className="container d-flex flex-column">
                            <div className="row align-items-center justify-content-center gx-0 min-vh-100">
                                <div className="col-12-sign-in col-md-5 col-lg-5 py-8 py-md-11">
                                    <h1 className="mb-0 fw-bold text-center">
                                        This content is available only on
                                        browser{" "}
                                    </h1>

                                    <p className="mb-6 text-center text-muted"></p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            );
        } else {
            return (
                <>
                    <Helmet>
                        <title> Sign in</title>
                    </Helmet>
                    <div
                        data-aos-easing="ease-out-quad"
                        data-aos-duration="700"
                        data-aos-delay="0"
                    >
                        <section
                            className="bg-gray-200"
                            style={{ backgroundColor: "white" }}
                        >
                            <div className="container d-flex flex-column">
                                <div className="row align-items-center justify-content-center gx-0 min-vh-100">
                                    <div className="col-12-sign-in col-md-5 col-lg-5 py-8 py-md-11">
                                        {showResetContent ? (
                                            <ResetPasswordForm
                                                setShowResetContent={
                                                    setShowResetContent
                                                }
                                            />
                                        ) : (
                                            <>
                                                <div
                                                    class="text-center"
                                                    style={{
                                                        marginBottom: "60px",
                                                    }}
                                                >
                                                    <img
                                                        src="../assets/images/cupid_affiliate_logo.png"
                                                        alt="wrapkit"
                                                        height="150px"
                                                        width="300px"
                                                    />
                                                </div>
                                                <h1 className="mb-0 fw-bold text-center">
                                                    Sign in
                                                </h1>

                                                <form
                                                    className="mb-6"
                                                    onSubmit={handleSubmit(
                                                        handleLogin
                                                    )}
                                                >
                                                    <div className="form-group">
                                                        <label
                                                            className="form-label"
                                                            htmlFor="email"
                                                        >
                                                            Email
                                                        </label>
                                                        <input
                                                            type="email"
                                                            className="form-control2"
                                                            name="login"
                                                            id="email"
                                                            placeholder="name@address.com"
                                                            autoComplete="off"
                                                            {...register(
                                                                "login"
                                                            )}
                                                        />
                                                        {errors?.login && (
                                                            <small
                                                                id="emailHelp"
                                                                className="text-danger form-text"
                                                            >
                                                                {
                                                                    errors
                                                                        ?.login
                                                                        ?.message
                                                                }
                                                            </small>
                                                        )}
                                                    </div>

                                                    <div className="form-group mb-5">
                                                        <label
                                                            className="form-label"
                                                            htmlFor="password"
                                                        >
                                                            Password
                                                        </label>
                                                        <div className="input-group">
                                                            <input
                                                                className="form-control"
                                                                type={
                                                                    showPassword
                                                                }
                                                                name="password"
                                                                id="password"
                                                                placeholder="Enter your password"
                                                                autoComplete="off"
                                                                {...register(
                                                                    "password"
                                                                )}
                                                                style={{
                                                                    ...STYLE_INPUT,
                                                                }}
                                                            />
                                                            <span
                                                                class="input-group-text"
                                                                style={{
                                                                    border: "none",
                                                                }}
                                                            >
                                                                {showPassword ===
                                                                "password" ? (
                                                                    <i
                                                                        className="fa fa-eye"
                                                                        id="togglePassword"
                                                                        style={{
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() =>
                                                                            setShowPassword(
                                                                                "text"
                                                                            )
                                                                        }
                                                                    ></i>
                                                                ) : (
                                                                    <i
                                                                        className="fas fa-eye-slash"
                                                                        id="togglehidePassword"
                                                                        style={{
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() =>
                                                                            setShowPassword(
                                                                                "password"
                                                                            )
                                                                        }
                                                                    ></i>
                                                                )}
                                                            </span>
                                                        </div>
                                                        {errors.password && (
                                                            <small
                                                                id="emailHelp"
                                                                className="text-danger form-text"
                                                            >
                                                                {
                                                                    errors
                                                                        .password
                                                                        .message
                                                                }
                                                            </small>
                                                        )}
                                                    </div>

                                                    <button
                                                        className="btn2 w-100 btn-primary2"
                                                        type="submit"
                                                        disabled={loading}
                                                    >
                                                        Sign in
                                                        {loading && (
                                                            <Spinner
                                                                as="span"
                                                                animation="grow"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                        )}
                                                    </button>
                                                </form>
                                                <p className="mb-0 fs-sm text-center text-muted">
                                                    <Link
                                                        onClick={() => {
                                                            setShowResetContent(
                                                                true
                                                            );
                                                        }}
                                                    >
                                                        Don't remember your
                                                        password?
                                                    </Link>
                                                </p>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </>
            );
        }
    };

    return <>{renderContent()}</>;
}
export default SignIn;

const schema = yup.object().shape({
    login: yup
        .string()
        .email("Must be a valid email")
        .required("Email is required"),
    password: yup.string().required("Password is required"),
});
