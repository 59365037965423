import React from "react";
import CatalogTableDetails from "./CatalogTableDetails";
import { Helmet } from "react-helmet";

function CatalogsManagerScreen(props) {
    return (
        <>
            <Helmet>
                <title> Inventory</title>
            </Helmet>
            <div className="page-wrapper" style={{ display: "block" }}>
                <div className="page-breadcrumb">
                    <div className="row">
                        <div className="col-7 align-self-center">
                            <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                                Inventory manager
                            </h3>
                        </div>
                        <div className="col-12">
                            <p className="card-text">
                                <span className="sub-title-info ">
                                    <i className="fa  fa-info-circle"></i>
                                    &nbsp; This is your properties inventory. It
                                    will be used as a reference when mapping.
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <CatalogTableDetails />
                </div>
            </div>
        </>
    );
}
export default CatalogsManagerScreen;
