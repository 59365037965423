import {
    GET_CATALOGS_SUCCESS,
    GET_CATALOGS_FAIL,
    GET_CATALOGS_LISTING,
    GET_PROGRESS_CATALOG_SUCCESS,
    GET_PROGRESS_CATALOG_FAIL,
    GET_PROGRESS_CATALOG_LISTING,
    GET_CATALOG_SUCCESS,
    GET_CATALOG_FAIL,
    GET_CATALOG_LISTING,
    SHOW_WELCOME_DIALOG,
} from "../types";
import axios from "axios";

const PATH_URL = process.env.REACT_APP_BASE_URL;

export const getCatalogs = () => async (dispatch) => {
    dispatch({
        type: GET_CATALOGS_LISTING,
    });
    try {
        const res = await axios.get(PATH_URL + "/inventories", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        dispatch({
            type: GET_CATALOGS_SUCCESS,
            payload: res.data.data,
        });
        if (res.data.data?.length === 0) {
            dispatch({ type: SHOW_WELCOME_DIALOG });
        }
    } catch (e) {
        dispatch({
            type: GET_CATALOGS_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};
export const getCatalogsPeriodique = () => async (dispatch) => {
    try {
        const res = await axios.get(PATH_URL + "/inventories", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        dispatch({
            type: GET_CATALOGS_SUCCESS,
            payload: res.data.data,
        });
        if (res.data.data?.length === 0) {
            dispatch({ type: SHOW_WELCOME_DIALOG });
        }
    } catch (e) {
        dispatch({
            type: GET_CATALOGS_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};

export const getProgressCatalog = () => async (dispatch) => {
    dispatch({
        type: GET_PROGRESS_CATALOG_LISTING,
    });
    try {
        const res = await axios.get(PATH_URL + "/catalogs/keys", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        dispatch({
            type: GET_PROGRESS_CATALOG_SUCCESS,
            payload: res.data?.count,
        });
    } catch (e) {
        dispatch({
            type: GET_PROGRESS_CATALOG_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};

export const getCatalog = (id) => async (dispatch) => {
    dispatch({
        type: GET_CATALOG_LISTING,
    });
    try {
        const res = await axios.get(PATH_URL + `/catalogs/${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        dispatch({
            type: GET_CATALOG_SUCCESS,
            payload: res.data.data,
        });
    } catch (e) {
        dispatch({
            type: GET_CATALOG_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};
