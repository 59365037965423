import React, { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { useDispatch, useSelector } from "react-redux";
import { getAllInvitations } from "../redux/actions/LoginAction";
import moment from "moment";
import LoginService from "../services/LoginService";
import { NavDropdown, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";

const InvitationsBar = () => {
    const dispatch = useDispatch();
    const [showAllInvitations, setShowAllInvitations] = useState(false);
    const { invitations, totalListinvitations } = useSelector((state) => ({
        invitations: state.loginReducer.invitations?.slice(0, 3) ?? [],
        totalListinvitations: state.loginReducer.invitations ?? [],
    }));
    useEffect(() => {
        dispatch(getAllInvitations());
    }, []);
    return (
        <>
            <ul className="header navbar-nav float-left mr-auto ml-3 pl-1">
                <NavDropdown
                    title={
                        <div style={{ padding: "0px" }}>
                            <span>
                                <i
                                    className="far fa-bell"
                                    style={{
                                        fontSize: "25px",
                                        color: "#333",
                                    }}
                                ></i>
                            </span>
                            <span
                                className="badge badge-primary notify-no rounded-circle"
                                style={{ right: "1px" }}
                            >
                                {totalListinvitations?.length ?? 0}
                            </span>
                        </div>
                    }
                    id="basic-nav-dropdown"
                    //className="dropdown-menu dropdown-menu-left mailbox animated bounceInDown"
                >
                    <ul className="list-style-none">
                        <li>
                            <div
                                className="message-center notifications position-relative"
                                style={{
                                    width: "500px",
                                    marginTop: "10px",
                                    borderRadius: "15px",
                                    overflow: "auto",
                                    maxHeight: "600px",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        padding: "16px 16px",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <h6
                                        style={{
                                            margin: "0px",
                                            fontWeight: "600",
                                            fontSize: "1rem",
                                            lineHeight: "1.2rem",
                                            color: "#222222",
                                            marginLeft: "10px",
                                        }}
                                    >
                                        Invitations
                                    </h6>
                                    <span
                                        text="white"
                                        className={`badge rounded-pill text-white bg-info`}
                                        style={{ fontWeight: "bold" }}
                                    >
                                        {totalListinvitations?.length} new
                                    </span>
                                </div>
                                {!showAllInvitations &&
                                    invitations?.map((e, index) => {
                                        return (
                                            <a
                                                className="message-item d-flex align-items-center border-bottom px-3 py-2"
                                                key={index}
                                            >
                                                <Avatar
                                                    name={e?.company_name}
                                                    round={true}
                                                    size={45}
                                                    color="#222"
                                                    style={{
                                                        marginLeft: "10px",
                                                        marginTop: "10px",
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "space-between",
                                                        width: "100%",
                                                    }}
                                                >
                                                    <div className="w-75 d-inline-block v-middle pl-2">
                                                        <h6
                                                            className="message-title mb-0 mt-1"
                                                            style={{
                                                                color: "#212529",
                                                            }}
                                                        >
                                                            {e?.company_name}
                                                        </h6>

                                                        <span className="font-12 text-nowrap d-block text-muted">
                                                            {`${moment(
                                                                e?.date,
                                                                "YYYYMMDD HH:mm:ss Z"
                                                            ).fromNow()}`}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <DeclineInvitation
                                                            currentInvitation={
                                                                e
                                                            }
                                                        />
                                                        &nbsp;
                                                        <AcceptInvitation
                                                            currentInvitation={
                                                                e
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </a>
                                        );
                                    })}
                                {showAllInvitations &&
                                    totalListinvitations?.map((e, index) => {
                                        return (
                                            <a
                                                className="message-item d-flex align-items-center border-bottom px-3 py-2"
                                                key={index}
                                            >
                                                <Avatar
                                                    name={e?.company_name}
                                                    round={true}
                                                    size={45}
                                                    color="#222"
                                                    style={{
                                                        marginLeft: "10px",
                                                        marginTop: "10px",
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "space-between",
                                                        width: "100%",
                                                    }}
                                                >
                                                    <div className="w-75 d-inline-block v-middle pl-2">
                                                        <h6
                                                            className="message-title mb-0 mt-1"
                                                            style={{
                                                                color: "#212529",
                                                            }}
                                                        >
                                                            {e?.company_name}
                                                        </h6>

                                                        <span className="font-12 text-nowrap d-block text-muted">
                                                            {`${moment(
                                                                e?.date,
                                                                "YYYYMMDD HH:mm:ss Z"
                                                            ).fromNow()}`}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <DeclineInvitation
                                                            currentInvitation={
                                                                e
                                                            }
                                                        />
                                                        &nbsp;
                                                        <AcceptInvitation
                                                            currentInvitation={
                                                                e
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </a>
                                        );
                                    })}
                            </div>
                        </li>
                        <li
                            style={{
                                justifyContent: "center",
                                display: "flex",
                            }}
                        >
                            {invitations?.length > 0 ? (
                                <>
                                    {totalListinvitations?.length > 3 ? (
                                        <div
                                            style={{
                                                width: "100%",
                                                padding: "16px",
                                            }}
                                        >
                                            <button
                                                className="btn btn-rounded btn-outline-primary "
                                                style={{
                                                    marginTop: "10px",
                                                    borderRadius: "13px",
                                                    width: "100%",
                                                    padding: "10px",
                                                }}
                                                onClick={() => {
                                                    // if (!showAllInvitations) {
                                                    //     setInvitationsList(
                                                    //         totalListinvitations
                                                    //     );
                                                    // } else {
                                                    //     setInvitationsList(
                                                    //         invitations
                                                    //     );
                                                    // }
                                                    setShowAllInvitations(
                                                        !showAllInvitations
                                                    );
                                                }}
                                            >
                                                <strong>
                                                    {showAllInvitations
                                                        ? "Hide invitations"
                                                        : "Check all invitations"}
                                                </strong>
                                            </button>
                                        </div>
                                    ) : (
                                        <button className="btn nav-link pt-3 text-center text-dark">
                                            <strong>No more invitations</strong>
                                        </button>
                                    )}
                                </>
                            ) : (
                                <button className="btn nav-link pt-3 text-center text-dark">
                                    <strong>No invitations found</strong>
                                </button>
                            )}
                        </li>
                    </ul>
                </NavDropdown>
            </ul>
        </>
    );
};

const AcceptInvitation = ({ currentInvitation }) => {
    const dispatch = useDispatch();
    const [acceptPending, setAcceptPending] = useState(false);

    return (
        <button
            type="button"
            className="btn btn-rounded btn-outline-success "
            style={{
                borderRadius: "50%",
            }}
            disabled={acceptPending}
            onClick={async () => {
                setAcceptPending(true);
                try {
                    const res = await LoginService.acceptInvitation(
                        currentInvitation?.id
                    );
                    if (res.status === 200) {
                        toast.success(res?.data?.msg);
                        dispatch(getAllInvitations());
                        setAcceptPending(false);
                    } else {
                        toast.error(
                            "An error occurred while accepting invitation"
                        );
                        setAcceptPending(false);
                    }
                } catch (error) {
                    toast.error("An error occurred while accepting invitation");
                    setAcceptPending(false);
                }
            }}
        >
            {acceptPending ? (
                <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
            ) : (
                <i className="fas fa-check"></i>
            )}
        </button>
    );
};

const DeclineInvitation = ({ currentInvitation }) => {
    const [declinePending, setDeclinePending] = useState(false);
    const dispatch = useDispatch();
    return (
        <button
            type="button"
            className="btn btn-rounded btn-outline-danger "
            style={{
                borderRadius: "50%",
            }}
            disabled={declinePending}
            onClick={async () => {
                setDeclinePending(true);
                try {
                    const res = await LoginService.declineInvitation(
                        currentInvitation?.id
                    );
                    if (res.status === 200) {
                        toast.success(res?.data?.msg);
                        dispatch(getAllInvitations());
                        setDeclinePending(false);
                    } else {
                        toast.error(
                            "An error occurred while accepting invitation"
                        );
                        setDeclinePending(false);
                    }
                } catch (error) {
                    toast.error("An error occurred while accepting invitation");
                    setDeclinePending(false);
                }
            }}
        >
            {declinePending ? (
                <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
            ) : (
                <i className="fas fa-times"></i>
            )}
        </button>
    );
};

export default InvitationsBar;
