import axios from "axios";
const url = process.env.REACT_APP_BASE_URL;

const CatalogService = {
    dowloadCatalog: async (id) => {
        const result = await axios
            .get(url + `/catalogs/download/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                responseType: "blob",
            })
            .then((res) => {
                const re = new RegExp('filename=\\"([a-zA-Z0-9.\\-_]+)');
                return {
                    data: res.data,
                    fileName:
                        res.headers?.["content-disposition"].match(re)?.[1],
                    status: 200,
                };
            })
            .catch((error) => {
                let errorString = JSON.parse(error.response.data.text());
                console.log(errorString);
                return {
                    message: error.response.data.message,
                    status: error.response.data.code,
                };
            });
        return result;
    },

    uploadCatalog: async (data, opt) => {
        const result = await axios
            .post(url + "/inventories", data, opt)
            .then((res) => {
                return {
                    data: res?.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error?.response?.data?.msg,
                    status: error?.response?.data?.code,
                };
            });
        return result;
    },
};

export default CatalogService;
