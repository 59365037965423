import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import routes from "../routes";
import Header from "../components/Header";
import Leftside from "../components/Leftside";
import SignIn from "../pages/Login/SignIn";
import ConfirmPassword from "../pages/Login/ConfirmPassword";
import SignUp from "../pages/Invitation/SignUp";
import TeamSignUp from "../pages/Login/TeamSign-up";
import AcceptInvitation from "../pages/Invitation/AcceptInvitation";

export default function DashboardRouter(props) {
    const isAuthenticated = localStorage.getItem("isAuthenticated");

    return (
        <>
            <Route path="/sign-in" render={() => <SignIn {...props} />} />
            <Route path="/sign-up" render={() => <SignUp {...props} />} />
            <Route
                path="/accept-invitation"
                render={() => <AcceptInvitation {...props} />}
            />
            <Route
                path="/team-signup"
                render={() => <TeamSignUp {...props} />}
            />
            <Route
                path="/resetPassword/cofirm/:token"
                render={() => <ConfirmPassword {...props} />}
            />

            <Route exact path="/">
                {isAuthenticated ? (
                    <Redirect to="/catalogs-manager" />
                ) : (
                    <Redirect to="/sign-in" />
                )}
            </Route>

            <Switch>
                {routes.map((route, key) => {
                    return (
                        <Route
                            path={route.path}
                            render={() =>
                                isAuthenticated ? (
                                    <>
                                        <Header />
                                        <Leftside {...props} />
                                        <route.component {...props} />
                                    </>
                                ) : (
                                    <Redirect to="/sign-in" />
                                )
                            }
                            key={key}
                        ></Route>
                    );
                })}
            </Switch>
        </>
    );
}
