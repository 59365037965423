import React from "react";
import welcome from "../../lotties/welcome2.gif";

function WelcomeDialog(props) {
    const { setOpenDialog } = props;

    return (
        <div style={{ textAlign: "center" }}>
            <img
                src={welcome}
                alt="Welcome"
                style={{ height: "400px", width: "400px" }}
            />
            <h2 className="modalTitle">
                Welcome to Cup
                <span style={{ color: "#5f76e8" }}>id</span>!
            </h2>

            <p className="modalP">We’re glad you’re here.</p>
            <p className="modalP">Ready to jump in ?</p>
            <button
                className="modalBtn btn waves-effect waves-light btn-rounded btn-primary"
                onClick={() => {
                    setOpenDialog(true);
                }}
            >
                Upload inventory
            </button>
        </div>
    );
}

export default WelcomeDialog;
