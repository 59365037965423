export const CONNECTOR_FREQUENCY_DAILY = 1;
export const CONNECTOR_FREQUENCY_WEEKLY = 2;
export const CONNECTOR_FREQUENCY_HALF_MONTHLY = 3;
export const CONNECTOR_FREQUENCY_MONTHLY = 4;
export const CONNECTOR_FREQUENCY_NEVER = 0;

//
export const DEFAULT_CHOISE = 0;
export const CSV_CHOISE = 1;
export const CONNECTOR_CHOISE = 2;

export const TRAVELGATE_ID = 2;
export const PUBLIC_CONNECTOR = 0;
export const DIRECT_CONNECTOR = 1;
export const HUB_CONNECTOR = 2;

export const FILE_FORMAT = 1;
export const DATABASE = 2;
export const WEBHOOK = 3;

export const ROLE_ADMIN = "admin";
export const ROLE_OWNER = "owner";
export const ROLE_DEV = "dev";
export const ROLE_FINANCE = "finance";

export const UPDATE_EXISTING_CATALOG = "update";
export const ADD_TO_CATALOG = "add";

export const DAILY = "daily";
export const WEEKLY = "weekly";
export const MONTHLY = "monthly";

export const STATUS_SUCCESS_INVENTORY = 2;
export const STATUS_PROCESSING = 1;
export const STATUS_PENDING = 0;
export const STATUS_SUCCESS_CATALOG = 2;
export const STATUS_FAILED = 3;
export const STATUS_IN_QUEUE = 4;
export const STATUS_FINISH_PROCESSING = 2;

export const GIATA_MODEL = "giata";
export const CUPID_MODEL = "cupid";

export const STYLE_INPUT = {
    padding: "0.8125rem 1.25rem",
    appearance: "none",
    backgroundClip: "padding-box",
    backgroundColor: "#fff",
    border: "1px solid #f1f4f8",
    borderRadius: "0.375rem 0 0 0.375rem",
    boxShadow: "0 0",
    color: "#161c2d",
    lineHeight: "1.6",
    height: "55px",
    display: "block",
    fontSize: "1.0625rem",
    fontWeight: "400",
};
