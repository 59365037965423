/* eslint-disable import/no-anonymous-default-export */
import {
    GET_CUPID_MODELS_LISTING,
    GET_CUPID_MODELS_SUCCESS,
    GET_CUPID_MODELS_FAIL,
} from "../types";

const initialState = {
    cupidModelsList: [],
    loadingCupidModelsList: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CUPID_MODELS_LISTING:
            return {
                ...state,
                loadingCupidModelsList: true,
            };
        case GET_CUPID_MODELS_SUCCESS:
            return {
                ...state,
                cupidModelsList: action.payload,
                loadingCupidModelsList: false,
            };
        case GET_CUPID_MODELS_FAIL:
            return {
                ...state,
                loadingCupidModelsList: false,
            };

        default:
            return state;
    }
}
