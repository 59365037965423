import React, { useEffect, useState } from "react";
import AddCatalogDialog from "./AddCatalogDialog/index";
import moment from "moment";
import Table from "../../components/Table/Table";
import DownloadCatalog from "./DownloadCatalog";
import { useDispatch, useSelector } from "react-redux";
import {
    getCatalogs,
    getCatalogsPeriodique,
} from "../../redux/actions/CatalogActions";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import WelcomeDialog from "./WelcomeDialog";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { separator } from "../../utilis/functions";
import { getPartnerCatalogsByStatus } from "../../redux/actions/PartnerCataogActions";
import { STATUS_PROCESSING, STATUS_PENDING } from "../../utilis/const";
import Launching from "../../lotties/launching.gif";
import FinishProcessingDialog from "./FinishProccessingDialog";
import PendingCatalog from "./PendingCatalog";
import ProccessingCatalog from "./ProccessingCatalog";

const statusBadgeVariant = (status) => {
    switch (status) {
        case 1:
            return {
                color: "info",
                status: "Validating your content",
            };
        default:
            return {
                color: "",
                status: "",
            };
    }
};

function CatalogTableDetails(props) {
    const [openDialog, setOpenDialog] = useState(false);
    const [showOldCatalog, setShowOldCatalog] = useState(false);
    const [showFinishProccessing, setShowFinishProccessing] = useState(false);
    const dispatch = useDispatch();
    const { catalogs, loading, activeCatalog, oldCatalogs, partnerCatalog } =
        useSelector((state) => ({
            catalogs: state.catalogReducer?.catalogs ?? [],
            loading: state.catalogReducer?.loading,
            activeCatalog: state.catalogReducer?.catalogs?.find(
                (e) => e?.active === true
            ),
            oldCatalogs: state.catalogReducer?.catalogs?.filter(
                (e) => e?.active === false
            ),
            partnerCatalog: state.partnerCatalogReducer?.partnerCatalogs?.find(
                (e) =>
                    e?.status === STATUS_PROCESSING ||
                    e?.status === STATUS_PENDING
            ),
        }));
    useEffect(() => {
        dispatch(getCatalogs());
        dispatch(
            getPartnerCatalogsByStatus(undefined, [
                STATUS_PENDING,
                STATUS_PROCESSING,
            ])
        );
    }, []);
    const columns = [
        {
            name: "Total hotels",
            cell: (e) => {
                return <>{e?.total_hotels}</>;
            },
            sortable: true,
        },
        {
            name: "Valid",
            cell: (e) => {
                return <>{e?.valid_hotels}</>;
            },
            sortable: true,
        },
        {
            name: "Invalid",
            cell: (e) => {
                return <>{e?.invalid_hotels}</>;
            },
            sortable: true,
        },
        {
            name: "Status",
            sortable: true,
            cell: (e) => {
                return (
                    <>
                        {e?.active ? (
                            <div>
                                <span className="badge badge-pill badge-success">
                                    <b>●</b> Active
                                </span>
                            </div>
                        ) : (
                            <div>
                                <span className="badge badge-pill badge-light">
                                    <b>●</b> Inactive
                                </span>
                            </div>
                        )}
                    </>
                );
            },
        },
        {
            name: "Creation date",
            sortable: true,
            cell: (e) => {
                return (
                    <>
                        {moment(new Date(e?.created_at)).format(
                            "DD/MM/YYYY HH:mm:ss"
                        )}
                    </>
                );
            },
        },
        {
            name: "Download",
            cell: (e) => {
                return (
                    <>
                        {e?.status === STATUS_PROCESSING ? (
                            <span className="font-weight-medium">
                                <span
                                    text="white"
                                    className={`badge rounded-pill text-white bg-${
                                        statusBadgeVariant(e.status)?.color
                                    }`}
                                    style={{ fontWeight: 800 }}
                                >
                                    {statusBadgeVariant(e.status)?.status}
                                </span>
                            </span>
                        ) : (
                            <DownloadCatalog dataCell={e} />
                        )}
                    </>
                );
            },
        },
    ];

    const renderTooltip = (props = (text) => (
        <Tooltip id="button-tooltip" {...props}>
            {text}
        </Tooltip>
    ));

    useEffect(() => {
        setInterval(() => {
            if (
                window.location?.pathname != "/sign-in" &&
                window.location.pathname == "/catalogs-manager"
            ) {
                dispatch(getCatalogsPeriodique());
            }
        }, 10000);
    }, []);
    return (
        <>
            <div className="row">
                <div className="col-12">
                    {loading ? (
                        <div className="text-center">
                            <div className="lds-ripple">
                                <div className="lds-pos"></div>
                                <div className="lds-pos"></div>
                            </div>
                        </div>
                    ) : catalogs?.length === 0 ? (
                        <WelcomeDialog setOpenDialog={setOpenDialog} />
                    ) : (
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex align-items-center mb-4">
                                    <h4 className="card-title">
                                        Your inventory
                                    </h4>
                                </div>
                                <div className="row">
                                    {activeCatalog && (
                                        <div className="col-6 col-md-12 col-lg-6">
                                            <div className="catalogBloc">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="align-items-center">
                                                            {activeCatalog?.status ===
                                                            STATUS_PENDING ? (
                                                                <PendingCatalog
                                                                    activeCatalog={
                                                                        activeCatalog
                                                                    }
                                                                />
                                                            ) : activeCatalog?.status ===
                                                              STATUS_PROCESSING ? (
                                                                <ProccessingCatalog
                                                                    activeCatalog={
                                                                        activeCatalog
                                                                    }
                                                                    statusBadgeVariant={
                                                                        statusBadgeVariant
                                                                    }
                                                                />
                                                            ) : (
                                                                <>
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <h4>
                                                                                {
                                                                                    activeCatalog?.name
                                                                                }
                                                                            </h4>
                                                                        </div>
                                                                        <div
                                                                            className="col-6"
                                                                            style={{
                                                                                textAlign:
                                                                                    "right",
                                                                                paddingTop:
                                                                                    "5px",
                                                                            }}
                                                                        ></div>
                                                                        <div className="col-12">
                                                                            <p>
                                                                                <i className="fas fa-building"></i>
                                                                                &nbsp;
                                                                                {activeCatalog?.total_hotels
                                                                                    ? separator(
                                                                                          activeCatalog?.total_hotels
                                                                                      )
                                                                                    : 0}
                                                                                &nbsp;Properties
                                                                            </p>
                                                                            <p>
                                                                                <i className="fas fa-calendar"></i>{" "}
                                                                                {moment(
                                                                                    new Date(
                                                                                        activeCatalog?.created_at
                                                                                    )
                                                                                ).format(
                                                                                    "DD/MM/YYYY HH:mm:ss"
                                                                                )}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="statsCatalog">
                                                                        <div className="statsRow">
                                                                            <div className="col-6 d-flex flex-column">
                                                                                <OverlayTrigger
                                                                                    placement="bottom"
                                                                                    delay={{
                                                                                        show: 250,
                                                                                        hide: 400,
                                                                                    }}
                                                                                    overlay={renderTooltip(
                                                                                        "Includes the required fields"
                                                                                    )}
                                                                                >
                                                                                    <span className="title">
                                                                                        <i className="fas fa-building"></i>{" "}
                                                                                        Valid
                                                                                        Hotels
                                                                                    </span>
                                                                                </OverlayTrigger>

                                                                                <span className="number">
                                                                                    {activeCatalog?.valid_hotels
                                                                                        ? separator(
                                                                                              activeCatalog?.valid_hotels
                                                                                          )
                                                                                        : 0}
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-6 d-flex flex-column bordered">
                                                                                <OverlayTrigger
                                                                                    placement="bottom"
                                                                                    delay={{
                                                                                        show: 250,
                                                                                        hide: 400,
                                                                                    }}
                                                                                    overlay={renderTooltip(
                                                                                        "Missing one or more required fields (Hotel Name, Hotel Address and/or Country"
                                                                                    )}
                                                                                >
                                                                                    <span className="title">
                                                                                        <i className="fas fa-building"></i>{" "}
                                                                                        Invalid
                                                                                        Hotels
                                                                                    </span>
                                                                                </OverlayTrigger>
                                                                                <span className="number">
                                                                                    {activeCatalog?.invalid_hotels
                                                                                        ? separator(
                                                                                              activeCatalog?.invalid_hotels
                                                                                          )
                                                                                        : 0}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="col-6 col-md-12 col-lg-6">
                                        {partnerCatalog ? (
                                            <div
                                                className="addNewCatalogBloc"
                                                style={{
                                                    border: "2px solid #e8eaed",
                                                }}
                                            >
                                                <img
                                                    src={Launching}
                                                    height="150"
                                                    width="150"
                                                    alt="Your partner catalog is
                                                    still proccessing"
                                                />
                                                <p>
                                                    Your partner catalog is
                                                    still proccessing
                                                </p>
                                            </div>
                                        ) : (
                                            <div
                                                className="addNewCatalogBloc"
                                                onClick={() => {
                                                    setOpenDialog(true);
                                                }}
                                                style={{
                                                    border: "2px solid #e8eaed",
                                                }}
                                            >
                                                <h4>Add new inventory</h4>
                                                <p>
                                                    Upload your inventory and
                                                    start mapping
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                    {oldCatalogs?.length > 0 && oldCatalogs && (
                                        <>
                                            {showOldCatalog ? (
                                                <div className="col-12 hideOldBlocs">
                                                    <h4>
                                                        <Link
                                                            onClick={() =>
                                                                setShowOldCatalog(
                                                                    false
                                                                )
                                                            }
                                                        >
                                                            Hide old inventories
                                                        </Link>
                                                    </h4>
                                                </div>
                                            ) : (
                                                <div className="col-12 showOldBlocs">
                                                    <h4>
                                                        <Link
                                                            onClick={() =>
                                                                setShowOldCatalog(
                                                                    true
                                                                )
                                                            }
                                                        >
                                                            Show old inventories
                                                        </Link>
                                                    </h4>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                                {showOldCatalog && (
                                    <div className="table-responsive div-tooltip-catalog  ">
                                        <Table
                                            data={oldCatalogs}
                                            rowsPerPage={10}
                                            columns={columns}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {openDialog && (
                <AddCatalogDialog
                    open={openDialog}
                    handleClose={() => {
                        setOpenDialog(false);
                    }}
                    setShowFinishProccessing={setShowFinishProccessing}
                />
            )}
            {showFinishProccessing && (
                <FinishProcessingDialog
                    show={showFinishProccessing}
                    handleClose={() => {
                        setShowFinishProccessing(false);
                    }}
                />
            )}
        </>
    );
}
export default CatalogTableDetails;
