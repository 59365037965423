import React from "react";
import Lottie from "react-lottie";
import preparingListAnimationData from "../../lotties/preparinglist.json";

const preparingOptions = {
    loop: true,
    autoplay: true,
    animationData: preparingListAnimationData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

const PendingCatalog = (props) => {
    const { activeCatalog } = props;
    return (
        <>
            <div className="col-6">
                <h4>{activeCatalog?.name}</h4>
                <span
                    text="white"
                    className="badge rounded-pill text-white bg-warning"
                    style={{
                        fontWeight: 800,
                        fontSize: "13px",
                    }}
                >
                    STEP (1 of 2) : Preparing list
                </span>
            </div>
            <div className="col-12 align-items-center">
                <Lottie options={preparingOptions} height={150} width={180} />
            </div>
        </>
    );
};
export default PendingCatalog;
