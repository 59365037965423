import React, { useState } from "react";
import PartnerService from "../../services/PartnerService";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import fileDownload from "js-file-download";
import { MenuItem, ControlledMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";

function DownloadCatalog({ dataCell }) {
    const [pending, setPending] = useState(false);
    const [isOpen, setOpen] = useState();
    const ref = React.useRef(null);

    const handleDowload = (id) => async () => {
        setPending(true);
        let res = await PartnerService.dowloadPartnerCatalog(id);
        try {
            if (res.status === 200) {
                fileDownload(res.data, res?.fileName);
                setPending(false);
            } else {
                toast.error("Something went wrong while uploading this file");
                setPending(false);
            }
        } catch (err) {
            toast.error("Something went wrong while uploading this file");
        }
    };

    return (
        <>
            <div
                ref={ref}
                className="btn"
                onClick={() => setOpen(true)}
                style={{ cursor: "pointer" }}
            >
                <i className="fas fa-ellipsis-v"></i>{" "}
            </div>
            <ControlledMenu
                state={isOpen ? "open" : pending ? "open" : "closed"}
                anchorRef={ref}
                onClose={() => setOpen(false)}
            >
                <MenuItem onClick={handleDowload(dataCell?.id)}>
                    Download Zip
                    {pending && (
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    )}
                </MenuItem>
            </ControlledMenu>
        </>
    );
}
export default DownloadCatalog;
