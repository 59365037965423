import React, { useState, useEffect } from "react";
import moment from "moment";
import Table from "../../components/Table/Table";
import DownloadCatalog from "./DownloadCatalog";
import {
    getPartnerCatalogsByStatus,
    getPartnerCatalogs,
    getPartnerCatalogsFirst,
} from "../../redux/actions/PartnerCataogActions";
import { useDispatch, useSelector } from "react-redux";
import { Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import CatalogNoDataModal from "./CatalogNoDataModal";
import { useHistory } from "react-router-dom";
import SuccessUploadPartner from "./SucessUploadPartnerDialog";
import useCollapse from "react-collapsed";
import { ListGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import { getCatalogs } from "../../redux/actions/CatalogActions";
import { partnerGrouppdListByAction } from "../../redux/actions/PartnerActions";
import { STATUS_SUCCESS_INVENTORY } from "../../utilis/const";
import { separator } from "../../utilis/functions";
import Tippy from "@tippyjs/react";
import ToggleStatus from "./ToggleStatus";

const statusBadgeVariant = (status) => {
    switch (status) {
        case 0:
            return {
                color: "warning",
                status: "Loading",
            };
        case 1:
            return {
                color: "info",
                status: "Processing",
            };
        case 2:
            return {
                color: "success",
                status: "Mapped",
            };
        case 3:
            return {
                color: "danger",
                status: "Failed",
            };
        case 4:
            return {
                color: "cyan",
                status: "In queue",
            };

        default:
            return {
                color: "",
                status: "",
            };
    }
};
const STATUS_SUCCESS_CATALOG = 2;
const STATUS_PROCESSING = 1;
const STATUS_PENDING = 0;

function CatalogPartnersTableDetails(props) {
    const { partner } = props;
    const [openSuccesFirstUpload, setOpenSuccesFirstUpload] = useState(false);
    const [proccessingList, setProccessingList] = useState([]);
    const history = useHistory();
    const dispatch = useDispatch();
    const config = {
        defaultExpanded: true,
    };
    const { getCollapseProps, getToggleProps, isExpanded } =
        useCollapse(config);

    const token = localStorage.getItem("token");
    const {
        data,
        loading,
        catalogs,
        partnerGroupedCatalogs,
        loadingPartnerGroupedCatalog,
    } = useSelector((state) => ({
        data: state.partnerCatalogReducer?.partnerCatalogs ?? [],
        loading: state.partnerCatalogReducer?.loadingPartnerCatalogs,
        loadingPartnerGroupedCatalog:
            state.partnerReducer?.loadingPartnerGroupedCatalog,

        catalogs: state.catalogReducer?.catalogs ?? [],
        partnerGroupedCatalogs:
            state?.partnerReducer.partnerGroupedCatalogs?.catalogs,
    }));

    useEffect(() => {
        if (proccessingList?.length > 0 && data?.length <= 0) {
            setProccessingList([]);
            dispatch(partnerGrouppdListByAction(partner?.id));
        }
    }, [data, proccessingList]);

    useEffect(() => {
        dispatch(getCatalogs());
        dispatch(partnerGrouppdListByAction(partner?.id));
        dispatch(getPartnerCatalogsFirst(partner?.id));
    }, []);

    useEffect(() => {
        var interval = setInterval(() => {
            if (
                token &&
                window.location?.pathname != "/sign-in" &&
                window.location?.pathname != "/sign-up" &&
                window.location.pathname ==
                    `/provider-content/details/${partner.id}`
            ) {
                dispatch(
                    getPartnerCatalogsByStatus(partner?.id, [
                        STATUS_PENDING,
                        STATUS_PROCESSING,
                    ])
                );
            }
        }, 10000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (data?.length > 0) {
            dispatch(partnerGrouppdListByAction(partner?.id));

            setProccessingList(
                data
                    ?.filter(
                        (e) =>
                            e?.mapping_process_status_id ===
                                STATUS_PROCESSING ||
                            e?.mapping_process_status_id === STATUS_PENDING
                    )
                    ?.map((e) => {
                        var rate =
                            e?.mapping_process_status_id === STATUS_PROCESSING
                                ? (
                                      ((e?.total_hotels -
                                          e?.mapped_hotels -
                                          e?.unmapped_hotels) *
                                          1.6) /
                                      1000 /
                                      60
                                  ).toFixed(0, 10)
                                : 0;

                        return {
                            ...e,
                            rate: rate,
                        };
                    })
            );
            var list = proccessingList?.filter(
                (e) =>
                    e?.mapping_process_status_id === STATUS_PROCESSING ||
                    e?.mapping_process_status_id === STATUS_PENDING
            );
            list?.forEach((e) => {
                partnerGroupedCatalogs?.forEach((s) => {
                    if (
                        s?.child?.find((c) => c.id === e.id)
                            ?.mapping_process_status_id ===
                        STATUS_SUCCESS_CATALOG
                    ) {
                        toast.success(`${e.name} is mapped `);
                        dispatch(partnerGrouppdListByAction(partner?.id));
                    }
                });
            });
        }
    }, [data]);

    const conditionalRowStyles = [
        {
            when: (row) =>
                row.partner?.mapping_process_status_id === STATUS_PROCESSING ||
                row.partner?.mapping_process_status_id === STATUS_PENDING,
            style: {
                backgroundColor: "rgb(174 161 251 / 14%)",
                color: "black",
                "&:hover": {
                    cursor: "pointer",
                },
            },
        },
    ];
    const renderTooltip = (props = (text) => (
        <Tooltip id="button-tooltip" {...props}>
            {text}
        </Tooltip>
    ));
    const columns = [
        {
            name: "Catalog",
            cell: (e) => {
                return (
                    <>
                        {e?.has_mapping_error && e?.active && (
                            <OverlayTrigger
                                placement="top"
                                delay={{
                                    show: 250,
                                    hide: 400,
                                }}
                                overlay={renderTooltip(
                                    " Warning ! A mapping error was removed from this catalog's mapping. Take necessary action"
                                )}
                            >
                                <i
                                    className="fas fa-exclamation-triangle"
                                    style={{
                                        color: "rgb(254, 192, 106)",
                                        marginRight: "5px",
                                    }}
                                ></i>
                            </OverlayTrigger>
                        )}

                        {e?.name}
                    </>
                );
            },
        },
        {
            name: "",
            sortable: true,
            cell: (e) => {
                return (
                    <>
                        {e?.active ? (
                            <span
                                className="badge badge-pill badge-light"
                                style={{ backgroundColor: "#21ca7f47" }}
                            >
                                <b
                                    style={{
                                        color: "#22ca80",
                                        marginLeft: "4px",
                                    }}
                                >
                                    ●
                                    <span style={{ color: "rgba(0,0,0,0.87)" }}>
                                        Active
                                    </span>
                                </b>
                            </span>
                        ) : (
                            <span className="badge badge-pill badge-light">
                                <b
                                    style={{
                                        color: "rgb(125, 126, 127)",
                                        marginLeft: "4px",
                                    }}
                                >
                                    ●
                                    <span style={{ color: "rgba(0,0,0,0.87)" }}>
                                        Inactive
                                    </span>
                                </b>
                            </span>
                        )}
                    </>
                );
            },
        },
        {
            name: (
                <Tippy content={`${partner?.name} hotels`}>
                    <div>{`${partner?.name} hotels`}</div>
                </Tippy>
            ),
            cell: (e) => {
                return (
                    <>
                        {e?.company_hotels ? separator(e?.company_hotels) : 0}
                        {e?.hotels_rate !== 0 &&
                            e?.hotels_rate !== null &&
                            e?.mapping_process_status_id === STATUS_PENDING && (
                                <>
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip("Rate/Sec")}
                                    >
                                        <small
                                            style={{
                                                color: "#21CA7F",
                                                fontWeight: "900",
                                                marginLeft: "5px",
                                            }}
                                        >
                                            {e?.hotels_rate}/Sec
                                        </small>
                                    </OverlayTrigger>
                                </>
                            )}
                    </>
                );
            },
        },
        {
            name: (
                <Tippy content="Affiliate hotels">
                    <div>Affiliate hotels</div>
                </Tippy>
            ),
            cell: (e) => {
                return (
                    <>
                        {e?.total_hotels ? separator(e?.total_hotels) : 0}
                        {e?.hotels_rate !== 0 &&
                            e?.hotels_rate !== null &&
                            e?.mapping_process_status_id === STATUS_PENDING && (
                                <>
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip("Rate/Sec")}
                                    >
                                        <small
                                            style={{
                                                color: "#21CA7F",
                                                fontWeight: "900",
                                                marginLeft: "5px",
                                            }}
                                        >
                                            {e?.hotels_rate}/Sec
                                        </small>
                                    </OverlayTrigger>
                                </>
                            )}
                    </>
                );
            },
        },

        {
            name: (
                <Tippy content={`Mapped`}>
                    <div>{`Mapped`}</div>
                </Tippy>
            ),
            cell: (e) => {
                const mapped_hotels = e?.mapped_hotels ?? 0;
                const valid_hotels = e?.valid_hotels ?? 0;
                const pourcentage =
                    valid_hotels != 0
                        ? (mapped_hotels / valid_hotels) * 100
                        : 0;
                return (
                    <>
                        {`${pourcentage.toFixed(0)}%`} <br />
                        {`(${
                            e?.mapped_hotels ? separator(e?.mapped_hotels) : 0
                        })`}
                    </>
                );
            },
        },
        {
            name: (
                <Tippy content="Opportunities">
                    <div>Opportunities</div>
                </Tippy>
            ),
            minWidth: "140px",
            cell: (e) => {
                const unmapped_hotels = e?.unmapped_hotels ?? 0;
                const valid_hotels = e?.valid_hotels ?? 0;
                const pourcentage =
                    valid_hotels != 0
                        ? (unmapped_hotels / valid_hotels) * 100
                        : 0;
                return (
                    <>
                        {`${pourcentage.toFixed(0)}%`} <br />
                        {`(${
                            e?.unmapped_hotels
                                ? separator(e?.unmapped_hotels)
                                : 0
                        }
                    )`}
                    </>
                );
            },
        },
        {
            name: (
                <Tippy content="Valid">
                    <div>Valid</div>
                </Tippy>
            ),
            cell: (e) => {
                return <>{e?.valid_hotels ? separator(e?.valid_hotels) : 0}</>;
            },
        },
        {
            name: (
                <Tippy content="Invalid">
                    <div>Invalid</div>
                </Tippy>
            ),
            cell: (e) => {
                return (
                    <>{e?.invalid_hotels ? separator(e?.invalid_hotels) : 0}</>
                );
            },
        },
        {
            name: (
                <Tippy content="Model">
                    <div>Model</div>
                </Tippy>
            ),
            cell: (e) => {
                return (
                    <span class="logo-text" style={{ fontSize: "18px" }}>
                        {e?.model}
                    </span>
                );
            },
        },
        {
            name: "",
            sortable: true,
            cell: (e) => {
                return (
                    <>
                        {e?.mapping_process_status_id ===
                            STATUS_SUCCESS_CATALOG && (
                            <ToggleStatus
                                dataCell={{ ...e, company_id: partner?.id }}
                                partner={partner}
                            />
                        )}
                    </>
                );
            },
        },
        {
            name: (
                <Tippy content="Status">
                    <div>Status</div>
                </Tippy>
            ),
            cell: (e) => {
                return (
                    <span
                        text="white"
                        className={`badge rounded-pill text-white bg-${
                            statusBadgeVariant(e?.mapping_process_status_id)
                                ?.color
                        }`}
                    >
                        {
                            statusBadgeVariant(e?.mapping_process_status_id)
                                ?.status
                        }
                    </span>
                );
            },
        },
        {
            name: (
                <Tippy content="Created">
                    <div>Created</div>
                </Tippy>
            ),
            cell: (e) => {
                return (
                    <>
                        {moment(new Date(e?.created_at)).format(
                            "DD/MM/YYYY HH:mm:ss"
                        )}
                    </>
                );
            },
        },

        {
            name: "Download",
            cell: (e) => {
                var rateProccessing =
                    e?.mapping_process_status_id === STATUS_PROCESSING
                        ? (
                              ((e?.total_hotels -
                                  e?.mapped_hotels -
                                  e?.unmapped_hotels) *
                                  1.6) /
                              1000 /
                              60
                          ).toFixed(0, 10)
                        : 0;
                return (
                    <>
                        {e?.mapping_process_status_id === STATUS_PROCESSING && (
                            <small
                                style={{
                                    color: "#21CA7F",
                                    fontWeight: "900",
                                    marginLeft: "5px",
                                }}
                            >
                                Estimated time ~ {rateProccessing} min
                            </small>
                        )}
                        {e?.mapping_process_status_id == 2 ? (
                            <>
                                <DownloadCatalog dataCell={e} id={e?.id} />
                            </>
                        ) : (
                            e?.mapping_process_status_id != 2 &&
                            e?.mapping_process_status_id != 3 && (
                                <Spinner
                                    animation="border"
                                    variant="primary"
                                    size="sm"
                                />
                            )
                        )}
                    </>
                );
            },
        },
    ];
    return (
        <>
            <div className="row">
                <div className="col-12">
                    {loading || loadingPartnerGroupedCatalog ? (
                        <div className="text-center">
                            <div className="lds-ripple">
                                <div className="lds-pos"></div>
                                <div className="lds-pos"></div>
                            </div>
                        </div>
                    ) : catalogs?.find(
                          (e) =>
                              e?.mapping_process_status_id ===
                                  STATUS_PROCESSING && e?.active === true
                      ) && partnerGroupedCatalogs?.length === 0 ? (
                        <CatalogNoDataModal
                            title="Almost ready!"
                            text="Your inventory is still processing."
                            hideButton={true}
                        />
                    ) : !catalogs?.find(
                          (e) =>
                              e?.mapping_process_status_id ==
                                  STATUS_SUCCESS_INVENTORY && e?.active == true
                      ) && partnerGroupedCatalogs?.length == 0 ? (
                        <CatalogNoDataModal
                            title="Your dedicated workspace is almost ready to go !"
                            text="First, you’ll need to upload your hotel inventory."
                            buttonText="Upload inventory"
                            handleClick={async () => {
                                history.push("/catalogs-manager");
                            }}
                        />
                    ) : (
                        <>
                            {proccessingList?.length > 0 && (
                                <div
                                    className="collapsible "
                                    style={{
                                        marginBottom: "20px",
                                        border: "2px solid rgb(99 117 231 / 77%)",
                                        borderRadius: "8px",
                                    }}
                                >
                                    <div
                                        className="header"
                                        {...getToggleProps()}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <div>
                                                <i
                                                    className="fas fa-info-circle"
                                                    style={{
                                                        color: "rgb(254 192 106)",
                                                        fontSize: "20px",
                                                    }}
                                                ></i>
                                                &nbsp;{" "}
                                                {`You have ${
                                                    proccessingList?.filter(
                                                        (e) =>
                                                            e?.mapping_process_status_id ===
                                                                STATUS_PROCESSING ||
                                                            e?.mapping_process_status_id ===
                                                                STATUS_PENDING
                                                    )?.length
                                                } mapping currently processing. `}
                                            </div>
                                            <div>
                                                <>
                                                    {isExpanded ? (
                                                        <div
                                                            className="hideOldBlocs"
                                                            style={{
                                                                margin: "0px",
                                                            }}
                                                        >
                                                            <h4>
                                                                <Link
                                                                    style={{
                                                                        fontSize:
                                                                            "17px",
                                                                    }}
                                                                >
                                                                    Hide details
                                                                </Link>
                                                            </h4>
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className="showOldBlocs"
                                                            style={{
                                                                margin: "0px",
                                                            }}
                                                        >
                                                            <h4>
                                                                <Link
                                                                    style={{
                                                                        fontSize:
                                                                            "17px",
                                                                    }}
                                                                >
                                                                    More details
                                                                </Link>
                                                            </h4>
                                                        </div>
                                                    )}
                                                </>
                                            </div>
                                        </div>
                                    </div>
                                    <div {...getCollapseProps()}>
                                        <div className="content">
                                            <ListGroup variant="flush">
                                                {proccessingList.map((e) => {
                                                    return (
                                                        <ListGroup.Item
                                                            style={{
                                                                backgroundColor:
                                                                    "#eeeffd",
                                                            }}
                                                        >
                                                            <div className="row">
                                                                <div className="col-lg-4">
                                                                    {e?.mapping_process_status_id ===
                                                                        STATUS_PROCESSING ||
                                                                    e?.mapping_process_status_id ===
                                                                        STATUS_PENDING ? (
                                                                        <Spinner
                                                                            animation="border"
                                                                            variant="primary"
                                                                            size="sm"
                                                                        />
                                                                    ) : (
                                                                        <i
                                                                            className="fas fa-check"
                                                                            style={{
                                                                                color: "green",
                                                                            }}
                                                                        ></i>
                                                                    )}
                                                                    &nbsp;&nbsp;
                                                                    {e?.name}
                                                                    {e?.mapping_process_status_id ===
                                                                        STATUS_PROCESSING && (
                                                                        <small
                                                                            style={{
                                                                                fontSize:
                                                                                    "13px",
                                                                                color: "#21CA7F",
                                                                                fontWeight:
                                                                                    "900",
                                                                                margin: "0px",
                                                                            }}
                                                                        >
                                                                            &nbsp;Estimated
                                                                            time
                                                                            ~
                                                                            {e?.rate ??
                                                                                0}
                                                                            min
                                                                        </small>
                                                                    )}
                                                                </div>
                                                                {/* <div
                                                                        style={{
                                                                            display:
                                                                                "flex",
                                                                            justifyContent:
                                                                                "space-between",
                                                                        }}
                                                                    > */}
                                                                <div className="col-lg-2 expanded-div">
                                                                    {`${e?.total_hotels} Total `}
                                                                    {e?.hotels_rate !==
                                                                        0 &&
                                                                        e?.hotels_rate !==
                                                                            null &&
                                                                        e?.mapping_process_status_id ===
                                                                            STATUS_PENDING && (
                                                                            <>
                                                                                &nbsp;
                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    delay={{
                                                                                        show: 250,
                                                                                        hide: 400,
                                                                                    }}
                                                                                    overlay={renderTooltip(
                                                                                        "Rate/Sec"
                                                                                    )}
                                                                                >
                                                                                    <small
                                                                                        style={{
                                                                                            fontSize:
                                                                                                "13px",
                                                                                            color: "#21CA7F",
                                                                                            fontWeight:
                                                                                                "900",
                                                                                            margin: "0px",
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            e?.hotels_rate
                                                                                        }
                                                                                        /Sec
                                                                                    </small>
                                                                                </OverlayTrigger>
                                                                            </>
                                                                        )}
                                                                </div>
                                                                <div className="col-lg-3 expanded-div">
                                                                    {`${e?.mapped_hotels} Mapped`}
                                                                </div>
                                                                <div className="col-lg-3 expanded-div">
                                                                    {`${e?.unmapped_hotels} Opportunities`}
                                                                </div>
                                                            </div>
                                                        </ListGroup.Item>
                                                    );
                                                })}
                                            </ListGroup>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex align-items-center mb-4">
                                        <h4 className="card-title">
                                            {`Upload ${partner?.name} catalogs and
                                            map them with yours`}
                                        </h4>
                                    </div>
                                    <div className="table-responsive ">
                                        <Table
                                            data={partnerGroupedCatalogs}
                                            rowsPerPage={10}
                                            columns={columns}
                                            loading={loading}
                                            conditionalRowStyles={
                                                conditionalRowStyles
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
            {openSuccesFirstUpload && (
                <SuccessUploadPartner
                    show={openSuccesFirstUpload}
                    handleClose={() => {
                        setOpenSuccesFirstUpload(false);
                    }}
                />
            )}
        </>
    );
}
export default CatalogPartnersTableDetails;
