import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import DashboardRouter from "./routers/DashboardRouter";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import { store } from "./redux/store";

function App() {
    // React.useEffect(() => {
    //     document.title = "This is a title";
    // }, []);
    return (
        <Provider store={store}>
            <div
                id="main-wrapper"
                data-theme="light"
                data-layout="vertical"
                data-navbarbg="skin6"
                data-sidebartype="full"
                data-sidebar-position="fixed"
                data-header-position="fixed"
                data-boxed-layout="full"
            >
                <TransitionGroup>
                    <CSSTransition timeout={300} classNames="fade">
                        <Router>
                            <Switch>
                                <DashboardRouter />
                            </Switch>
                        </Router>
                    </CSSTransition>
                </TransitionGroup>
                <ToastContainer />
            </div>
        </Provider>
    );
}

export default App;
