import {
    VERIFY_EMAIL_CODE_LISTING,
    VERIFY_EMAIL_CODE_SUCCESS,
    VERIFY_EMAIL_CODE_FAIL,
    VERIFY_EMAIL_SUCCESS,
    VERIFY_EMAIL_LISTING,
    VERIFY_EMAIL_FAIL,
    GET_ALL_INVITATIONS_LISTING,
    GET_ALL_INVITATIONS_SUCCESS,
    GET_ALL_INVITATIONS_FAILD,
} from "../types";
import axios from "axios";

const PATH_URL = process.env.REACT_APP_BASE_URL;

export const emailVerification = (email) => async (dispatch) => {
    dispatch({
        type: VERIFY_EMAIL_LISTING,
    });
    try {
        const res = await axios.post(
            PATH_URL + "auth/send-verification-email",
            { email: email },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        );
        dispatch({
            type: VERIFY_EMAIL_SUCCESS,
            payload: res.data,
        });
    } catch (e) {
        dispatch({
            type: VERIFY_EMAIL_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};

export const emailCodeVerification = (code) => async (dispatch) => {
    dispatch({
        type: VERIFY_EMAIL_CODE_LISTING,
    });
    try {
        const res = await axios.get(PATH_URL + `auth/verify`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            params: {
                code: code,
            },
        });
        dispatch({
            type: VERIFY_EMAIL_CODE_SUCCESS,
            payload: res.data,
        });
    } catch (e) {
        dispatch({
            type: VERIFY_EMAIL_CODE_FAIL,
        });
    }
};

export const getAllInvitations = () => async (dispatch) => {
    dispatch({
        type: GET_ALL_INVITATIONS_LISTING,
    });
    try {
        const res = await axios.get(PATH_URL + "/invitations", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        dispatch({
            type: GET_ALL_INVITATIONS_SUCCESS,
            payload: res.data,
        });
    } catch (e) {
        dispatch({
            type: GET_ALL_INVITATIONS_FAILD,
            payload: console.log("Error in actions ", e),
        });
    }
};
