import React from "react";
import { useLocation } from "react-router-dom";
import NavLink from "./NavLink";
import SignUpCompanyModal from "./SignUpCompanyModal";

function Leftside(props) {
    const location = useLocation()?.pathname;
    React.useEffect(() => {
        window.analytics.identify(localStorage.getItem("userId"), {
            name: localStorage.getItem("userConnected"),
            email: localStorage.getItem("email"),
            company: {
                id: localStorage.getItem("companyId"),
                name: localStorage.getItem("companyName"),
            },
            userID: localStorage.getItem("userId"),
            companyID: localStorage.getItem("companyId"),
            companyName: localStorage.getItem("companyName"),
            firstName: localStorage.getItem("firstName"),
            lastName: localStorage.getItem("lastName"),
            createdAt: new Date(),
            traits: {
                name: localStorage.getItem("userConnected"),
                email: localStorage.getItem("email"),
                company: {
                    id: localStorage.getItem("companyId"),
                    name: localStorage.getItem("companyName"),
                },
                userID: localStorage.getItem("userId"),
                companyID: localStorage.getItem("companyId"),
                companyName: localStorage.getItem("companyName"),
                firstName: localStorage.getItem("firstName"),
                lastName: localStorage.getItem("lastName"),
                createdAt: new Date(),
            },
        });
    }, []);
    return (
        <aside
            className="left-sidebar"
            data-sidebarbg="skin6"
            style={{ overflow: "scroll" }}
        >
            <div
                className="scroll-sidebar"
                data-sidebarbg="skin6"
                style={{ display: "flex", flexDirection: "column" }}
            >
                <nav className="sidebar-nav ">
                    <div className="cont-sign-up">
                        <div className="top-sign-up">
                            <ul id="sidebarnav">
                                <li className="nav-small-cap">
                                    <span className="hide-menu">
                                        {" "}
                                        Your inventory
                                    </span>
                                </li>
                                <NavLink
                                    to="/catalogs-manager"
                                    pathname={location}
                                    aria-expanded="false"
                                    className="sidebar-link sidebar-link"
                                >
                                    <i className="fas fa-upload"></i>
                                    <span className="hide-menu">Overview</span>
                                </NavLink>
                                <li className="list-divider"></li>
                                <li className="nav-small-cap">
                                    <span className="hide-menu">Mapping</span>
                                </li>
                                <NavLink
                                    to="/provider-content"
                                    pathname={location}
                                    aria-expanded="false"
                                    className="sidebar-link sidebar-link"
                                    isactive={
                                        window.location.href.indexOf(
                                            "/provider-content/details/"
                                        ) > -1 ||
                                        window.location.href.indexOf(
                                            "/to-review/"
                                        ) > -1
                                            ? true
                                            : false
                                    }
                                >
                                    <i className="far fa-file"></i>
                                    <span className="hide-menu">Mappings</span>
                                </NavLink>
                                {/* <li className="list-divider"></li>
                        <li className="nav-small-cap">
                            <span className="hide-menu">Settings</span>
                        </li>
                        <NavLink
                            to="/basic-info"
                            pathname={location}
                            aria-expanded="false"
                            className="sidebar-link sidebar-link"
                        >
                            <i className="fas fa-info-circle"></i>
                            <span className="hide-menu">Your company</span>
                        </NavLink> */}
                            </ul>
                        </div>

                        <div className="bottom-sign-up">
                            <SignUpCompanyModal />
                        </div>
                    </div>
                </nav>
            </div>
        </aside>
    );
}
export default Leftside;
