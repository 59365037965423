import axios from "axios";
import {
    GET_CUPID_MODELS_LISTING,
    GET_CUPID_MODELS_SUCCESS,
    GET_CUPID_MODELS_FAIL,
} from "../types";

const PATH_URL = process.env.REACT_APP_BASE_URL;

export const getCupidModels = () => async (dispatch) => {
    dispatch({
        type: GET_CUPID_MODELS_LISTING,
    });
    try {
        const res = await axios.get(PATH_URL + "/cupid_models", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        dispatch({
            type: GET_CUPID_MODELS_SUCCESS,
            payload: res.data,
        });
    } catch (e) {
        dispatch({
            type: GET_CUPID_MODELS_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};
