export const resolveNestedPath = (path, obj, separator = ".") => {
    const properties = Array.isArray(path) ? path : path.split(separator);
    return properties.reduce((prev, curr) => {
        if (curr instanceof Function) return prev && prev.find(curr);
        return prev && prev[curr];
    }, obj);
};

export const downloadBlobFile = (blob, fileName) => {
    // Create blob link to download
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
};

export const separator = (numb) => {
    var str = numb.toString().split(".");
    str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return str.join(".");
};

export const groupByCriteria = (arr, criteria) => {
    const newObj = arr.reduce(function (acc, currentValue) {
        if (!acc[currentValue[criteria]]) {
            acc[currentValue[criteria]] = [];
        }

        acc[currentValue[criteria]].push(currentValue);
        return acc;
    }, {});
    return newObj;
};

export const findDuplicates = (arr) => {
    return arr.filter((item, index) => arr.indexOf(item) != index) ?? [];
};
