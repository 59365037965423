import React, { useState } from "react";
import CatalogService from "../../services/CatalogService";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

function DownloadCatalog({ dataCell }) {
    const [pending, setPending] = useState(false);

    const handleDowload = (id) => async () => {
        setPending(true);
        let res = await CatalogService.dowloadCatalog(id);
        try {
            if (res.status === 200) {
                fileDownload(res.data, res?.fileName);
                setPending(false);
            } else {
                setPending(false);
                toast.error(res.message);
            }
        } catch (err) {
            setPending(false);
            toast.error("Something went wrong while uploading this file");
        }
    };

    return (
        <button
            type="button"
            className="btn btn-sm waves-effect waves-light btn-rounded btn-outline-secondary"
            onClick={handleDowload(dataCell.id)}
            disabled={pending}
        >
            {pending ? (
                <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
            ) : (
                <i className="fas fa-download"></i>
            )}
        </button>
    );
}
export default DownloadCatalog;
