/* eslint-disable import/no-anonymous-default-export */
import {
  DELETE_TEAM_DATA,
  DELETE_TEAM_DATA_FAIL,
  DELETE_TEAM_DATA_SUCCESS,
  GET_TEAM_DATA_FAIL,
  GET_TEAM_DATA_SUCCESS,
  GET_TEAM_DATA,
  UPDATE_TEAM_DATA,
  UPDATE_TEAM_DATA_SUCCESS,
  UPDATE_TEAM_DATA_FAIL,
  ADD_TEAM_DATA,
  ADD_TEAM_DATA_SUCCESS,
  ADD_TEAM_DATA_FAIL,
} from "../types";

const initialState = {
  users: [],
  loading: false,
  updating: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DELETE_TEAM_DATA:
      return {
        ...state,
        loading: true,
      };
    case DELETE_TEAM_DATA_SUCCESS:
      let userId = action.payload;
      let users = state.users.filter((user) => user.id != userId);
      return {
        ...state,
        users,
        loading: false,
      };
    case DELETE_TEAM_DATA_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_TEAM_DATA:
      return {
        ...state,
        loading: true,
      };
    case GET_TEAM_DATA_SUCCESS:
      return {
        ...state,
        users: action.payload,
        loading: false,
      };
    case GET_TEAM_DATA_FAIL:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_TEAM_DATA:
      return {
        ...state,
        updating: true,
      };
    case UPDATE_TEAM_DATA_SUCCESS:
      let usersCopy = [...state.users];
      for (let i in usersCopy) {
        let user = usersCopy[i];
        if (user.id == action.payload.id) {
          let { first_name, last_name, is_dev, is_admin, is_finance } =
            action.payload;
          user = {
            ...user,
            first_name,
            last_name,
            is_dev,
            is_admin,
            is_finance,
          };
          usersCopy[i] = user;
          break;
        }
      }
      return {
        ...state,
        users: usersCopy,
        updating: false,
      };
    case UPDATE_TEAM_DATA_FAIL:
      return {
        ...state,
        updating: false,
      };
    case ADD_TEAM_DATA_SUCCESS:
      let newUsers = [...state.users];
      newUsers.push(action.payload);
      return {
        ...state,
        users: newUsers,
        updating: false,
      };
    case ADD_TEAM_DATA_FAIL:
      return {
        ...state,
        updating: false,
      };
    case ADD_TEAM_DATA:
      return {
        ...state,
        updating: true,
      };
    default:
      return state;
  }
}
