// Types for login
export const REFRESH_TOKEN = "REFRESH_TOKEN";

// Types for Upload catalog
export const GET_CATALOGS_SUCCESS = "GET_CATALOGS_SUCCESS";
export const GET_CATALOGS_FAIL = "GET_CATALOGS_FAIL";
export const GET_CATALOGS_LISTING = "GET_CATALOGS_LISTING";

// Types for mappings catalogs
export const GET_MAPPING_CATALOGS_SUCCESS = "GET_MAPPING_CATALOGS_SUCCESS";
export const GET_MAPPING_CATALOGS_FAIL = "GET_MAPPING_CATALOGS_FAIL";
export const GET_MAPPING_CATALOGS_LISTING = "GET_MAPPING_CATALOGS_LISTING";

//Types for Dashboard data
export const GET_DASHBOARD_DATA_SUCCESS = "GET_DASHBOARD_DATA_SUCCESS";
export const GET_DASHBOARD_DATA_FAIL = "GET_DASHBOARD_DATA_FAIL";
export const GET_DASHBOARD_DATA_LISTING = "GET_DASHBOARD_DATA_LISTING";

// Types for user connected
export const GET_USER_CONNECTED_SUCCESS = "GET_USER_CONNECTED_SUCCESS";
export const GET_USER_CONNECTED_LISTING = "GET_USER_CONNECTED_LISTING";
export const GET_USER_CONNECTED_FAIL = "GET_USER_CONNECTED_FAIL";

//Types for to review hotels
export const GET_CATALOG_HOTEL_TO_REVIEW_SUCCESS =
    "GET_CATALOG_HOTEL_TO_REVIEW_SUCCESS";
export const GET_CATALOG_HOTEL_TO_REVIEW_LISTING =
    "GET_CATALOG_HOTEL_TO_REVIEW_LISTING";
export const GET_CATALOG_HOTEL_TO_REVIEW_FAIL =
    "GET_CATALOG_HOTEL_TO_REVIEW_FAIL";
export const CLEAR_CATALOG_HOTEL_TO_REVIEW = "CLEAR_CATALOG_HOTEL_TO_REVIEW";
export const DISPLAY_FINISH_MESSAGE = "DISPLAY_FINISH_MESSAGE";

//Types for partners list
export const GET_PARTNERS_LIST_SUCCESS = "GET_PARTNERS_LIST_SUCCESS";
export const GET_PARTNERS_LIST_FAIL = "GET_PARTNERS_LIST_FAIL";
export const GET_PARTNERS_LIST_LISTING = "GET_PARTNERS_LIST_LISTING";

export const GET_PARTNER_ATTRIBUTES_LISTING = "GET_PARTNER_ATTRIBUTES_LISTING";
export const GET_PARTNER_ATTRIBUTES_FAIL = "GET_PARTNER_ATTRIBUTES_FAIL";
export const GET_PARTNER_ATTRIBUTES_SUCCESS = "GET_PARTNER_ATTRIBUTES_SUCCESS";

export const GET_CATALOGS_FIRST_FAIL = "GET_CATALOGS_FIRST_FAIL";
export const GET_CATALOGS_FIRST_LISTING = "GET_CATALOGS_FIRST_LISTING";
export const GET_CATALOGS_FIRST_SUCCESS = "GET_CATALOGS_FIRST_LISTING";

export const GET_PARTNER_CATALOGS_LAST_UPDATE_FAIL =
    "GET_PARTNER_CATALOGS_LAST_UPDATE_FAIL";
export const GET_PARTNER_CATALOGS_LAST_UPDATE_LISTING =
    "GET_PARTNER_CATALOGS_LAST_UPDATE_LISTING";
export const GET_PARTNER_CATALOGS_LAST_UPDATE_SUCCESS =
    "GET_PARTNER_CATALOGS_LAST_UPDATE_SUCCESS";

export const GET_PARTNER_CATALOGS_BY_ID_FAIL =
    "GET_PARTNER_CATALOGS_BY_ID_FAIL";
export const GET_PARTNER_CATALOGS_BY_ID_LISTING =
    "GET_PARTNER_CATALOGS_BY_ID_LISTING";
export const GET_PARTNER_CATALOGS_BY_ID_SUCCESS =
    "GET_PARTNER_CATALOGS_BY_ID_SUCCESS";

//Types for progress  upload
export const GET_PROGRESS_CATALOG_SUCCESS = "GET_PROGRESS_CATALOG_SUCCESS";
export const GET_PROGRESS_CATALOG_FAIL = "GET_PROGRESS_CATALOG_FAIL";
export const GET_PROGRESS_CATALOG_LISTING = "GET_PROGRESS_CATALOG_LISTING";

//Types for get Catalog
export const GET_CATALOG_SUCCESS = "GET_CATALOG_SUCCESS";
export const GET_CATALOG_FAIL = "GET_CATALOG_FAIL";
export const GET_CATALOG_LISTING = "GET_CATALOG_LISTING";

//Types for Logout
export const USER_LOGOUT = "USER_LOGOUT";

//Types for Welcome dialog in catalog
export const SHOW_WELCOME_DIALOG = "SHOW_WELCOME_DIALOG";
export const HIDE_WELCOME_DIALOG = "HIDE_WELCOME_DIALOG";

//Types for Welcome dialog in catalog
export const SHOW_FINISH_PROCCESSING_DIALOG = "SHOW_FINISH_PROCCESSING_DIALOG";
export const HIDE_FINISH_PROCCESSING_DIALOG = "HIDE_FINISH_PROCCESSING_DIALOG";

//Types for open dialog add new cataog
export const OPEN_DIALOG_ADD_NEW_CATALOG = "OPEN_DIALOG_ADD_NEW_CATALOG";
export const HIDE_DIALOG_ADD_NEW_CATALOG = "HIDE_DIALOG_ADD_NEW_CATALOG";

//Types for email verification
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_LISTING = "VERIFY_EMAIL_LISTING";
export const VERIFY_EMAIL_FAIL = "VERIFY_EMAIL_FAIL";

//Types for email code verification
export const VERIFY_EMAIL_CODE_SUCCESS = "VERIFY_EMAIL_CODE_LISTING";
export const VERIFY_EMAIL_CODE_LISTING = "VERIFY_EMAIL_CODE_LISTING";
export const VERIFY_EMAIL_CODE_FAIL = "VERIFY_EMAIL_CODE_FAIL";

//Company details
export const GET_COMPANY_DATA = "GET_COMPANY_DATA";
export const GET_COMPANY_DATA_SUCCESS = "GET_COMPANY_DATA_SUCCESS";
export const GET_COMPANY_DATA_FAIL = "GET_COMPANY_DATA_FAIL";
export const UPDATE_COMPANY_DATA = "UPDATE_COMPANY_DATA";
export const UPDATE_COMPANY_DATA_SUCCESS = "UPDATE_COMPANY_DATA_SUCCESS";
export const UPDATE_COMPANY_DATA_FAIL = "UPDATE_COMPANY_DATA_FAIL";

//Team details
export const DELETE_TEAM_DATA = "DELETE_TEAM_DATA";
export const DELETE_TEAM_DATA_SUCCESS = "DELETE_TEAM_DATA_SUCCESS";
export const DELETE_TEAM_DATA_FAIL = "DELETE_TEAM_DATA_FAIL";
export const GET_TEAM_DATA_FAIL = "GET_TEAM_DATA_FAIL";
export const GET_TEAM_DATA_SUCCESS = "GET_TEAM_DATA_SUCCESS";
export const GET_TEAM_DATA = "GET_TEAM_DATA";
export const UPDATE_TEAM_DATA = "UPDATE_TEAM_DATA";
export const UPDATE_TEAM_DATA_SUCCESS = "UPDATE_TEAM_DATA_SUCCESS";
export const UPDATE_TEAM_DATA_FAIL = "UPDATE_TEAM_DATA_FAIL";
export const ADD_TEAM_DATA = "ADD_TEAM_DATA";
export const ADD_TEAM_DATA_SUCCESS = "ADD_TEAM_DATA_SUCCESS";
export const ADD_TEAM_DATA_FAIL = "ADD_TEAM_DATA_FAIL";

//Payment cards
export const GET_PAYMENT_CARDS_SUCCESS = "GET_PAYMENT_CARDS_SUCCESS";
export const GET_PAYMENT_CARDS_LISTING = "GET_PAYMENT_CARDS_LISTING";
export const GET_PAYMENT_CARDS_FAIL = "GET_PAYMENT_CARDS_FAIL";

//Auto-pilot
export const GET_AFFILIATE_PARTNER_SUCCESS = "GET_AFFILIATE_PARTNER_SUCCESS";
export const GET_AFFILIATE_PARTNER_LISTING = "GET_AFFILIATE_PARTNER_LISTING";
export const GET_AFFILIATE_PARTNER_FAIL = "GET_AFFILIATE_PARTNER_FAIL";

//Explore mapping
export const SEARCH_HOTELS_SUCCESS = "SEARCH_HOTELS_SUCCESS";
export const SEARCH_HOTELS_LISTING = "SEARCH_HOTELS_LISTING";
export const SEARCH_HOTELS_FAIL = "SEARCH_HOTELS_FAIL";
export const HOTEL_DETAILS_SUCCESS = "HOTEL_DETAILS_SUCCESS";
export const HOTEL_DETAILS_FAIL = "HOTEL_DETAILS_FAIL";
export const HOTEL_DETAILS_LISTING = "HOTEL_DETAILS_LISTING";

//Countries
export const GET_COUNTRIES_LIST_SUCCESS = "GET_COUNTRIES_LIST_SUCCESS";
export const GET_COUNTRIES_LIST_LISTING = "GET_COUNTRIES_LIST_LISTING";
export const GET_COUNTRIES_LIST_FAIL = "GET_COUNTRIES_LIST_FAIL";

//Auto complete hotel name
export const GET_AUTO_COMPLETE_HOTEL_NAME_SUCCESS =
    "GET_AUTO_COMPLETE_HOTEL_NAME_SUCCESS";
export const GET_AUTO_COMPLETE_HOTEL_NAME_LISTING =
    "GET_AUTO_COMPLETE_HOTEL_NAME_LISTING";
export const GET_AUTO_COMPLETE_HOTEL_NAME_FAIL =
    "GET_AUTO_COMPLETE_HOTEL_NAME_FAIL";

//Auto pilot connectors
export const GET_CONNECTORS_SUCCESS = "GET_CONNECTORS_SUCCESS";
export const GET_CONNECTORS_LISTING = "GET_CONNECTORS_LISTING";
export const GET_CONNECTORS_FAIL = "GET_CONNECTORS_FAIL";

//Auto pilot cridentials
export const GET_CRIDENTIALS_SUCCESS = "GET_CRIDENTIALS_SUCCESS";
export const GET_CRIDENTIALS_LISTING = "GET_CRIDENTIALS_LISTING";
export const GET_CRIDENTIALS_FAIL = "GET_CRIDENTIALS_FAIL";

//Destinations
export const GET_DESTINATION_LIST_DATA_FAIL = "GET_DESTINATION_LIST_DATA_FAIL";
export const GET_DESTINATION_LIST_DATA_LISTING =
    "GET_DESTINATION_LIST_DATA_LISTING";
export const GET_DESTINATION_LIST_DATA_SUCCESS =
    "GET_DESTINATION_LIST_DATA_SUCCESS";

export const GET_DESTINATION_CREDENTIALS_DATA_FAIL =
    "GET_DESTINATION_CREDENTIALS_DATA_FAIL";
export const GET_DESTINATION_CREDENTIALS_DATA_LISTING =
    "GET_DESTINATION_CREDENTIALS_DATA_LISTING";
export const GET_DESTINATION_CREDENTIALS_DATA_SUCCESS =
    "GET_DESTINATION_CREDENTIALS_DATA_SUCCESS";

// Check if partner has active catalog
export const CHECK_PARTNER_CATALOGS_LISTING = "CHECK_PARTNER_CATALOGS_LISTING";
export const CHECK_PARTNER_CATALOGS_SUCCESS = "CHECK_PARTNER_CATALOGS_SUCCESS";
export const CHECK_PARTNER_CATALOGS_FAIL = "CHECK_PARTNER_CATALOGS_FAIL";
export const CLEAR_PARTNER_HAS_CATALOG = "CLEAR_PARTNER_HAS_CATALOG";

//Get partner grouped catalogs

export const GET_PARTNER_GROUPED_LIST_FAILD = "GET_PARTNER_GROUPED_LIST_FAILD";
export const GET_PARTNER_GROUPED_LIST_SUCCESS =
    "GET_PARTNER_GROUPED_LIST_SUCCESS";
export const GET_PARTNER_GROUPED_LIST_LISTING =
    "GET_PARTNER_GROUPED_LIST_LISTING";
export const GET_PARTNER_GROUPED_LIST_LISTING_PERIODIQUE =
    "GET_PARTNER_GROUPED_LIST_LISTING_PERIODIQUE";

//Analytics

/* MAPPING NUMBER */
export const GET_MAPPING_NUMBER_SUCCESS = "GET_MAPPING_NUMBER_SUCCESS";
export const GET_MAPPING_NUMBER_FAIL = "GET_MAPPING_NUMBER_FAIL";
export const GET_MAPPING_NUMBER_LISTING = "GET_MAPPING_NUMBER_LISTING";

/* Supplier outdated */
export const GET_SUPPLIER_OUTDATED_SUCCESS = "GET_SUPPLIER_OUTDATED_SUCCESS";
export const GET_SUPPLIER_OUTDATED_FAIL = "GET_SUPPLIER_OUTDATED_FAIL";
export const GET_SUPPLIER_OUTDATED_LISTING = "GET_SUPPLIER_OUTDATED_LISTING";

/* Suppliers exclusive content */
export const GET_SUPPLIER_EXCLUSIVE_CONTENT_SUCCESS =
    "GET_SUPPLIER_EXCLUSIVE_CONTENT_SUCCESS";
export const GET_SUPPLIER_EXCLUSIVE_CONTENT_LISTING =
    "GET_SUPPLIER_EXCLUSIVE_CONTENT_LISTING";
export const GET_SUPPLIER_EXCLUSIVE_CONTENT_FAIL =
    "GET_SUPPLIER_EXCLUSIVE_CONTENT_FAIL";

/* Top supplier */
export const GET_TOP_SUPPLIER_SUCCESS = "GET_TOP_SUPPLIER_SUCCESS";
export const GET_TOP_SUPPLIER_LISTING = "GET_TOP_SUPPLIER_LISTING";
export const GET_TOP_SUPPLIER_FAIL = "GET_TOP_SUPPLIER_FAIL";

/* list of inventory with count */
export const GET_INVENTORY_COUNT_SUCCESS = "GET_INVENTORY_COUNT_SUCCESS";
export const GET_INVENTORY_COUNT_LISTING = "GET_INVENTORY_COUNT_LISTING";
export const GET_INVENTORY_COUNT_FAIL = "GET_INVENTORY_COUNT_FAIL";

/* list of inventory unmapped */
export const GET_INVENTORY_UNMAPPED_SUCCESS = "GET_INVENTORY_UNMAPPED_SUCCESS";
export const GET_INVENTORY_UNMAPPED_LISTING = "GET_INVENTORY_UNMAPPED_LISTING";
export const GET_INVENTORY_UNMAPPED_FAIL = "GET_INVENTORY_UNMAPPED_FAIL";

/* Timeserie mapped rate */
export const TIME_SERIE_MAPPED_RATE_LISTING = "TIME_SERIE_MAPPED_RATE_LISTING";
export const TIME_SERIE_MAPPED_RATE_SUCCESS = "TIME_SERIE_MAPPED_RATE_SUCCESS";
export const TIME_SERIE_MAPPED_RATE_FAIL = "TIME_SERIE_MAPPED_RATE_FAIL";

/* Timeserie inventory mapped rate */
export const TIME_SERIE_INVENTORY_MAPPED_RATE_LISTING =
    "TIME_SERIE_INVENTORY_MAPPED_RATE_LISTING";
export const TIME_SERIE_INVENTORY_MAPPED_RATE_SUCCESS =
    "TIME_SERIE_INVENTORY_MAPPED_RATE_SUCCESS";
export const TIME_SERIE_INVENTORY_MAPPED_RATE_FAIL =
    "TIME_SERIE_INVENTORY_MAPPED_RATE_FAIL";

/* Get completed steps home page  */
export const GET_COMPLETED_STEPS_LISTING = "GET_COMPLETED_STEPS_LISTING";
export const GET_COMPLETED_STEPS_SUCCESS = "GET_COMPLETED_STEPS_SUCCESS";
export const GET_COMPLETED_STEPS_FAIL = "GET_COMPLETED_STEPS_FAIL";

/* Get credentials by id  */
export const GET_CRIDENTIALS_BY_ID_LISTING = "GET_CRIDENTIALS_BY_ID_LISTING";
export const GET_CRIDENTIALS_BY_ID_SUCCESS = "GET_CRIDENTIALS_BY_ID_SUCCESS";
export const GET_CRIDENTIALS_BY_ID_FAIL = "GET_CRIDENTIALS_BY_ID_FAIL";

/** Get partners inventory */
export const GET_PARTNERS_INVENTORIES_LISTING =
    "GET_PARTNERS_INVENTORIES_LISTING";
export const GET_PARTNERS_INVENTORIES_SUCCESS =
    "GET_PARTNERS_INVENTORIES_SUCCESS";
export const GET_PARTNERS_INVENTORIES_FAIL = "GET_PARTNERS_INVENTORIES_FAIL";

/** Get mappings */
export const GET_MAPPINGS_LISTING = "GET_MAPPINGS_LISTING";
export const GET_MAPPINGS_SUCCESS = "GET_MAPPINGS_SUCCESS";
export const GET_MAPPINGS_FAIL = "GET_MAPPINGS_FAIL";

// Get outadated catalogs
export const GET_OUTDATED_CATALOGS_SUCCESS = "GET_OUTDATED_CATALOGS_SUCCESS";
export const GET_OUTDATED_CATALOGS_FAIL = "GET_OUTDATED_CATALOGS_FAIL";
export const GET_OUTDATED_CATALOGS_LISTING = "GET_OUTDATED_CATALOGS_LISTING";

//Models

//get cupid models

export const GET_CUPID_MODELS_LISTING = "GET_CUPID_MODELS_LISTING";
export const GET_CUPID_MODELS_SUCCESS = "GET_CUPID_MODELS_SUCCESS";
export const GET_CUPID_MODELS_FAIL = "GET_CUPID_MODELS_FAIL";

// get all invitations
export const GET_ALL_INVITATIONS_LISTING = "GET_ALL_INVITATIONS_LISTING";
export const GET_ALL_INVITATIONS_SUCCESS = "GET_ALL_INVITATIONS_SUCCESS";
export const GET_ALL_INVITATIONS_FAILD = "GET_ALL_INVITATIONS_FAILD";
